import {gql, MutationResult, useMutation} from '@apollo/client'
import type {Artist, Where} from 'data/types'

interface DeleteArtistVars {
  where: Where<Artist>,
}

const DELETE_ARTIST_MUTATION = gql `
  mutation DeleteArtist($where: ArtistWhere) {
    deleteArtists(
      where: $where
      delete: {
        description: {}
        links: {}
      }
    ) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`

interface DeleteArtistData {
  deleteArtists: {
    nodesDeleted: number,
    relationshipsDeleted: number,
  },
}

interface UseDeleteArtistResult {
  deleteArtist: () => void
  result: MutationResult,
}

export const useDeleteArtist = (artist: Artist): UseDeleteArtistResult => {
  const [mutate, result] = useMutation<DeleteArtistData, DeleteArtistVars>(DELETE_ARTIST_MUTATION)

  const deleteArtist = () => {
    if (result.called) return

    const whereInput: Where<Artist> = {
      id: artist.id,
    }

    mutate({
      variables: {
        where: whereInput,
      },
    })
  }

  return {
    deleteArtist,
    result,
  }
}
