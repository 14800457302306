import React from 'react'
import type {Link} from 'data/types'
import {
  SPOTIFY_ARTIST_URL,
} from 'utils/regex'
import LinkButtons, {LinkButtonsProps} from 'ui/LinkButtons'

interface LinksProps extends LinkButtonsProps {
  links?: Link[],
}

const Links = ({links, ...props}: LinksProps): JSX.Element | null => {
  if (!links) return null

  // remove Spotify artist links
  const filteredLinks = links.filter(l => !l.url.match(SPOTIFY_ARTIST_URL))

  return (
    <LinkButtons links={filteredLinks} {...props}/>
  )
}

export default Links
