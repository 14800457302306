import React, {useState} from 'react'
import {
  Box,
  IconButton,
  Input,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/AddBox'
import LinkIcon from '@mui/icons-material/Link'
import DeleteIcon from '@mui/icons-material/Delete'
import {Link} from 'data/types'

type LinksEditorProps = {
  links?: Link[],
  addLink: (link: string) => void,
  removeLink: (id: string) => void,
}

const LinksEditor = ({links, addLink, removeLink}: LinksEditorProps): JSX.Element => {
  const [url, setUrl] = useState("")
  const [focused, setFocused] = useState(false)

  const disableAddButton = url.length < 1
  const color = focused ? "primary.main" : "inherit"

  const handleAddLink = () => {
    if (!links?.find(l => l.url === url)) {
      addLink(url)
      setUrl("")
    } else {
      // TODO: replace with error message
      console.log("Link already exists")
    }
  }

  const addButton = (
    <IconButton
      onClick={handleAddLink}
      disabled={disableAddButton}
    >
      <AddIcon/>
    </IconButton>
  )

  return (
    <Box component="fieldset" m={0} borderRadius={1} borderColor={color}>
      <Typography component="legend" color={color}>Links</Typography>

      <Box px={2} my={1}>
        <Input
          placeholder="Type or paste a URL..."
          fullWidth
          startAdornment={<LinkIcon sx={{mr: 2}}/>}
          endAdornment={addButton}
          value={url}
          onChange={e => setUrl(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </Box>

      <List>
        {links && links.map((link) => {
          const deleteButton = (
            <IconButton onClick={() => removeLink(link.id)}>
              <DeleteIcon color="error"/>
            </IconButton>
          )

          return (
            <ListItem
              key={link.id}
              secondaryAction={deleteButton}
            >
              <ListItemIcon sx={{minWidth: 'auto', pr: 2}}>
                <LinkIcon/>
              </ListItemIcon>
              <MuiLink
                href={link.url}
                color="success.main"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.url}
              </MuiLink>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )
}

export default LinksEditor
