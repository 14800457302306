import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {
  Box,
  Button,
  Paper,
  SxProps,
  TextField,
  Typography,
} from '@mui/material'
import HapnynLogo from 'ui/hapnyn/Logo'

const gridSx: SxProps = {
  display: 'grid',
  rowGap: 2,
  minWidth: 360,
}

type TextFieldChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

const SignUpForm = (): JSX.Element => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const loading = false
  const signUp = (name: string, email: string) => {console.log({name, email})}

  const handleNameChange = (e: TextFieldChangeEvent) => setName(e.target.value)
  const handleEmailChange = (e: TextFieldChangeEvent) => setEmail(e.target.value)
  const handleSignUp = () => signUp(name, email)

  const disableFields = loading
  const disableButton = !name || !email || loading

  return (
    <Paper sx={{p: 2}}>
      <Box component="form" noValidate>
        <Box sx={gridSx}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <HapnynLogo fontSize={36} color="primary.main" shadow={false}/>
            <Typography fontSize={18}>sign up</Typography>
          </Box>

          <TextField
            label="Name"
            type="text"
            value={name}
            onChange={handleNameChange}
            disabled={disableFields}
          />
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            disabled={disableFields}
          />

          <Box display="flex" justifyContent="space-between">
            <Button
              component={Link}
              to="/signin"
              color="secondary"
              sx={{textTransform: 'none'}}
            >
              Sign In
            </Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              sx={{textTransform: 'none'}}
              onClick={handleSignUp}
              disabled={disableButton}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default SignUpForm
