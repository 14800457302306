import {useCallback} from 'react'
import {gql, LazyQueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {COMPLETE_ARTIST_FIELDS} from 'data/fragments/artist'
import {Artist} from 'data/types'

const FETCH_ARTIST_BY_ID_QUERY = gql `
  ${COMPLETE_ARTIST_FIELDS}

  query FetchArtistById($id: ID!) {
    artists(
      where: {id: $id}
    ) {
      ...CompleteArtistFields

      eventsTotal: eventsAggregate {
        count
      }
    }
  }
`

interface FetchArtistByIdQueryVars {
  id: string,
}

interface FetchArtistByIdQueryData {
  artists: Artist[],
}

interface UseFetchArtistByIdResult {
  fetchArtistById: (id: string) => void,
  artist?: Artist,
  result: QueryResult<FetchArtistByIdQueryData, FetchArtistByIdQueryVars>,
}

type FetchArtistByIdOptions = LazyQueryHookOptions<FetchArtistByIdQueryData, FetchArtistByIdQueryVars>

export const useFetchArtistById = (options?: FetchArtistByIdOptions): UseFetchArtistByIdResult => {
  const [query, result] = useLazyQuery<FetchArtistByIdQueryData, FetchArtistByIdQueryVars>(FETCH_ARTIST_BY_ID_QUERY, options)

  const artist = result.data?.artists[0]

  const fetchArtistById = useCallback((id: string) => {
    if (!result.called || (artist && artist.id !== id)) query({variables: {id}})
  }, [result.called, artist, query])

  return {
    fetchArtistById,
    artist,
    result,
  }
}
