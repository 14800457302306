import {useCallback} from 'react'
import {DateTime} from 'luxon'
import {gql, QueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_EVENT_FIELDS} from 'data/fragments/event'
import {Place} from 'data/types'

const FETCH_UPCOMING_PLACE_EVENTS_QUERY = gql`
  ${CORE_EVENT_FIELDS}

  query FetchUpcomingPlaceEvents($placeId: ID!, $start: DateTime) {
    places(
      where: {id: $placeId}
    ) {
      id
      events(
        where: {
          startTime_GTE: $start,
        }
        options: {sort: {startTime: ASC}}
      ) {
        ...CoreEventFields
      }
    }
  }
`

interface FetchUpcomingPlaceEventsVars {
  placeId: string,
  start: Date,
}

interface FetchUpcomingPlaceEventsData {
  places: Place[],
}

interface UseFetchUpcomingPlaceEventsResult {
  fetchUpcomingPlaceEvents: () => void,
  result: QueryResult<FetchUpcomingPlaceEventsData, FetchUpcomingPlaceEventsVars>,
}

type FetchUpcomingPlaceEventsOptions = QueryHookOptions<FetchUpcomingPlaceEventsData, FetchUpcomingPlaceEventsVars>

export const useFetchUpcomingPlaceEvents = (placeId: string, options?: FetchUpcomingPlaceEventsOptions): UseFetchUpcomingPlaceEventsResult => {
  const [query, result] = useLazyQuery<FetchUpcomingPlaceEventsData, FetchUpcomingPlaceEventsVars>(FETCH_UPCOMING_PLACE_EVENTS_QUERY, options)

  const fetchUpcomingPlaceEvents = useCallback(() => {
    const start = DateTime.now().startOf('day').toJSDate()

    if (!result.called)
      query({
        variables: {
          placeId,
          start,
        }
      })
  }, [result.called, query, placeId])

  return {
    fetchUpcomingPlaceEvents,
    result,
  }
}
