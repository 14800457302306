import {DateTime, Interval} from 'luxon'
import type {Link} from 'data/types'
import {INSTAGRAM_URL} from './regex'

export const capitalize = (str: string): string => str[0].toUpperCase() + str.substring(1)

export const formatInterval = (interval?: Interval): string | undefined => {
  if (!interval || !interval.isValid) return undefined

  const now = DateTime.now()

  let startDateString = "", startTimeString = "", endDateString = "", endTimeString = ""

  startDateString += interval.start.toFormat('EEEE, MMMM d')
  if (!interval.start.hasSame(now, 'year')) startDateString += interval.start.toFormat(', yyyy')

  startTimeString += interval.start.toFormat('h')
  if (interval.start.minute != 0) startTimeString += interval.start.toFormat(':mm')
  if (interval.start.toFormat('a') !== interval.end.toFormat('a') || !interval.hasSame('day')) {
    startTimeString += interval.start.toFormat('a').toLowerCase()
  }

  if (interval.length('minutes') > 719 && !interval.hasSame('day')) {
    endDateString += interval.end.toFormat('EEEE, MMMM d, ')

    if (!interval.hasSame('year')) endDateString += interval.end.toFormat('yyyy, ')
  }

  endTimeString += interval.end.toFormat('h')
  if (interval.end.minute != 0) endTimeString += interval.end.toFormat(':mm')
  endTimeString += interval.end.toFormat('a').toLowerCase()

  const startString = startDateString + " from " + startTimeString
  const endString = endDateString + endTimeString

  return startString + " to " + endString
}

export const getRelativeDateString = (date: DateTime): string => {
  let dateString = ""

  const today = DateTime.now()
  const tomorrow = today.plus({day: 1})

  if (date.hasSame(today, 'day')) {
    dateString = "Today"
  } else if (date.hasSame(tomorrow, 'day')) {
    dateString = "Tomorrow"
  } else {
    dateString = date.toFormat("EEEE, MMMM d")
    if (!date.hasSame(today, 'year')) dateString += date.toFormat(", y")
  }

  return dateString
}

export const sortByName = (a: string, b: string): number => {
  let nameA = a
  let nameB = b

  // TODO: use regex
  if (nameA.startsWith("The ") || nameA.startsWith("the ")) nameA = nameA.slice(4)
  if (nameB.startsWith("The ") || nameB.startsWith("the ")) nameB = nameB.slice(4)

  return nameA.localeCompare(nameB)
}

export const generateSearchString = (searchInput: string): string => {
  const SPECIAL_CHARACTERS = '+-&|!(){}[]^"~*?:\\/'

  if (searchInput[0] == '"') return searchInput.substring(1)

  let escapedSearchInput = ""

  for (let i = 0; i < searchInput.length; i++) {
    const char = searchInput[i]

    if (SPECIAL_CHARACTERS.indexOf(char) > -1) {
      escapedSearchInput += "\\"
    }

    escapedSearchInput += char
  }

  let searchString = ""

  const searchTerms = escapedSearchInput.split(" ").filter(s => s)

  searchTerms.forEach((term) => {
    let subquery = `(${term}*`

    subquery += term.length <= 2 ? ")" : ` OR ${term}~)`

    if (searchString != "") searchString += " OR "

    searchString += subquery
  })

  return searchString
}

export const getHandleFromUrl = (url: string): string | null => {
  let s = url.split("?")[0]
  s = (s.slice(-1)) == "/" ? s.slice(0, -1) : s
  const handle = s.split("/").pop()

  return handle ?? null
}

export const getHandleFromLinks = (links: Link[]): string | null => {
  if (!links) return null

  const instagramLinks = links.filter(l => l.url.match(INSTAGRAM_URL))

  if (instagramLinks.length == 0) return null

  return getHandleFromUrl(instagramLinks[0].url)
}

export const isMacOs = (): boolean => {
  return window.navigator.userAgent.indexOf("Mac OS") != -1
}

export const ctrlOs = (): string => {
  return isMacOs() ? 'Cmd' : 'Ctrl'
}
