import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import {Event} from 'data/types'
import {formatEventTime, getEventName} from 'events/utils'
import {getPrimaryUrl} from 'utils/urls'

interface ConflictingEventProps {
  event: Event,
  entity: string,
  url: string,
}

const ConflictingEvent = ({event, entity, url}: ConflictingEventProps): JSX.Element => {
  const eventUrl = getPrimaryUrl(event)

  return (
    <TableRow>
      <TableCell sx={{maxWidth: 300, borderBottom: 'none', px: 1}}>
        <Typography
          color='inherit'
          component={RouterLink}
          to={eventUrl}
          target="_blank"
          rel="noopener noreferrer"
          sx={{p: 0, textDecoration: 'none'}}
        >
          {getEventName(event)}
        </Typography>
      </TableCell>
      <TableCell sx={{borderBottom: 'none', px: 1}}>
        <Typography
          color='primary.main'
          component={RouterLink}
          to={url}
          target="_blank"
          rel="noopener noreferrer"sx={{p: 0, textDecoration: 'none'}}>
          {entity}
        </Typography>
      </TableCell>
      <TableCell sx={{borderBottom: 'none', px: 1, textAlign: 'right'}}>
        <Typography color="info.main">
          {formatEventTime(event)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default ConflictingEvent
