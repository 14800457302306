import {Location, useLocation} from 'react-router-dom'
import {LocationState} from 'types/location'

export const useReturnLocation = (): Location | string | undefined => {
  const location = useLocation()

  const state = location.state as LocationState

  return state?.from
}
