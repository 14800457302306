import {useReactiveVar} from '@apollo/client'
import {isSignedInVar} from 'data/auth'

interface UseAuthResult {
  isSignedIn: boolean,
}

export const useAuthStatus = (): UseAuthResult => {
  const isSignedIn = useReactiveVar(isSignedInVar)

  return {
    isSignedIn,
  }
}
