import {useCallback} from 'react'
import {gql, MutationHookOptions, MutationResult, useMutation} from '@apollo/client'
import {COMPLETE_PLACE_FIELDS} from 'data/fragments/place'
import type {Handle, Input, Link, Place, Where} from 'data/types'

interface PlaceUpdateVars {
  where: Where<Place>,
  update: Input<Partial<Place>>,
}

const UPDATE_PLACE_MUTATION = gql `
  ${COMPLETE_PLACE_FIELDS}

  mutation UpdatePlace($where: PlaceWhere, $update: PlaceUpdateInput) {
    updatePlaces(where: $where, update: $update) {
      places {
        ...CompletePlaceFields
      }
    }
  }
`

interface PlaceUpdateData {
  updatePlaces: {
    places: Place[],
  },
}

interface UseUpdatePlaceResult {
  updatePlace: (placeData: Partial<Place>) => void
  result: MutationResult<PlaceUpdateData>,
}

type UpdatePlaceOptions = MutationHookOptions<PlaceUpdateData, PlaceUpdateVars>

export const useUpdatePlace = (place: Place, options?: UpdatePlaceOptions): UseUpdatePlaceResult => {
  const [mutate, result] = useMutation<PlaceUpdateData, PlaceUpdateVars>(UPDATE_PLACE_MUTATION, {
    onError: () => {/* prevent exception from being thrown */},
    ...options,
  })

  const updatePlace = useCallback((data: Partial<Place>) => {
    const whereInput: Where<Place> = {
      id: place.id,
    }

    const updateInput: Input<Partial<Place>> = {
    }

    // name
    if (data.name && data.name !== place.name) {
      updateInput.name = data.name
    }

    // description
    if (data.description) {
      const node = {
        id: data.description.id,
        format: data.description.format,
        content: data.description.content,
      }

      if (place.description?.createdAt) {
        if (node.content !== place.description.content) {
          updateInput.description = {
            update: {
              node,
            },
          }
        }
      } else {
        updateInput.description = {
          create: {
            node,
          },
        }
      }
    } else {
      if (place.description) {
        updateInput.description = {
          delete: {
            where: {
              node: {
                id: place.description.id,
              },
            },
          },
        }
      }
    }

    // links
    const addedLinks: Link[] = []
    for (const link of data.links ?? []) {
      if (place.links?.filter(l => l.url === link.url).length === 0) {
        addedLinks.push(link)
      }
    }

    const removedLinks: Link[] = []
    for (const link of place.links ?? []) {
      if (data.links?.filter(l => l.url === link.url).length === 0) {
        removedLinks.push(link)
      }
    }

    if (addedLinks.length > 0) {
      if (!updateInput.links) updateInput.links = {}

      updateInput.links.create = addedLinks.map((link) => {
        const node = {
          id: link.id,
          url: link.url,
        }

        return {node}
      })
    }

    if (removedLinks.length > 0) {
      if (!updateInput.links) updateInput.links = {}

      updateInput.links.delete = removedLinks.map((link) => {
        return {
          where: {
            node: {
              id: link.id,
            },
          },
        }
      })
    }

    // handles
    const addedHandles: Handle[] = []
    for (const handle of data.handles ?? []) {
      if (place.handles?.filter(h => h.name === handle.name).length === 0) {
        addedHandles.push(handle)
      }
    }

    if (addedHandles.length > 0) {
      if (!updateInput.handles) updateInput.handles = {}

      updateInput.handles.create = addedHandles.map((handle) => {
        const node = {
          name: handle.name,
        }

        return {node}
      })
    }

    if (data.primaryHandle && data.primaryHandle.name !== place.primaryHandle?.name) {
      updateInput.primaryHandle = {
        disconnect: {
          where: {
            node: {
              name_NOT: data.primaryHandle.name,
            },
          },
        },
        connect: {
          where: {
            node: {
              name: data.primaryHandle.name,
            },
          },
        },
      }
    }

    if (Object.keys(updateInput).length > 0) {
      mutate({
        variables: {
          where: whereInput,
          update: updateInput,
        },
      })
    }

    console.log({
      place,
      data,
      updateInput,
    })
  }, [place, mutate])

  return {
    updatePlace,
    result,
  }
}
