import React from 'react'
import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'

const cardSx: SxProps<Theme> = {
  height: 1080,
  width: 1080,
  position: 'relative',
  backgroundColor: theme => theme.palette.background.default,
}

const logoSx: SxProps<Theme> = {
  color: 'common.white',
  fontFamily: 'alba',
  fontSize: 160,
  position: 'absolute',
  left: 208,
  top: 445,
  textShadow: theme => `.04em .04em .04em ${theme.palette.primary.main}`,
}

const comSx: SxProps<Theme> = {
  color: 'common.white',
  fontFamily: 'alba',
  fontSize: 85,
  position: 'absolute',
  left: 710,
  top: 523,
}

export const EndCard = (): JSX.Element => {
  return (
    <Box sx={cardSx}>
      <Box
        position='absolute'
        top={191}
        textAlign='center'
        width='100%'
      >
        <Typography
          color='common.white'
          fontSize={64}
          lineHeight={1.40}
        >For more information about<br/>
        upcoming events, artists, and<br/>
        venues in San Diego, visit:
        </Typography>
      </Box>
      <Typography
        sx={logoSx}
      >hapnyn
      </Typography>
      <Typography
        sx={comSx}
      >.com
      </Typography>
    </Box>
  )
}

export default EndCard
