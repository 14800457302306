import {DateTime, Interval} from 'luxon'
import type {Artist, Event} from 'data/types'

type eventTimeStrArgs = {
  event: Event,
  includeDay?: boolean,
}

export const eventTimeStr = ({event, includeDay=false}: eventTimeStrArgs): string => {
  const today = DateTime.now().startOf('day')

  const startTime = DateTime.fromJSDate(event.startTime)
  const endTime = event.endTime ? DateTime.fromJSDate(event.endTime) : startTime.plus({hours: 3})

  let startTimeStr = startTime.toFormat('h:mm')
  if (startTime.minute === 0) startTimeStr = startTime.toFormat('h')
  if (startTime.toFormat('a') != endTime.toFormat('a') || startTime.day !== endTime.day)
    startTimeStr += startTime.toFormat('a')

  let endTimeStr = endTime?.toFormat('h:mma')
  if (endTime.minute === 0) endTimeStr = endTime.toFormat('ha')
  if (endTime.hour === 0 && endTime.minute === 0) endTimeStr = "midnight"

  // format day string
  let startDayStr: string
  if (startTime.hasSame(today, 'day')) {
    startDayStr = startTime.get('hour') < 17 ? "Today" : "Tonight"
  } else if (startTime.hasSame(today.plus({day: 1}), 'day')) {
    startDayStr = "Tomorrow"
  } else {
    startDayStr = startTime.toFormat('EEEE, MMMM d')
  }

  let timeStr = ""

  if (includeDay) timeStr += `${startDayStr}, `

  timeStr += `${startTimeStr.toLocaleLowerCase()}\u2013${endTimeStr.toLocaleLowerCase()}`

  return timeStr
}


type EventNameOptions = {
  artist: Artist,
}

export const getEventName = (event: Event, opts?: EventNameOptions): string | undefined => {
  if (event.title) return event.title

  const artists = event.artists ?? []

  if (opts?.artist) {
    if (event.artists && event.artists.length > 1) {
      const artistNames = artists.filter(a => a.id !== opts.artist.id).map(a => a.name)
      const separator = artistNames.length > 2 ? ", " : " & "
      return "With " + artistNames.join(separator)
    }

    return undefined
  }

  const artistNames = artists.map(a => a.name)
  const separator = artistNames.length > 2 ? ", " : " & "
  return artistNames.join(separator)
}

export const sortEvents = (a: Event, b: Event): number => {
  const timeA = a.startTime.getTime()
  const timeB = b.startTime.getTime()

  if (timeA !== timeB) return timeA - timeB

  const nameA = a.location.name
  const nameB = b.location.name

  return nameA.localeCompare(nameB)
}

export const formatEventTime = (event: Event): string => {
  const startTime = DateTime.fromJSDate(event.startTime)
  const endTime = event.endTime ? DateTime.fromJSDate(event.endTime) : startTime.plus({hours: 3})
  const interval = Interval.fromDateTimes(startTime, endTime)

  let startTimeString = interval.start.toFormat('h')
  if (interval.start.minute != 0) startTimeString += interval.start.toFormat(':mm')
  if (interval.start.toFormat('a') !== interval.end.toFormat('a') || !interval.hasSame('day')) {
    startTimeString += interval.start.toFormat('a').toLowerCase()
  }

  let endTimeString = interval.end.toFormat('h')
  if (interval.end.minute != 0) endTimeString += interval.end.toFormat(':mm')
  endTimeString += interval.end.toFormat('a').toLowerCase()
  if (interval.end.hour === 0 && interval.end.minute === 0) endTimeString = "midnight"

  return [startTimeString, endTimeString].join("-")
}

/**
 * Filter events with certain statuses. Don't look for closing bracket to allow
 * for additional text inside the [STATUS] block...
 */
const FILTERED_STATUSES = [
  "[CANCEL",
  "[CLOSED",
  "[RESCHEDULED",
  "[POSTPONED",
  "[PRIVATE",
]

export const filterStatusEvents = (events: Event[]): Event[] => {
  return events.filter(event => {
    return !FILTERED_STATUSES.find(s => event.title?.includes(s))
  })
}
