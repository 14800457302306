import React from 'react'
import {
  Box,
  IconButton,
  Link as MuiLink,
  SxProps,
  Theme,
} from '@mui/material'
import type {Link} from 'data/types'
import Icon from './Icon'

const baseListSx: SxProps<Theme> = {
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(40px, auto))',
  gap: 2,
}

export interface LinkButtonsProps {
  links?: Link[],
  justify?: 'start' | 'end' | 'center' | 'stretch',
}

const LinkButtons = ({links, justify}: LinkButtonsProps): JSX.Element | null => {
  if (!links) return null

  const listSx: SxProps<Theme> = {
    ...baseListSx,
    justifyContent: justify ?? 'center',
  }

  const edge = (justify === 'start' || justify === 'end') ? justify : false

  return (
    <Box component="ul" sx={listSx}>
      {links.map((link) => {
        // const Icon = getLinkIcon(link)

        return (
          <Box key={link.id} component="li">
            <IconButton
              component={MuiLink}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              edge={edge}
            >
              <Icon link={link}/>
            </IconButton>
          </Box>
        )
      })}
    </Box>
  )
}

export default LinkButtons
