import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {debounce} from 'lodash'
import {useSearchParams} from 'react-router-dom'
import {Searcher} from 'fast-fuzzy'
import {
  Autocomplete,
  Chip,
  FilterOptionsState,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material'
import type {Artist} from 'data/types'
import {useFetchArtistById, useFetchArtistByName} from 'artists/hooks'
import {getHandleFromLinks} from 'utils'

interface ArtistPickerProps {
  artists?: Artist[],
  onUpdate: (artists: Artist[]) => void,
}

const ArtistPicker = ({artists=[], onUpdate}: ArtistPickerProps): JSX.Element => {
  // ======================================================================
  // TODO: move loading artist from searchParams elsewhere
  // ======================================================================

  const [searchParams] = useSearchParams()
  const {fetchArtistById, result: fetchArtistByIdResult} = useFetchArtistById({fetchPolicy: 'cache-and-network'})

  const artistId = searchParams.get('artistId')

  if (artistId) {
    if (!fetchArtistByIdResult.called) fetchArtistById(artistId)
  }

  useEffect(() => {
    const artist = fetchArtistByIdResult.data?.artists?.[0]

    if (artist) onUpdate([artist])
  }, [onUpdate, fetchArtistByIdResult.data])

  // ======================================================================
  // END TODO
  // ======================================================================

  const {
    fetchArtistByName,
    artists: fetchedArtists = [],
    result: fetchArtistByNameResult,
  } = useFetchArtistByName({fetchPolicy: 'cache-and-network'})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchArtistsByName = useCallback(debounce(fetchArtistByName, 500), [fetchArtistByName])

  const fetchedArtistsUnion = useRef<Artist[]>([])
  const fetchedArtistsUnionIds = useRef<string[]>([])

  const newArtists = fetchedArtists.filter(a => !fetchedArtistsUnionIds.current.includes(a.id))
  const newArtistsIds = newArtists.map(a => a.id)

  fetchedArtistsUnion.current = [...fetchedArtistsUnion.current, ...newArtists]
  fetchedArtistsUnionIds.current = [...fetchedArtistsUnionIds.current, ...newArtistsIds]

  const selectedArtistIds = artists.map(a => a.id)
  const fetchedArtistIds = fetchedArtistsUnion.current.map(a => a.id)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searcher = useMemo(() => new Searcher(fetchedArtistsUnion.current, {keySelector: a => a.name}), [fetchedArtistsUnion.current])

  const options = [...fetchedArtistsUnion.current]
  artists.forEach((artist) => {
    if (!fetchedArtistIds.includes(artist.id)) options.push(artist)
  })

  const selectedOptions = options.filter(a => selectedArtistIds.includes(a.id))

  const filterOptions = (options: Artist[], state: FilterOptionsState<Artist>) => {
    let filteredOptions = searcher.search(state.inputValue) ?? []

    filteredOptions = filteredOptions.filter(a => !selectedArtistIds.includes(a.id))

    return filteredOptions
  }

  const loading = fetchArtistByIdResult.loading || fetchArtistByNameResult.loading

  const handleInputChange = (_event: React.SyntheticEvent, value: string) => {
    if (value.length > 0) debouncedFetchArtistsByName(value)
  }

  return (
    <Autocomplete
      multiple
      autoHighlight
      value={selectedOptions}
      filterOptions={filterOptions}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      onChange={(_e, value) => onUpdate(value)}
      onInputChange={handleInputChange}
      fullWidth
      options={options}
      getOptionLabel={o => o.name}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Who"
        />
      )}
      clearOnBlur={false}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip label={option.name} {...getTagProps({index})} disabled={option.id === artistId}/>
        ))
      }
      disableClearable={!!artistId}
      renderOption={(props, option) => {
        const handle = option.links && getHandleFromLinks(option.links)

        return (
          <ListItem {...props} key={option.id}>
            <ListItemText
              primary={option.name}
              primaryTypographyProps={{color: 'inherit', fontSize: {xs: '1.2em', sm: '1.3em'}}}
              secondary={handle ? '@' + handle : null}
              secondaryTypographyProps={{color: 'primary', fontSize: {xs: '1em', sm: '1.1em'}, fontWeight: 300}}
            />
          </ListItem>
        )
      }}
    />
  )
}

export default ArtistPicker
