import React from 'react'
import {
  Box,
  Typography,
} from '@mui/material'

interface TooltipLabelProps {
  title?: string,
  shortcut?: string,
  size?: number,
}

const TooltipLabel = ({title, shortcut, size=18}: TooltipLabelProps): JSX.Element => {
  return (
    <Box textAlign="center">
      {title && <Typography fontSize={size}>{title}</Typography>}
      {shortcut && <Typography fontSize={size * 0.75}>[{shortcut}]</Typography>}
    </Box>
  )
}

export default TooltipLabel
