import React from 'react'
import {DateTime, Interval} from 'luxon'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {
  Box,
  Card,
  CardActionArea,
  SxProps,
  Typography,
} from '@mui/material'
import type {Event} from 'data/types'
import {getPrimaryUrl} from 'utils/urls'

const cardSx: SxProps = {
  p: 2,
}

const nameSx: SxProps = {
  fontSize: {
    xs: 18,
    sm: 24,
  },
  fontWeight: 300,
  mb: 1,
}

const placeSx: SxProps = {
  fontSize: {
    xs: 16,
    sm: 20,
  },
  fontWeight: 400,
}

const localeSx: SxProps = {
  fontSize: {
    xs: 14,
    sm: 18,
  },
  fontWeight: 400,
}

interface EventCardProps {
  event: Event,
  listDate: DateTime,
}

const EventCard = ({event, listDate}: EventCardProps): JSX.Element => {
  const location = useLocation()

  let eventName = ""
  if (event.title) {
    eventName += `${event.title}`
  } else if (event.artists && event.artists.length > 0) {
    const artistNames = event.artists.map(a => a.name)
    const separator = artistNames.length > 2 ? ", " : " & "
    eventName += artistNames.join(separator)
  }

  const startTime = DateTime.fromJSDate(event.startTime)
  const endTime = event.endTime ? DateTime.fromJSDate(event.endTime) : startTime.plus({hours: 3})
  const interval = Interval.fromDateTimes(startTime, endTime)

  let startTimeString = interval.start.toFormat('h')
  if (interval.start.minute != 0) startTimeString += interval.start.toFormat(':mm')
  if (interval.start.toFormat('a') !== interval.end.toFormat('a') || !interval.hasSame('day')) {
    startTimeString += interval.start.toFormat('a').toLowerCase()
  }

  let endTimeString = interval.end.toFormat('h')
  if (interval.end.minute != 0) endTimeString += interval.end.toFormat(':mm')
  endTimeString += interval.end.toFormat('a').toLowerCase()

  const hasEnded = endTime < DateTime.now()

  const eventTimeString = hasEnded ? "Ended" : [startTimeString, endTimeString].join("-")
  const timeTextColor = hasEnded ? "text.disabled" : undefined

  const to = getPrimaryUrl(event)
  const state = {
    backgroundLocation: location,
  }

  let locale = event.location.physicalAddress?.neighborhood
  if (!locale) locale = event.location.physicalAddress?.locality
  if (!locale) locale = ""

  return (
    <Card elevation={0} sx={{mb: 1}}>
      <CardActionArea component={RouterLink} to={to} state={state} sx={cardSx}>
        <Box display='inline-flex' justifyContent='space-between' width='100%'>
          <Typography variant='h4' sx={nameSx}>{eventName}</Typography>
          <Typography color={timeTextColor} flexShrink={0} ml={2}>{eventTimeString}</Typography>
        </Box>
        <Box display='inline-flex' justifyContent='space-between' width='100%' alignItems='baseline'>
          <Typography color="primary.main" sx={placeSx}>{event.location.name}</Typography>
          <Typography color='success.main' sx={localeSx} flexShrink={0} ml={2}>{locale}</Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default EventCard
