import React from 'react'
import {
  Theme,
  useMediaQuery,
} from '@mui/material'
import type {Artist} from 'data/types'
import Desktop from './Desktop'
import Mobile from './Mobile'

interface ArtistDetailsProps {
  artist: Artist
}

const ArtistDetails = ({artist}: ArtistDetailsProps): JSX.Element | null => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (!artist) return null

  if (isMobile) return <Mobile artist={artist}/>

  return <Desktop artist={artist}/>
}

export default ArtistDetails
