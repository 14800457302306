import {gql, QueryHookOptions, QueryResult, useQuery} from '@apollo/client'

const ARTISTS_COUNT_QUERY = gql `
  query ArtistsCount {
    artistsAggregate {
      count
    }
  }
`

interface ArtistsCountData {
  artistsAggregate: {
    count: number,
  },
}

interface ArtistCountQueryResult {
  count?: number,
  result: QueryResult<ArtistsCountData>,
}

type ArtistsCountOptions = QueryHookOptions<ArtistsCountData>

export const useArtistsCount = (options?: ArtistsCountOptions): ArtistCountQueryResult => {
  const result = useQuery<ArtistsCountData>(ARTISTS_COUNT_QUERY, {
    fetchPolicy: 'network-only',
    ...options,
  })

  const count = result.data?.artistsAggregate.count

  return {
    count,
    result,
  }
}
