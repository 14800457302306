import React from 'react'
import {
  Box,
  Typography,
} from '@mui/material'
import type {Artist} from 'data/types'
import ActionsDial from 'artists/components/ActionsDial'
import ArtistEventsList from 'artists/components/EventsList'
import Description from 'artists/components/Description'
import Links from 'artists/components/Links'
import SpotifyPlayer from 'artists/components/SpotifyPlayer'

interface MobileArtistDetailsProps {
  artist: Artist,
}

const MobileArtistDetails = ({artist}: MobileArtistDetailsProps): JSX.Element | null => {
  const {name, description, links} = artist

  return (
    <Box display="grid" gap={1} sx={{m: 2}}>
      <ActionsDial artist={artist}/>

      <Box mb={1}>
        <Typography
          variant="h2"
          my={2}
          textAlign="center"
          color="warning.main"
        >
          {name}
        </Typography>
      </Box>

      <SpotifyPlayer links={links} height="80" mx={0.5}/>
      <Description description={description}/>
      <ArtistEventsList artist={artist} my={1}/>
      <Links links={links}/>
    </Box>
  )
}

export default MobileArtistDetails
