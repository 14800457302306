import {setContext} from '@apollo/client/link/context'
import {getJwt} from 'data/auth'

const authLink = setContext(async (_, {headers}) => {
  const token = getJwt()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

export default authLink
