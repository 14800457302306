import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Box,
  Button,
  SxProps,
  Theme,
} from '@mui/material'
import {useRoles} from 'auth/hooks'

const listSx: SxProps<Theme> = {
  display: 'grid',
  listStyleType: 'none',
  mx: 0,
  my: 1,
  p: 0,

  '& > *': {
    width: '100%',
  },
}

const buttonSx: SxProps<Theme> = {
  display: 'block',
  px: 4,
  py: 1,
  textTransform: 'none',
  color: 'inherit',
  fontSize: 18,
  fontWeight: 400,
  textAlign: 'left',
  width: '100%',
  borderRadius: 0,
}

const MobileNav = (): JSX.Element => {
  const {isAdmin, isContentManager} = useRoles()

  return (
    <Box component="ul" sx={listSx}>
      <Box component="li">
        <Button
          component={RouterLink}
          to="/"
          replace
          sx={buttonSx}
        >
          Home
        </Button>
      </Box>
      <Box component="li">
        <Button
          component={RouterLink}
          to="/artists"
          replace
          sx={buttonSx}
        >
          Artists
        </Button>
      </Box>
      <Box component="li">
        <Button
          component={RouterLink}
          to="/places"
          replace
          sx={buttonSx}
        >
          Places
        </Button>
      </Box>

      {(isAdmin || isContentManager) &&
        <Box component="li">
          <Button
            component={RouterLink}
            to="/admin"
            replace
            sx={buttonSx}
          >
            Admin
          </Button>
        </Box>
      }
    </Box>
  )
}

export default MobileNav
