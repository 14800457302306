import React from 'react'
import {
  Box,
  Chip,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import type {Handle} from 'data/types'
import {useShowDialogs} from 'router/hooks'
import NewHandleDialog, {NEW_HANDLE_DIALOG_NAME} from './NewHandleDialog'

interface HandlesEditorProps {
  handles?: Handle[],
  primaryHandle?: Handle,
  setPrimaryHandle: (primaryHandle: Handle) => void,
  addHandle: (handle: Handle) => void,
}

const HandlesEditor = ({handles, primaryHandle, setPrimaryHandle, addHandle}: HandlesEditorProps): JSX.Element => {
  const {openDialog} = useShowDialogs()

  const handleOpenHandleDialog = () => openDialog(NEW_HANDLE_DIALOG_NAME)

  return (
    <Box component="fieldset" m={0} borderRadius={1} borderColor="inherit">
      <Typography component="legend" color="inherit">Handles</Typography>
      <Box display="flex">
        {handles?.map((handle) => {
          const {name} = handle

          const handleClick = () => setPrimaryHandle(handle)

          const isPrimary = name === primaryHandle?.name

          return (
            <Chip
              key={name}
              label={name}
              color={isPrimary ? "primary" : "default"}
              onClick={isPrimary ? undefined : handleClick}
              sx={{mr: 1}}
            />
          )
        })}

        <Chip
          label="Add Handle"
          icon={<AddIcon/>}
          onClick={handleOpenHandleDialog}
        />
      </Box>

      <NewHandleDialog addHandle={addHandle}/>
    </Box>
  )
}

export default HandlesEditor
