import React from 'react'
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  SxProps,
  Toolbar,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import HomeLink from 'hapnyn/components/HomeLink'
import MobileNav from 'hapnyn/components/Nav/Mobile'
import {useLocation, useNavigate} from 'react-router-dom'

const toolbarSx: SxProps = {
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const MobileMenu = (): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()

  const state = location.state as {showMenu?: boolean}
  const showMenu = state?.showMenu ?? false

  const toggleDrawer = () => navigate(location, {state: {showMenu: !showMenu}})

  return (
    <AppBar
      position="sticky"
      color="primary"
      enableColorOnDark
      elevation={0}
    >
      <Toolbar variant="dense" sx={toolbarSx}>
        <HomeLink/>
        <IconButton
          size="large"
          edge="end"
          aria-label="menu"
          onClick={toggleDrawer}
        >
          <MenuIcon/>
        </IconButton>
      </Toolbar>

      <Drawer anchor="right" open={showMenu} onClose={toggleDrawer}>
        <Box p={1} textAlign="right" minWidth={300}>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon/>
          </IconButton>
        </Box>
        <Divider/>
        <MobileNav/>
      </Drawer>
    </AppBar>
  )
}

export default MobileMenu
