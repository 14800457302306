import {useCallback} from 'react'
import {gql, QueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_EVENT_FIELDS} from 'data/fragments/event'
import {Place} from 'data/types'

interface FetchPlaceEventsVars {
  placeId: string,
}

const FETCH_PLACE_EVENTS_QUERY = gql`
  ${CORE_EVENT_FIELDS}

  query FetchPlaceEvents($placeId: ID!) {
    places(
      where: {id: $placeId}
    ) {
      id
      events(
        options: {sort: {startTime: ASC}}
      ) {
        ...CoreEventFields
      }
    }
  }
`

interface FetchPlaceEventsData {
  places: Place[],
}

interface UseFetchPlaceEventsResult {
  fetchPlaceEvents: () => void,
  result: QueryResult<FetchPlaceEventsData, FetchPlaceEventsVars>,
}

type FetchPlaceEventsOptions = QueryHookOptions<FetchPlaceEventsData, FetchPlaceEventsVars>

export const useFetchPlaceEvents = (placeId: string, options?: FetchPlaceEventsOptions): UseFetchPlaceEventsResult => {
  const [query, result] = useLazyQuery<FetchPlaceEventsData, FetchPlaceEventsVars>(FETCH_PLACE_EVENTS_QUERY, options)

  const fetchPlaceEvents = useCallback(() => {
    if (!result.called) query({variables: {placeId}})
  }, [result.called, query, placeId])

  return {
    fetchPlaceEvents,
    result,
  }
}
