import React, {useEffect, useRef, useState} from 'react'
import {useImmer} from 'use-immer'
import {DateTime} from 'luxon'
import * as Chrono from 'chrono-node'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import InfoIcon from '@mui/icons-material/Info'
import DatePicker from './DatePicker'

const leftCellSx: SxProps = {
  pl: 0.25,
  pr: 1.5,
  py: 0,
}

const rightCellSx: SxProps = {
  pl: 1.5,
  pr: 0.25,
  py: 0,
}

const tooltip =
<Table>
  <TableBody>
    <TableRow>
      <TableCell sx={leftCellSx}>←</TableCell>
      <TableCell sx={rightCellSx}>Previous day</TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={leftCellSx}>→</TableCell>
      <TableCell sx={rightCellSx}>Next day</TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={leftCellSx}>↑</TableCell>
      <TableCell sx={rightCellSx}>Previous week</TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={leftCellSx}>↓</TableCell>
      <TableCell sx={rightCellSx}>Next week</TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={leftCellSx}>{'<'}</TableCell>
      <TableCell sx={rightCellSx}>Previous month</TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={leftCellSx}>{'>'}</TableCell>
      <TableCell sx={rightCellSx}>Next month</TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={leftCellSx}>t</TableCell>
      <TableCell sx={rightCellSx}>Today</TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={leftCellSx}>Enter</TableCell>
      <TableCell sx={rightCellSx}>Select date</TableCell>
    </TableRow>
  </TableBody>
</Table>

const WITHOUT_YEAR = 'EEEE, LLLL d'
const WITH_YEAR = 'EEEE, LLLL d, y'

const formatDate = (date?: DateTime): string => {
  if (!date) return ""

  const now = DateTime.now()

  return date.hasSame(now, 'year') ? date.toFormat(WITHOUT_YEAR) : date.toFormat(WITH_YEAR)
}

export const parseDate = (input: string) => {
  const [parsedResult] = Chrono.parse(input)

  const parsedStart = parsedResult?.start
  const parsedDate = parsedStart?.isCertain('day') ? parsedStart.date() : undefined

  return parsedDate ? DateTime.fromJSDate(parsedDate).startOf('day') : undefined
}

interface DateSelectorProps {
  date?: DateTime,
  minDate?: DateTime,
  onChange: (date?: DateTime) => void,
  label?: string,
  error?: string,
  autoFocus?: boolean,
}

const DateSelector = ({date, minDate, onChange, label="Date", error, autoFocus=false}: DateSelectorProps) => {
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [dateInput, setDateInput] = useImmer(formatDate(date))
  const [showCalendarDialog, setShowCalendarDialog] = useState(false)

  useEffect(() => {
    setDateInput(formatDate(date))

    if (inputRef.current?.ownerDocument.activeElement === inputRef.current) {
      // update selection if focused
      setTimeout(() => inputRef.current?.select(), 50)
    }
  }, [date, setDateInput])

  useEffect(() => {
    if (buttonRef.current) buttonRef.current.tabIndex = -1
  }, [])


  const handleInputChange = (input: string) => {
    setDateInput(input)
  }

  const parseInput = () => {
    let parsedDateTime = parseDate(dateInput) ?? minDate ?? date

    if (parsedDateTime && date) {
      parsedDateTime = parsedDateTime.set({
        hour: date.hour,
        minute: date.minute,
      })
    }

    if (parsedDateTime && minDate && parsedDateTime <= minDate) {
      parsedDateTime = parsedDateTime.set({
        year: minDate.year,
        month: minDate.month,
        day: minDate.day,
      })
    }

    return parsedDateTime
  }

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    event.target.select()
  }

  const handleBlur = () => {
    const parsedDateTime = parseInput()

    setDateInput(formatDate(parsedDateTime))
    onChange(parsedDateTime)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        setShowCalendarDialog(!showCalendarDialog)
        break
    }
  }

  const openDialog = () => {
    setShowCalendarDialog(true)
  }

  const closeDialog = () => {
    setShowCalendarDialog(false)
  }

  const handleDateChange = (date: DateTime) => {
    setDateInput(formatDate(date))
    onChange(date)

    closeDialog()
  }

  const helperText = error ?? 'Enter for calendar'

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const justify = isMobile ? 'flex-end' : 'space-between'

  return (
    <>
      <TextField
        inputRef={inputRef}
        label={label}
        value={dateInput}
        onChange={e => handleInputChange(e.target.value)}
        error={!!error}
        helperText={helperText}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoFocus={autoFocus}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment:
            <InputAdornment position="end" sx={{mr: -1}}>
              <IconButton
                color="inherit"
                size="small"
                onClick={openDialog}
                ref={buttonRef}
              >
                <CalendarIcon/>
              </IconButton>
            </InputAdornment>,
        }}
      />
      <Dialog
        open={showCalendarDialog}
        onClose={() => closeDialog()}
      >
        <DialogContent>
          <DatePicker date={date} handleChange={handleDateChange} showNonMonthDays startOnSun/>
        </DialogContent>
        <DialogActions sx={{justifyContent: justify}}>
          {!isMobile &&
          <Tooltip title={tooltip}>
            <IconButton>
              <InfoIcon color='info'/>
            </IconButton>
          </Tooltip>}
          <Button
            onClick={() => closeDialog()}
          >Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DateSelector
