import type {Address} from 'data/types'

export const addressToString = (address: Address): string => {
  const {street, neighborhood, locality, region, postalCode} = address

  const addressComponents: string[] = []

  if (street) {
    addressComponents.push(street)
  } else if (neighborhood) {
    addressComponents.push(neighborhood)
  }

  addressComponents.push(locality)
  addressComponents.push(`${region} ${postalCode}`)

  return addressComponents.filter(c => c).join(", ")
}
