import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Button,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import {getPrimaryUrl} from 'utils/urls'
import {Event, Place} from 'data/types'
import PlaceLinks from './PlaceLinks'

interface PlaceRowProps {
  place: Place,
  name: string,
  event: Event,
}

const PlaceRow = ({place, name, event}: PlaceRowProps): JSX.Element => {
  return (
    <TableRow>
      <TableCell sx={{py: 0}}>
        <Button
          sx={{textTransform: 'none'}}
          component={RouterLink}
          to={getPrimaryUrl(place)}
          target="_blank"
          rel="noopener noreferrer"
          size="large"
        >
          <Typography fontSize={18}>
            {place.name}
          </Typography>
        </Button>
      </TableCell>
      <TableCell sx={{py: 0}}>
        <Button
          sx={{textTransform: 'none', color: 'primary.contrastText'}}
          component={RouterLink}
          to={getPrimaryUrl(event)}
          target='_blank'
          rel='noopener noreferrer'
          size='large'
        >
          <Typography>{name}</Typography>
        </Button>
      </TableCell>
      <TableCell sx={{py: 0}}>
        <PlaceLinks place={place}/>
      </TableCell>
    </TableRow>
  )
}

export default PlaceRow
