import React from 'react'
import {Link} from 'react-router-dom'
import {
  Box,
  Button,
  SxProps,
} from '@mui/material'
import {useAuthStatus, useSignOut} from 'auth/hooks'

const linkButtonSx: SxProps = {
  color: 'inherit',

  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
}

const AuthMenu = (): JSX.Element => {
  const {isSignedIn} = useAuthStatus()
  const signOut = useSignOut()

  const signInButton = (
    <Button component={Link} to="/signin" sx={linkButtonSx}>Sign In</Button>
  )

  const signUpButton = (
    <Button component={Link} to="/signup" sx={linkButtonSx}>Sign Up</Button>
  )

  const signOutButton = (
    <Button sx={linkButtonSx} onClick={signOut}>Sign Out</Button>
  )

  return (
    <Box display="grid" gap={1} gridAutoFlow="column">
      {!isSignedIn && signInButton}
      {/* {!isSignedIn && signUpButton} */}

      {isSignedIn && signOutButton}
    </Box>
  )
}

export default AuthMenu
