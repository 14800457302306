import React from 'react'
import {useParams} from 'react-router-dom'
import {
  Container,
  Paper,
} from '@mui/material'
import type {LocationParams} from 'types/location'
import {useFetchEventById} from 'events/hooks'
import EventEditor from 'events/components/Editor'

const EditEventView = (): JSX.Element | null => {
  const {id} = useParams<LocationParams>()
  const {fetchEventById, event} = useFetchEventById({fetchPolicy: 'network-only'})

  if (!id) return null

  fetchEventById(id)

  if (!event) return null

  return (
    <Container maxWidth="md">
      <Paper sx={{my: 2, p: 2}}>
        {event && <EventEditor event={event}/>}
      </Paper>
    </Container>
  )
}

export default EditEventView
