import React from 'react'
import {
  Box, BoxProps,
} from '@mui/material'
import {googleApiKey} from 'google/utils'

interface GoogleMapProps extends BoxProps {
  placeId: string,
}

const GoogleMap = ({placeId, ...boxProps}: GoogleMapProps): JSX.Element | null => {
  const apiKey = googleApiKey()

  if (!apiKey) return null

  const src = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=place_id:${placeId}`

  return (
    <Box
      {...boxProps}
      component="iframe"
      sx={{border :0}}
      loading="lazy"
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src={src}
    />
  )
}

export default GoogleMap
