import React from 'react'
import {
  IconButton,
} from '@mui/material'
import CalendarIcon from '@mui/icons-material/CalendarMonth'
import {useLocation, useNavigate} from 'react-router-dom'

const DateSelectorButton = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()

  const handleChangeDate = () => {
    navigate(location, {state: {showDateSelector: true}})
  }

  return (
    <>
      <IconButton
        color="inherit"
        size="small"
        onClick={handleChangeDate}
      >
        <CalendarIcon/>
      </IconButton>
    </>
  )
}

export default DateSelectorButton
