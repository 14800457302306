import React from 'react'
import {
  Box, SxProps,
} from '@mui/material'
import AuthLayout from 'auth/Layout'
import SignUpForm from 'auth/SignUpForm'

const wrapperSx: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}

const SignUpView = (): JSX.Element => {
  return (
    <AuthLayout>
      <Box sx={wrapperSx}>
        <SignUpForm/>
      </Box>
    </AuthLayout>
  )
}

export default SignUpView
