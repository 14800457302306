import React, {useMemo, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {Searcher} from 'fast-fuzzy'
import {
  Box,
  Card,
  CardActionArea,
  CircularProgress,
  IconButton,
  InputBase,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {getPrimaryUrl} from 'utils/urls'
import {useFetchArtistByName} from 'artists/hooks'

const headerSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  my: 2,
  px: 2,
  py: 1,
  backgroundColor: theme => theme.palette.info.main,
  borderRadius: 1,
  userSelect: 'none',
}

const searchWrapperSx: SxProps<Theme> = {
  backgroundColor: theme => theme.palette.common.white,
  px: 2,
  borderRadius: 5,
  mb: {
    xs: 2,
    md: 0,
  },
  display: 'flex',
  justifyContent: 'space-between',
}

const searchInputSx: SxProps<Theme> = {
  color: theme => theme.palette.info.main,
  minWidth: 300,
}

const searchInputMobileSx: SxProps<Theme> = {
  color: theme => theme.palette.info.main,
  flexGrow: 1,
}

const searchIconSx: SxProps<Theme> = {
  color: theme => theme.palette.info.main,
}

const listSx: SxProps = {
  listStyleType: 'none',
  margin: 0,
  padding: 0,
}

const ArtistList = (): JSX.Element | null => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [search, setSearch] = useState("")
  const [value, setValue] = useState("")

  const {
    fetchArtistByName,
    artists: fetchedArtists = [],
    result: fetchArtistByNameResult,
  } = useFetchArtistByName({fetchPolicy: 'cache-and-network'})

  const searcher = useMemo(() => new Searcher(fetchedArtists, {keySelector: p => p.name}), [fetchedArtists])

  const handleSearchChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const searchArtists = () => {
    if (value.length == 0) return

    setSearch(value)
    fetchArtistByName(value)
  }

  const filteredArtists = search.length > 0 ? searcher.search(search) : []

  const showLoader = fetchArtistByNameResult.loading && search.length > 0

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        searchArtists()
        break
    }
  }

  return (
    <Box>
      <Box sx={headerSx}>
        <Typography variant="h2" fontSize={24}>Hapnyn Artists</Typography>
        {!isMobile &&
          <Box sx={searchWrapperSx}>
            <InputBase
              value={value}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              placeholder="Search"
              autoFocus
              sx={searchInputSx}
            />
            <IconButton
              sx={searchIconSx}
              onClick={searchArtists}
            >
              <SearchIcon/>
            </IconButton>
          </Box>
        }
      </Box>

      {isMobile &&
        <Box sx={searchWrapperSx}>
          <InputBase
            value={value}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            placeholder="Search"
            autoFocus
            sx={searchInputMobileSx}
          />
          <IconButton
            sx={searchIconSx}
            onClick={searchArtists}
          >
            <SearchIcon sx={{my: 0}}/>
          </IconButton>
        </Box>
      }

      {!showLoader && filteredArtists.length === 0 && search &&
        <Card elevation={0} sx={{p: 2, textAlign: 'center'}}>
          <Typography variant="h4" color="warning.main">
            No artists match your search
          </Typography>
        </Card>
      }

      <Box component="ul" sx={listSx}>
        {filteredArtists.map((artist) => {
          const artistUrl = getPrimaryUrl(artist)

          return (
            <Card key={artist.id} component="li" elevation={0} sx={{mb: 1}}>
              <CardActionArea
                component={RouterLink}
                to={artistUrl}
                sx={{p: 2}}
              >
                <Typography variant="h4" color="warning.main">
                  {artist.name}
                </Typography>
              </CardActionArea>
            </Card>
          )
        })}
      </Box>

      {showLoader &&
        <Card elevation={0} sx={{mb: 2, pt: 2, pb: 1, textAlign: 'center'}}>
          <CircularProgress size={36} color="warning"/>
        </Card>
      }
    </Box>
  )
}

export default ArtistList
