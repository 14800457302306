import React from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {xor} from 'lodash'
import {v4 as uuid} from 'uuid'
import {useImmer} from 'use-immer'
import {
  Box,
  Button,
  TextField,
  Tooltip,
} from '@mui/material'
import type {Handle, Place} from 'data/types'
import HandlesEditor from 'ui/HandlesEditor'
import LinksEditor from 'ui/LinksEditor'
import {useUpdatePlace} from 'places/hooks'
import {useReturnLocation} from 'router/hooks'
import {getPrimaryUrl} from 'utils/urls'
import {useHotkeys} from 'react-hotkeys-hook'
import {ctrlOs} from 'utils'
import TooltipLabel from 'ui/TooltipLabel'

type PlaceEditorProps = {
  place: Place,
}

const PlaceEditor = ({place}: PlaceEditorProps): JSX.Element => {
  const [placeState, setPlaceState] = useImmer(place)
  const {updatePlace, result} = useUpdatePlace(place)
  const navigate = useNavigate()
  const returnLocation = useReturnLocation()

  useHotkeys('ctrl+s, meta+s', () => savePlace(),
    {enableOnFormTags: true, preventDefault: true})
  useHotkeys('ctrl+., meta+.', () => handleCancel(),
    {enableOnFormTags: true, preventDefault: true})

  const placeUrl = getPrimaryUrl(place)

  if (result.data) {
    return <Navigate to={returnLocation ?? placeUrl} replace/>
  }

  const setName = (name: string) => {
    setPlaceState((draft) => {draft.name = name})
  }

  const setDescription = (content: string) => {
    setPlaceState((draft) => {
      if (content.length > 0) {
        draft.description = {
          id: uuid(),
          format: 'PLAIN',
          content,
        }
      } else {
        draft.description = undefined
      }
    })
  }

  const addLink = (url: string) => {
    setPlaceState((draft) => {
      if (!draft.links) draft.links = []

      draft.links.push({id: uuid(), url})
    })
  }

  const removeLink = (linkId: string) => {
    setPlaceState(draft => {draft.links = draft.links?.filter(l => l.id !== linkId)})
  }

  const handleCancel = () => navigate(returnLocation ?? placeUrl, {replace: true})

  const handleUpdatePlace = () => {
    updatePlace(placeState)
  }

  const setPrimaryHandle = (primaryHandle: Handle) => {
    setPlaceState(draft => {draft.primaryHandle = primaryHandle})
  }

  const addHandle = (handle: Handle) => {
    setPlaceState((draft) => {
      if (!draft.handles) draft.handles = []

      const handleNames = draft.handles.map(h => h.name)

      if (!handleNames.includes(handle.name)) draft.handles.push(handle)

      draft.primaryHandle = handle
    })
  }

  const hasChanges = (): boolean => {
    if (place.name !== placeState.name) return true
    if (place.description?.content !== placeState.description?.content) return true

    const originalLinks = place.links?.map(l => l.url) ?? []
    const updatedLinks = placeState.links?.map(l => l.url) ?? []
    if (xor(originalLinks, updatedLinks).length > 0) return true

    const originalHandles = place.handles?.map(h => h.name) ?? []
    const updatedHandles = placeState.handles?.map(h => h.name) ?? []
    if (xor(originalHandles, updatedHandles).length > 0) return true

    if (place.primaryHandle?.name !== placeState.primaryHandle?.name) return true

    return false
  }

  const savePlace = () => {
    if (hasChanges()) handleUpdatePlace()
  }

  return (
    <Box display="grid" gap={2}>
      <TextField
        label="Place Name"
        value={placeState.name}
        onChange={e => setName(e.target.value)}
        fullWidth
      />
      <HandlesEditor
        handles={placeState.handles}
        primaryHandle={placeState.primaryHandle}
        setPrimaryHandle={setPrimaryHandle}
        addHandle={addHandle}
      />
      <TextField
        label="Description"
        value={placeState.description?.content ?? ""}
        onChange={e => setDescription(e.target.value)}
        fullWidth
        multiline
      />
      <LinksEditor links={placeState.links} addLink={addLink} removeLink={removeLink}/>
      <Box display="grid" gap={2} gridAutoFlow="column" justifyContent="end">
        <Tooltip title={<TooltipLabel shortcut={`${ctrlOs()} + Period`}/>}>
          <Button
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Tooltip>
        <Tooltip title={<TooltipLabel shortcut={`${ctrlOs()} + S`}/>}>
          <Box>
            <Button
              onClick={handleUpdatePlace}
              variant="contained"
              disableElevation
              disabled={!hasChanges()}
            >
              Update Place
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default PlaceEditor
