import React, {useRef, useEffect, useCallback} from 'react'
import {useImmer} from 'use-immer'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  Paper,
  SxProps,
  Theme,
  Typography,
  IconButton,
} from '@mui/material'
import type {Event} from 'data/types'
import {InstagramCard} from './Card'
import {TitleCard} from './Card/TitleCard'
import {EndCard} from './Card/EndCard'
import Button from '@mui/material/Button'
import * as htmlToImage from 'html-to-image'
import JSZip from 'jszip'
import download from 'downloadjs'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

const MAX_EVENTS_PER_CARD = 5

const MAX_CARDS_PER_PART = 8
const INITIAL_CARDS_PER_PART = 4

const partSx: SxProps<Theme> = {
  color: "#AAA",
  pt: "4px",
}

interface InstagramPanelProps {
  events: Event[],
}

export const InstagramPanel = ({events}: InstagramPanelProps): JSX.Element | null => {
  const [cards, setCards] = useImmer<Event[][]>([])
  const domElementRefs = useRef<HTMLElement[]>([])
  const [downloading, setDownloading] = useImmer(false)
  const partsCount = useRef<number[]>([])

  const resetCards = useCallback((events: Event[]) => {
    const newCards = []

    for (let i = 0; i < events.length; i += MAX_EVENTS_PER_CARD) {
      newCards.push(events.slice(i, i + MAX_EVENTS_PER_CARD))
    }

    setCards(newCards)

    const numParts = Math.trunc(newCards.length / INITIAL_CARDS_PER_PART)

    const newCardsPerPart: number[] = Array(numParts).fill(INITIAL_CARDS_PER_PART)

    if (newCards.length % INITIAL_CARDS_PER_PART) newCardsPerPart.push(newCards.length % INITIAL_CARDS_PER_PART)

    partsCount.current = newCardsPerPart
    // console.log(newCardsPerPart, newCards.length)
  }, [setCards])

  useEffect(() => resetCards(events), [events, resetCards])

  const refreshParts = (cards2: Event[][]) => {
    const newCardsPerPart: number[] = []

    let cardCount = 0

    for (let i = 0; i < partsCount.current.length; i++) {
      const cardsThisPart = partsCount.current[i]
      const cardsNextPart = partsCount.current[i + 1]

      newCardsPerPart[i] = partsCount.current[i]

      cardCount += cardsThisPart

      if (!cardsNextPart) {
        if (cardCount < cards2.length) {
          const cardsMissing = cards2.length - cardCount
          const cardsLeftThisPart = INITIAL_CARDS_PER_PART - cardsThisPart

          if (cardsMissing > cardsLeftThisPart) {
            newCardsPerPart[i + 1] = cardsMissing
          } else {
            newCardsPerPart[i] += cardsMissing
          }

          partsCount.current = newCardsPerPart
          // console.log(partsCount.current, cards2.length)

          return
        }

        if (cardCount > cards2.length) {
          const extraCards = cardCount - cards2.length

          newCardsPerPart[i] -= extraCards

          if (newCardsPerPart[i] == 0) newCardsPerPart.pop()

          partsCount.current = newCardsPerPart
          // console.log(partsCount.current, cards2.length)

          return
        }
      }
    }
  }

  const changePartCount = (index: number, value: number) => {
    const newCardsPerPart: number[] = partsCount.current.slice()

    newCardsPerPart[index] += value

    newCardsPerPart.length = index + 1

    const newPartCount = newCardsPerPart[index]

    let cardCount = 0

    for (let i = 0; i <= index; i++) {
      cardCount += newCardsPerPart[i]
    }

    for (let i = index + 1; cardCount < cards.length; i++) {
      const cardsLeft = cards.length - cardCount
      const cardsAdded = (cardsLeft > newPartCount ) ? newPartCount : cardsLeft

      newCardsPerPart[i] = cardsAdded
      cardCount += cardsAdded
    }

    partsCount.current = newCardsPerPart
    // console.log(newCardsPerPart, cards.length)

    // force refresh (bad)
    setCards(cards.slice())
  }

  const getAllCardsCount = (): number => {
    let allCardsCount = 0

    for (let i = 0; i < partsCount.current.length; i++) {
      allCardsCount += (partsCount.current[i] + 2)
    }

    return allCardsCount
  }

  const increaseCardCount = (index: number) => {
    setCards((draft) => {
      for (let i = index; i < draft.length; i++) {
        const card = draft[i]
        const nextCard = draft[i + 1]

        if (card.length === MAX_EVENTS_PER_CARD) {
          refreshParts(draft)
          return
        }

        if (nextCard) {
          const shiftedEvent = nextCard.shift()

          if (shiftedEvent) card.push(shiftedEvent)
        } else {
          if (card.length === 0) draft.pop()
        }
      }
      refreshParts(draft)
    })
  }

  const decreaseCardCount = (index: number) => {
    setCards((draft) => {
      for (let i = index; i < draft.length; i++) {
        const card = draft[i]
        const nextCard = draft[i + 1]

        if (card.length === 1) {
          refreshParts(draft)
          return
        }

        if (nextCard) {
          const shiftedEvent = card.pop()

          if (shiftedEvent) nextCard.unshift(shiftedEvent)
        } else {
          if (card.length > MAX_EVENTS_PER_CARD || (index + 1) === draft.length) {
            const shiftedEvent = card.pop()

            if (shiftedEvent) draft.push([shiftedEvent])
          }
        }
      }
      refreshParts(draft)
    })
  }

  const hideEvent = (id: string) => {
    setCards((draft) => {
      for (let i = 0; i < draft.length; i++) {
        draft[i] = draft[i].filter(e => e.id !== id)
      }
    })
  }

  const downloadCards = async (part?: number) => {
    setDownloading(true)

    const date = events[0].startTime
    const dateString = date.toISOString().split('T')[0]

    const zip = new JSZip()

    const prefix = "IG_" + dateString + "_part_"

    for (let i = 0; i < domElementRefs.current.length; i++) {

      const partInfo = getPartsFromCount(i)

      const row = partInfo.part
      const col = partInfo.card

      if (part && row != part) continue

      const filename = prefix + row + "-" + col + ".png"

      const blob = await htmlToImage.toBlob(domElementRefs.current[i], {pixelRatio: 1})

      if (blob) {
        zip.file(filename, blob)
      } else {
        console.error("Error: no image blob")
      }
    }

    const content = await zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
    })

    const zipfile = "IG_" + dateString + ((part) ? "_part_" + part : "_all") + ".zip"

    download(content, zipfile)

    setDownloading(false)
  }

  domElementRefs.current = Array(getAllCardsCount())

  const getPartsFromCount = (i: number): {card: number, part: number} => {
    let cardCount = 0
    let partIndex = 0

    for (let j = 0; j < partsCount.current.length; j++) {
      partIndex = j
      cardCount += partsCount.current[j] + 2
      if (i < cardCount) break
    }

    const partCardCount = partsCount.current[partIndex]

    const part = partIndex + 1

    const card = (partCardCount + 3) - (cardCount - i)

    return ({
      card,
      part,
    })
  }

  const cardIterator = [...Array(getAllCardsCount())]
  let cardNum = 0

  if (cards.length == 0) return null

  return (
    <Paper>
      <Box p={3} display="flex" flexDirection="column" alignItems="flex-start">
        {cardIterator.map((_, i) => {
          const dateString = events[0]?.startTime.toLocaleDateString('en-us', {weekday:"long", month:"long", day:"numeric"})

          const {part, card} = getPartsFromCount(i)
          const partIndex = part - 1

          const partCardCount = partsCount.current[partIndex]

          const isTitleCard = (card == 1)
          const isEndCard = (card == partCardCount + 2)
          const isEventCard = !isTitleCard && !isEndCard

          const cardIndex = cardNum
          const cardEvents = isEventCard ? cards[cardIndex] : []

          const eventCardsLeft = cards.length - cardIndex

          if (!isTitleCard && !isEndCard) cardNum++

          const partLabel = <Typography key={i} sx={partSx}>Part {part}/{partsCount.current.length} - Card {card}/{partCardCount + 2}</Typography>
          // console.log(`${i} (${cardIndex}) ${part}/${cardsPerPart.length} - ${card}/${partCardCount + 2} (${eventCardsLeft} left)`)
          return (
            <Box
              key={i}
              display="flex"
              flexDirection="column"
              mb={6}
            >
              {isTitleCard &&
              <Box>
                <Box>
                  <Box ref={(el: HTMLElement) => domElementRefs.current[i] = el}>
                    <TitleCard date={dateString} part={part}/>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2} width="55%">
                  {partLabel}
                  <Box ml={4} display="grid" gridAutoFlow="column" gap={2}>
                    <Box display="flex" alignItems="center">
                      <IconButton
                        size="small"
                        onClick={() => changePartCount(partIndex, -1)}
                        disabled={partCardCount <= 2}
                      >
                        <RemoveIcon/>
                      </IconButton>
                      <Typography mx={2}>
                        {partCardCount}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => changePartCount(partIndex, 1)}
                        disabled={partCardCount >= MAX_CARDS_PER_PART || partCardCount >= eventCardsLeft}
                      >
                        <AddIcon/>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
              }

              {isEventCard &&
              <Box>
                <Box>
                  <Box ref={(el: HTMLElement) => domElementRefs.current[i] = el}>
                    <InstagramCard events={cardEvents} hideEvent={hideEvent}/>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  {partLabel}
                  <Box ml={4} display="grid" gridAutoFlow="column" gap={2}>
                    <Box display="flex" alignItems="center">
                      <IconButton
                        size="small"
                        onClick={() => decreaseCardCount(cardIndex)}
                        disabled={cardEvents?.length === 1}
                      >
                        <RemoveIcon/>
                      </IconButton>
                      <Typography mx={2}>
                        {cardEvents?.length}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => increaseCardCount(cardIndex)}
                        disabled={cardEvents?.length === MAX_EVENTS_PER_CARD || cardIndex >= (cards?.length - 1)}
                      >
                        <AddIcon/>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
              }

              {isEndCard &&
              <Box>
                <Box>
                  <Box ref={(el: HTMLElement) => domElementRefs.current[i] = el}>
                    <EndCard/>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  {partLabel}
                  <Button
                    variant="contained"
                    onClick={() => downloadCards(part)}
                  >
                    Download Part {part}
                  </Button>
                </Box>
              </Box>
              }
            </Box>
          )
        })}
      </Box>
      <Button
        variant="contained"
        disableElevation
        sx={{ml: 3, mb: 3}}
        onClick={() => downloadCards()}
      >
        Download All
      </Button>
      <Dialog open={downloading}>
        <DialogTitle>Generating Zip file<CircularProgress size={30} sx={{ml: 2}}/></DialogTitle>
      </Dialog>
    </Paper>
  )
}
