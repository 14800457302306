import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material'

type CounterCardProps = {
  label: string,
  count?: number,
  loading?: boolean,
  error?: string,
  path: string,
}

const CounterCard = ({label, count, loading, error, path}: CounterCardProps): JSX.Element => {
  let value = <Typography fontSize={48}>{count}</Typography>

  if (loading) value = <CircularProgress/>
  if (error) value = <Typography fontSize={36} lineHeight={2} color="error">Error</Typography>

  return (
    <Card>
      <CardActionArea component={RouterLink} to={path}>
        <CardContent sx={{textAlign: 'center'}}>
          {value}
          <Typography fontSize={24} textAlign="center">{label}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CounterCard
