import {useCallback} from 'react'
import {DateTime} from 'luxon'
import {gql, QueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_EVENT_FIELDS} from 'data/fragments/event'
import type {Event} from 'data/types'

const FETCH_EVENTS_BY_DATE_RANGE_QUERY = gql `
  ${CORE_EVENT_FIELDS}

  query FetchEvents($start: DateTime, $end: DateTime) {
    events(
      where: {
        startTime_LTE: $end,
        endTime_GTE: $start,
      }
      options: {sort: {startTime: ASC}}
    ) {
      ...CoreEventFields
    }
  }
`

interface FetchEventByDateRangeQueryVars {
  start: Date,
  end: Date,
}

interface FetchEventsByDateRangeData {
  events: Event[],
}

interface UseFetchEventsByDateRangeResult {
  fetchEventsByDateRange: (startDate: Date, endDate?: Date) => void,
  events?: Event[],
  result: QueryResult<FetchEventsByDateRangeData, FetchEventByDateRangeQueryVars>,
}

type FetchEventsByDateRangeOptions = QueryHookOptions<FetchEventsByDateRangeData, FetchEventByDateRangeQueryVars>

export const useFetchEventsByDateRange = (options?: FetchEventsByDateRangeOptions): UseFetchEventsByDateRangeResult => {
  const [query, result] = useLazyQuery<FetchEventsByDateRangeData, FetchEventByDateRangeQueryVars>(FETCH_EVENTS_BY_DATE_RANGE_QUERY, options)

  const fetchEventsByDateRange = useCallback((startDate: Date, endDate?: Date) => {
    const start = DateTime.fromJSDate(startDate).startOf('day').toJSDate()
    const end = DateTime.fromJSDate(endDate ?? startDate).endOf('day').toJSDate()

    query({
      variables: {
        start,
        end,
      },
    })
  }, [query])

  const events = result.data?.events

  return {
    fetchEventsByDateRange,
    events,
    result,
  }
}
