import {useCallback} from 'react'
import {DateTime} from 'luxon'
import {gql, QueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_EVENT_FIELDS} from 'data/fragments/event'
import {Place} from 'data/types'

const FETCH_PAST_PLACE_EVENTS_QUERY = gql`
  ${CORE_EVENT_FIELDS}

  query FetchPastPlaceEvents($placeId: ID!, $start: DateTime, $limit: Int) {
    places(
      where: {id: $placeId}
    ) {
      id
      events(
        where: {
          startTime_LT: $start,
        }
        options: {
          sort: {startTime: DESC},
          limit: $limit,
        }
      ) {
        ...CoreEventFields
      }
    }
  }
`

interface FetchPastPlaceEventsVars {
  placeId: string,
  start: Date,
  limit: number,
}

interface FetchPastPlaceEventsData {
  places: Place[],
}

interface UseFetchPastPlaceEventsResult {
  fetchPastPlaceEvents: () => void,
  result: QueryResult<FetchPastPlaceEventsData, FetchPastPlaceEventsVars>,
}

type FetchPastPlaceEventsOptions = QueryHookOptions<FetchPastPlaceEventsData, FetchPastPlaceEventsVars>

export const useFetchPastPlaceEvents = (placeId: string, options?: FetchPastPlaceEventsOptions): UseFetchPastPlaceEventsResult => {
  const [query, result] = useLazyQuery<FetchPastPlaceEventsData, FetchPastPlaceEventsVars>(FETCH_PAST_PLACE_EVENTS_QUERY, options)

  const fetchPastPlaceEvents = useCallback(() => {
    const start = DateTime.now().startOf('day').toJSDate()
    const limit = 250

    if (!result.called)
      query({
        variables: {
          placeId,
          start,
          limit,
        },
      })
  }, [result.called, query, placeId])

  return {
    fetchPastPlaceEvents,
    result,
  }
}
