import React, {useEffect, useMemo, useState} from 'react'
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import {useFetchPlaces} from 'places/hooks'
import {sortByName} from 'utils'
import PlaceRow from './Row'

const headerSx: SxProps<Theme> = {
  backgroundColor: theme => theme.palette.info.main,
  mb: 1,
  px: 2,
  py: 1,
  borderRadius: 1,
  display: 'grid',
  gridTemplateColumns: `auto 1fr auto`,
  columnGap: 2,
  alignItems: 'center',
}

const PlacesAdminPanel = (): JSX.Element => {
  const {fetchPlaces, places=[], result} = useFetchPlaces({
    fetchPolicy: 'cache-and-network',
  })
  const [sortBy, setSortBy] = useState('events')

  useEffect(() => {
    result.startPolling(60000)

    return () => result.stopPolling()
  })

  if (!result.called) fetchPlaces({includeEventsCount: true})

  const sortedByName = useMemo(() => [...places].sort((a, b) => sortByName(a.name, b.name)), [places])
  const sortedByEvents = useMemo(() => {
    return [...sortedByName].sort((a, b) => {
      const aTotal = a.eventsTotal?.count ?? 0
      const aUpcoming = a.eventsUpcoming?.count ?? 0
      // const aRatio = aTotal > 0 ? aUpcoming / aTotal : 1

      const bTotal = b.eventsTotal?.count ?? 0
      const bUpcoming = b.eventsUpcoming?.count ?? 0
      // const bRatio = bTotal > 0 ? bUpcoming / bTotal : 1

      const totalDiff = bTotal - aTotal
      const upcomingDiff = aUpcoming - bUpcoming
      // const ratioDiff = bRatio - aRatio

      return totalDiff !== 0 ? totalDiff : upcomingDiff
    })
  }, [sortedByName])

  let sortedPlaces
  switch (sortBy) {
    case 'events':
      sortedPlaces = sortedByEvents
      break
    case 'name':
    default:
      sortedPlaces = sortedByName
      break
  }

  return (
    <Container maxWidth="xl">
      <Box my={2}>
        <Box sx={headerSx}>
          <Typography variant="h4">Places Admin Panel</Typography>

          <Typography fontSize={18} align="right">
            Count: {places.length}
          </Typography>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    color="info.main"
                    fontSize={18}
                    onClick={() => setSortBy('name')}
                    sx={{cursor: 'pointer', textDecoration: sortBy === 'name' ? 'underline' : 'none'}}
                  >
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color="info.main" fontSize={18}>Address</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    color="info.main"
                    fontSize={18}
                    onClick={() => setSortBy('events')}
                    sx={{cursor: 'pointer', textDecoration: sortBy === 'events' ? 'underline' : 'none'}}
                  >
                    Events (Upcoming/Total)
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>

              {result.loading &&
                <TableRow sx={{'&:last-child th, &:last-child td': {border: 0}}}>
                  <TableCell align="center" colSpan={3}>
                    <CircularProgress/>
                  </TableCell>
                </TableRow>
              }
            </TableHead>
            <TableBody>
              {sortedPlaces.map((place) => <PlaceRow key={place.id} place={place}/>)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  )
}

export default PlacesAdminPanel
