import {gql, QueryHookOptions, QueryResult, useQuery} from '@apollo/client'

const PLACES_COUNT_QUERY = gql `
  query PlacesCount {
    placesAggregate {
      count
    }
  }
`

interface PlacesCountData {
  placesAggregate: {
    count: number,
  },
}

interface PlacesCountQueryResult {
  count?: number,
  result: QueryResult<PlacesCountData>,
}

type PlacesCountOptions = QueryHookOptions<PlacesCountData>

export const usePlacesCount = (options?: PlacesCountOptions): PlacesCountQueryResult => {
  const result = useQuery<PlacesCountData>(PLACES_COUNT_QUERY, {
    fetchPolicy: 'network-only',
    ...options,
  })

  const count = result.data?.placesAggregate.count

  return {
    count,
    result,
  }
}
