import React from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  Link as MuiLink,
} from '@mui/material'
import type {Place} from 'data/types'
import Icon from 'ui/LinkButtons/Icon'
import {useFetchPlaceById} from 'places/hooks'

interface PlaceLinksProps {
  place: Place,
}

const PlaceLinks = ({place}: PlaceLinksProps): JSX.Element | null=> {
  const {fetchPlaceById, result} = useFetchPlaceById()

  if (!result.called) fetchPlaceById(place.id)

  if (result.loading) {
    return (
      <Box p={1}>
        <CircularProgress size="1rem"/>
      </Box>
    )
  }

  const links = result.data?.places?.[0]?.links

  if (!links) return null

  return (
    <Box
      display="grid"
      gridAutoFlow="column"
      gap={1}
      justifyContent="start"
    >
      {links.map((link) => {
        return (
          <Box key={link.id}>
            <IconButton
              component={MuiLink}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon link={link}/>
            </IconButton>
          </Box>
        )
      })}
    </Box>
  )
}

export default PlaceLinks
