import React, {useRef, useState} from 'react'
import {DateTime, Interval} from 'luxon'
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  SxProps,
  Table,
  TableBody,
  TableContainer,
  Theme,
} from '@mui/material'
import ExpandIcon from 'ui/ExpandIcon'
import {Artist, Event, Place} from 'data/types'
import {useFetchEventsByDateRange} from 'events/hooks'
import ConflictingEvent from './ConflictingEvent'
import {getPrimaryUrl} from 'utils/urls'
import {filterStatusEvents} from 'events/utils'

const containerSx: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
})

type Conflict = {
  event: Event,
  entity: string,
  url: string,
}

interface ConflictCheckerProps {
  id?: string,
  startTime?: Date,
  endTime?: Date,
  location?: Place,
  artists?: Artist[],
}

const ConflictChecker = ({id, startTime, endTime, location, artists}: ConflictCheckerProps): JSX.Element | null => {
  const [expand, setExpand] = useState(false)
  const prevDateTime = useRef<DateTime | null>(null)

  const {fetchEventsByDateRange, events, result: {loading}} = useFetchEventsByDateRange({
    fetchPolicy: 'cache-and-network',
  })

  if (!startTime || !endTime || (!location && !artists)) return null

  const eventStartDateTime = DateTime.fromJSDate(startTime)

  if (!prevDateTime.current || !prevDateTime.current.hasSame(eventStartDateTime, 'day')) {
    fetchEventsByDateRange(startTime)
    prevDateTime.current = eventStartDateTime
  }

  if (loading) {
    return (
      <CircularProgress size={20}/>
    )
  }

  if (!events) return null

  const locationId = location?.id
  const artistIds = artists?.map(a => a.id) ?? []

  const otherEvents = id ? events.filter(e => e.id != id) : events

  const filteredEvents = filterStatusEvents(otherEvents)

  const conflicts: Conflict[] = []

  filteredEvents.forEach(e => {
    if (!e.endTime) return

    let earlierEventInterval: Interval
    let laterEventInterval: Interval

    if (e.startTime <= startTime) {
      earlierEventInterval = Interval.fromDateTimes(e.startTime, e.endTime)
      laterEventInterval = Interval.fromDateTimes(startTime, endTime)
    } else {
      laterEventInterval = Interval.fromDateTimes(e.startTime, e.endTime)
      earlierEventInterval = Interval.fromDateTimes(startTime, endTime)
    }

    if ( !earlierEventInterval.contains(laterEventInterval.start) ) return

    if (locationId &&  e.location.id === locationId) {
      conflicts.push({event: e, entity: e.location.name, url: getPrimaryUrl(e.location)})
    }
    artistIds && e.artists && e.artists.map(a => {
      if (artistIds.includes(a.id)) {
        conflicts.push({event: e, entity: a.name, url: getPrimaryUrl(a)})
      }
    })
  })

  if (conflicts.length == 0) return null

  const toggleExpand = () => {setExpand(!expand)}

  return (
    <>
      <Box sx={containerSx}>
        <Box display="flex">
          <Alert
            sx={{pt: "4px", pb: "2px"}}
            severity="warning"
            onClick={toggleExpand}
            variant="filled"
          >
            <Box display="flex">
              Potential Conflict
              <ExpandIcon expanded={expand}/>
            </Box>
          </Alert>
        </Box>
        <Collapse in={expand}>
          <TableContainer sx={{mt: 1}}>
            <Table size="small">
              <TableBody>
                {conflicts.map(c => {
                  return (
                    <ConflictingEvent key={c.event.id} event={c.event} entity={c.entity} url={c.url}/>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Box>
    </>
  )
}

export default ConflictChecker
