import {DateTime} from 'luxon'
import {useCallback, useMemo} from 'react'
import {useSearchParams} from 'react-router-dom'

const DATE_FORMAT = 'yyyy-MM-dd'

type UseDateArgs = [
  date: DateTime,
  setDate: (newDate: DateTime, replaceOnChange?: boolean) => void,
]

export const useDate = (): UseDateArgs => {
  const [searchParams, setSearchParams] = useSearchParams()

  const dateParam = searchParams.get('date')

  const date = useMemo(()=> {
    let parsedDate

    // try to parse date
    if (dateParam) parsedDate = DateTime.fromFormat(dateParam, DATE_FORMAT)

    return parsedDate?.isValid ? parsedDate : DateTime.now()
  }, [dateParam])

  const setDate = useCallback((newDate: DateTime, replaceOnChange = false) => {
    if (newDate.hasSame(DateTime.now(), 'day')) {
      // remove date param if today
      searchParams.delete('date')
    } else {
      searchParams.set('date', newDate.toFormat(DATE_FORMAT))
    }

    setSearchParams(searchParams, {replace: replaceOnChange})
  }, [searchParams, setSearchParams])

  return [date, setDate]
}
