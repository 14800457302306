import React from 'react'
import {
  SvgIconProps,
  SxProps,
  Theme,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const iconSx: SxProps<Theme> = {
  ml: 1,
  transition: '300ms',

  '&.expanded': {
    transform: 'rotate(180deg)',
  },
}

interface ExpandIconProps extends SvgIconProps {
  expanded: boolean,
}

const ExpandIcon = ({expanded, ...iconProps}: ExpandIconProps): JSX.Element => {
  let className
  if (expanded) className = 'expanded'

  return (
    <ExpandMoreIcon className={className} sx={iconSx} {...iconProps}/>
  )
}

export default ExpandIcon
