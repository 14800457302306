import {useCallback} from 'react'
import {gql, MutationHookOptions, MutationResult, useMutation} from '@apollo/client'
import {CORE_PLACE_FIELDS} from 'data/fragments/place'
import type {Input, Place} from 'data/types'

interface PlaceCreateVars {
  createPlaceInput: Input<Place>,
}

const CREATE_PLACE_MUTATION = gql `
  ${CORE_PLACE_FIELDS}

  mutation CreatePlace($createPlaceInput: [PlaceCreateInput!]!) {
    createPlaces(input: $createPlaceInput) {
      places {
        ...CorePlaceFields
      }
    }
  }
`

interface PlaceCreateData {
  createPlaces: {
    places: Place[],
  },
}

interface UseCreatePlaceResult {
  createPlace: (place: Place) => void,
  result: MutationResult<PlaceCreateData>
}

type CreatePlaceOptions = MutationHookOptions<PlaceCreateData, PlaceCreateVars>

export const useCreatePlace = (options?: CreatePlaceOptions): UseCreatePlaceResult => {
  const [mutate, result] = useMutation<PlaceCreateData, PlaceCreateVars>(CREATE_PLACE_MUTATION, {
    onError: () => {/* prevent exception from being thrown */},
    refetchQueries: ['FetchPlaces', 'PlacesCount'],
    ...options,
  })

  const createPlace = useCallback((place: Place) => {
    const {
      id,
      googlePlaceId,
      name,
      location,
      physicalAddress,
      primaryPhone,
      links,
    } = place

    const createPlaceInput: Input<Place> = {
      id,
      googlePlaceId,
      name,
      location,
    }

    if (physicalAddress) {
      createPlaceInput.physicalAddress = {
        create: {
          node: {
            id: physicalAddress.id,
            street: physicalAddress.street,
            neighborhood: physicalAddress.neighborhood,
            locality: physicalAddress.locality,
            region: physicalAddress.region,
            postalCode: physicalAddress.postalCode,
            country: physicalAddress.country,
            location: physicalAddress.location,
            place: {
              connect: {
                where: {
                  node: {
                    id: place.id,
                  },
                },
              },
            },
          },
        },
      }
    }

    if (primaryPhone) {
      createPlaceInput.contactMethods = {
        Phone: {
          connectOrCreate: {
            where: {
              node: {
                number: primaryPhone.number,
              },
            },
            onCreate: {
              node: {
                number: primaryPhone.number,
              },
            },
          },
        },
      }

      createPlaceInput.primaryPhone = {
        connectOrCreate: {
          where: {
            node: {
              number: primaryPhone.number,
            },
          },
          onCreate: {
            node: {
              number: primaryPhone.number,
            },
          },
        },
      }
    }

    if (links?.length && links.length > 0) {
      createPlaceInput.links = {
        create: links.map((link) => {
          return {
            node: {
              id: link.id,
              url: link.url,
            },
          }
        }),
      }
    }

    console.log({
      createPlaceInput,
    })

    mutate({variables: {createPlaceInput}})
  }, [mutate])

  return {
    createPlace,
    result,
  }
}
