import {useCallback} from 'react'
import {gql, MutationHookOptions, MutationResult, useMutation} from '@apollo/client'
import {COMPLETE_EVENT_FIELDS} from 'data/fragments/event'
import type {Event, Input, Link, Where} from 'data/types'

interface UpdateEventVars {
  where: Where<Event>,
  update: Input<Partial<Event>>,
}

const UPDATE_EVENT_MUTATION = gql `
  ${COMPLETE_EVENT_FIELDS}

  mutation UpdateEvent($where: EventWhere, $update: EventUpdateInput) {
    updateEvents(where: $where, update: $update) {
      events {
        ...CompleteEventFields
      }
    }
  }
`

interface UpdateEventData {
  updateEvents: {
    events: Event[],
  },
}

interface UseUpdateEventResult {
  updateEvent: (data: Partial<Event>) => void,
  result: MutationResult<UpdateEventData>,
}

type UpdateEventOptions = MutationHookOptions<UpdateEventData, UpdateEventVars>

export const useUpdateEvent = (event: Event, options?: UpdateEventOptions): UseUpdateEventResult => {
  const [mutate, result] = useMutation<UpdateEventData, UpdateEventVars>(UPDATE_EVENT_MUTATION, {
    onError: () => {/* prevent exception from being thrown */},
    ...options,
  })

  const updateEvent = useCallback((data: Partial<Event>) => {
    const whereInput: Where<Event> = {
      id: event.id,
    }

    const updateInput: Input<Partial<Event>> = {
    }

    // location
    if (data.location && data.location.id !== event.location.id) {
      updateInput.location = {
        disconnect: {
          where: {
            node: {
              id_NOT: data.location.id,
            },
          },
        },
        connect: {
          where: {
            node: {
              id: data.location.id,
            },
          },
        },
      }
    }

    // startTime
    if (data.startTime && data.startTime.getTime() !== event.startTime.getTime()) {
      updateInput.startTime = data.startTime
    }

    // endTime
    if (data.endTime && data.endTime.getTime() !== event.endTime?.getTime()) {
      updateInput.endTime = data.endTime
    }

    // artists
    const originalArtistIds = event.artists?.map(a => a.id) ?? []
    const updatedArtistIds = data.artists?.map(a => a.id) ?? []
    const addedArtistsIds = updatedArtistIds.filter(id => !originalArtistIds.includes(id))
    const removedArtistIds = originalArtistIds.filter(id => !updatedArtistIds.includes(id))

    if (addedArtistsIds.length > 0) {
      updateInput.artists = {
        ...updateInput.artists,
        connect: {
          where: {
            node: {
              id_IN: addedArtistsIds,
            },
          },
        },
      }
    }

    if (removedArtistIds.length > 0) {
      updateInput.artists = {
        ...updateInput.artists,
        disconnect: {
          where: {
            node: {
              id_IN: removedArtistIds,
            },
          },
        },
      }
    }

    // sponsor artists
    const originalSponsorArtistIds = event.sponsorArtists?.map(a => a.id) ?? []
    const updatedSponsorArtistIds = data.sponsorArtists?.map(a => a.id) ?? []
    const addedSponsorArtistsIds = updatedSponsorArtistIds.filter(id => !originalSponsorArtistIds.includes(id))
    const removedSponsorArtistIds = originalSponsorArtistIds.filter(id => !updatedSponsorArtistIds.includes(id))

    if (addedSponsorArtistsIds.length > 0) {
      updateInput.sponsorArtists = {
        ...updateInput.sponsorArtists,
        connect: {
          where: {
            node: {
              id_IN: addedSponsorArtistsIds,
            },
          },
        },
      }
    }

    if (removedSponsorArtistIds.length > 0) {
      updateInput.sponsorArtists = {
        ...updateInput.sponsorArtists,
        disconnect: {
          where: {
            node: {
              id_IN: removedSponsorArtistIds,
            },
          },
        },
      }
    }

    // promoted artists
    const originalPromotedArtistIds = event.promotedArtists?.map(a => a.id) ?? []
    const updatedPromotedArtistIds = data.promotedArtists?.map(a => a.id) ?? []
    const addedPromotedArtistsIds = updatedPromotedArtistIds.filter(id => !originalPromotedArtistIds.includes(id))
    const removedPromotedArtistIds = originalPromotedArtistIds.filter(id => !updatedPromotedArtistIds.includes(id))

    if (addedPromotedArtistsIds.length > 0) {
      updateInput.promotedArtists = {
        ...updateInput.promotedArtists,
        connect: {
          where: {
            node: {
              id_IN: addedPromotedArtistsIds,
            },
          },
        },
      }
    }

    if (removedPromotedArtistIds.length > 0) {
      updateInput.promotedArtists = {
        ...updateInput.promotedArtists,
        disconnect: {
          where: {
            node: {
              id_IN: removedPromotedArtistIds,
            },
          },
        },
      }
    }

    console.log({dataTitle: data.title, eventTitle: event.title})

    // title
    if (data.title !== event.title) {
      updateInput.title = data.title ?? null
    }

    // description
    if (data.description) {
      const node = {
        id: data.description.id,
        format: data.description.format,
        content: data.description.content,
      }

      if (event.description?.createdAt) {
        if (node.content !== event.description.content) {
          updateInput.description = {
            update: {
              node,
            },
          }
        }
      } else {
        updateInput.description = {
          create: {
            node,
          },
        }
      }
    } else {
      if (event.description) {
        updateInput.description = {
          delete: {
            where: {
              node: {
                id: event.description.id,
              },
            },
          },
        }
      }
    }

    // links
    const addedLinks: Link[] = []
    for (const link of data.links ?? []) {
      if (event.links?.filter(l => l.url === link.url).length === 0) {
        addedLinks.push(link)
      }
    }

    const removedLinks: Link[] = []
    for (const link of event.links ?? []) {
      if (data.links?.filter(l => l.url === link.url).length === 0) {
        removedLinks.push(link)
      }
    }

    if (addedLinks.length > 0) {
      if (!updateInput.links) updateInput.links = {}

      updateInput.links.create = addedLinks.map((link) => {
        const node = {
          id: link.id,
          url: link.url,
        }

        return {node}
      })
    }

    if (removedLinks.length > 0) {
      if (!updateInput.links) updateInput.links = {}

      updateInput.links.delete = removedLinks.map((link) => {
        return {
          where: {
            node: {
              id: link.id,
            },
          },
        }
      })
    }

    console.log({
      data,
      whereInput,
      updateInput,
    })

    if (Object.keys(updateInput).length > 0) {
      mutate({
        variables: {
          where: whereInput,
          update: updateInput,
        },
      })
    }
  }, [event, mutate])

  return {
    updateEvent,
    result,
  }
}
