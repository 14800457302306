import {gql} from '@apollo/client'

export const CORE_ADDRESS_FIELDS = gql `
  fragment CoreAddressFields on Address {
    id
    createdAt
    updatedAt

    street
    locality
    region
    postalCode
    country
    neighborhood

    location {
      latitude
      longitude
    }
  }
`
