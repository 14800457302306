import {ApolloClient} from '@apollo/client'
import {
  authLink,
  errorLink,
  httpLink,
} from './links'
import cache from './cache'

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache,
})

export default client
