import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SxProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import EventsDateSelector from 'events/components/DateSelector'

const closeButtonSx: SxProps = {
  position: 'absolute',
  top: 8,
  right: 8,
}

const DateSelectorDialog = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const state = location.state as {showDateSelector?: boolean}

  const open = !!state?.showDateSelector

  const handleClose = () => {
    const newState = {...state}
    delete newState.showDateSelector

    navigate(location, {replace: true, state: newState})
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        Select Date

        <IconButton sx={closeButtonSx} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <EventsDateSelector replaceUrlOnChange={true}/>
      </DialogContent>
    </Dialog>
  )
}

export default DateSelectorDialog
