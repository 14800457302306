import React, {useState} from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {xor} from 'lodash'
import {v4 as uuid} from 'uuid'
import {useImmer} from 'use-immer'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material'
import type {Artist, Handle} from 'data/types'
import HandlesEditor from 'ui/HandlesEditor'
import LinksEditor from 'ui/LinksEditor'
import {useDeleteArtist, useUpdateArtist} from 'artists/hooks'
import {useReturnLocation} from 'router/hooks'
import {getPrimaryUrl} from 'utils/urls'
import {useHotkeys} from 'react-hotkeys-hook'
import {ctrlOs} from 'utils'
import ToolTipLabel from 'ui/TooltipLabel'

type ArtistEditorProps = {
  artist: Artist,
}

const ArtistEditor = ({artist}: ArtistEditorProps): JSX.Element => {
  const [artistState, setArtistState] = useImmer(artist)
  const {updateArtist, result: updateResult} = useUpdateArtist(artist)
  const {deleteArtist, result: deleteResult} = useDeleteArtist(artist)
  const navigate = useNavigate()
  const returnLocation = useReturnLocation()
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false)

  useHotkeys('ctrl+s, meta+s', () => saveArtist(),
    {enableOnFormTags: true, preventDefault: true})
  useHotkeys('ctrl+., meta+.', () => handleCancel(),
    {enableOnFormTags: true, preventDefault: true})

  const artistUrl = getPrimaryUrl(artist)

  if (updateResult.data) return <Navigate to={returnLocation ?? artistUrl} replace/>
  if (deleteResult.data) return <Navigate to="/artists" replace/>

  const setName = (name: string) => {
    setArtistState(draft => {draft.name = name})
  }

  const setDescription = (content: string) => {
    setArtistState((draft) => {
      if (content.length > 0) {
        draft.description = {
          id: uuid(),
          format: 'PLAIN',
          content,
        }
      } else {
        draft.description = undefined
      }
    })
  }

  const addLink = (url: string) => {
    setArtistState((draft) => {
      if (!draft.links) draft.links = []

      draft.links.push({id: uuid(), url})
    })
  }

  const removeLink = (linkId: string) => {
    setArtistState(draft => {draft.links = draft.links?.filter(l => l.id !== linkId)})
  }

  const handleCancel = () => navigate(returnLocation ?? artistUrl, {replace: true})

  const handleUpdateArtist = () => {
    updateArtist(artistState)
  }

  const handleDeleteArtist = () => {
    deleteArtist()
  }

  const setPrimaryHandle = (primaryHandle: Handle) => {
    setArtistState(draft => {draft.primaryHandle = primaryHandle})
  }

  const addHandle = (handle: Handle) => {
    setArtistState((draft) => {
      if (!draft.handles) draft.handles = []

      const handleNames = draft.handles.map(h => h.name)

      if (!handleNames.includes(handle.name)) draft.handles.push(handle)

      draft.primaryHandle = handle
    })
  }

  const hasChanges = (): boolean => {
    if (artist.name !== artistState.name) return true
    if (artist.description?.content !== artistState.description?.content) return true

    const originalLinks = artist.links?.map(l => l.url) ?? []
    const updatedLinks = artistState.links?.map(l => l.url) ?? []
    if (xor(originalLinks, updatedLinks).length > 0) return true

    const originalHandles = artist.handles?.map(h => h.name) ?? []
    const updatedHandles = artistState.handles?.map(h => h.name) ?? []
    if (xor(originalHandles, updatedHandles).length > 0) return true

    if (artist.primaryHandle?.name !== artistState.primaryHandle?.name) return true

    return false
  }

  const valid = hasChanges() && !updateResult.loading

  const saveArtist = () => {
    if (valid) handleUpdateArtist()
  }

  return (
    <Box display="grid" gap={2}>
      <TextField
        label="Artist Name"
        value={artistState.name}
        onChange={e => setName(e.target.value)}
        fullWidth
      />
      <HandlesEditor
        handles={artistState.handles}
        primaryHandle={artistState.primaryHandle}
        setPrimaryHandle={setPrimaryHandle}
        addHandle={addHandle}
      />
      <TextField
        label="Description"
        value={artistState.description?.content ?? ""}
        onChange={e => setDescription(e.target.value)}
        fullWidth
        multiline
      />
      <LinksEditor links={artistState.links} addLink={addLink} removeLink={removeLink}/>
      <Box display="flex" justifyContent="space-between">
        <Button
          color="error"
          onClick={() => setShowDeleteConfirmationDialog(true)}
          disabled={artist.eventsTotal && artist.eventsTotal.count > 0}
        >
          Delete
        </Button>
        <Box display="grid" gap={2} gridAutoFlow="column" justifyContent="end">
          <Tooltip title={<ToolTipLabel shortcut={`${ctrlOs()} + Period`}/>}>
            <Button onClick={handleCancel}>
              Cancel
            </Button>
          </Tooltip>
          <Tooltip title={<ToolTipLabel shortcut={`${ctrlOs()} + S`}/>}>
            <Box>
              <Button
                onClick={handleUpdateArtist}
                variant="contained"
                disableElevation
                disabled={!valid}
              >
                Update Artist
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <Dialog
        open={showDeleteConfirmationDialog}
        onClose={() => setShowDeleteConfirmationDialog(false)}
      >
        <DialogTitle>Are you sure you want to delete this artist? This cannot be undone.</DialogTitle>
        <DialogActions>
          <Button color="inherit" onClick={() => setShowDeleteConfirmationDialog(false)}>No</Button>
          <Button color="error" onClick={handleDeleteArtist} disabled={deleteResult.called}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ArtistEditor
