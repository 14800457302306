import React from 'react'
import {
  Box,
  Container,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import PodcastIcon from '@mui/icons-material/Podcasts'
import EventsList from 'events/components/List'
import EventsDateSelector from 'events/components/DateSelector'
import PodcastPlayer from 'podcast/components/Player'

const dashboardSx: SxProps<Theme> = {
  my: 2,
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: 2,
  alignItems: 'start',
}

const sidebarSx: SxProps<Theme> = {
  display: 'grid',
  gap: 2,
}

const podcastTitleSx: SxProps<Theme> = {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'auto 1fr',
  gap: 1,
  backgroundColor: theme => theme.palette.primary.main,
  px: 2,
  py: 1,
  mb: 1,
  borderRadius: 1,
  fontSize: 18,
  fontWeight: 300,
}

const podcastTitle = "What's Hapnyn San Diego Podcast"

const DesktopDashboard = (): JSX.Element => {
  return (
    <Container maxWidth="lg">
      <Box sx={dashboardSx}>
        <EventsList/>
        <Box sx={sidebarSx}>
          <EventsDateSelector/>
          <Box>
            <Typography sx={podcastTitleSx}>
              <PodcastIcon/>
              {podcastTitle}
            </Typography>
            <PodcastPlayer/>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default DesktopDashboard
