import React from 'react'
import type {Link} from 'data/types'
import LinkButtons, {LinkButtonsProps} from 'ui/LinkButtons'

interface LinksProps extends LinkButtonsProps {
  links?: Link[],
}

const Links = ({links, ...props}: LinksProps): JSX.Element | null => {
  if (!links) return null

  return (
    <LinkButtons links={links} {...props}/>
  )
}

export default Links
