import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  Box,
  Container,
  SxProps,
  Typography,
} from '@mui/material'
import type {Artist} from 'data/types'
import ActionsDial from 'artists/components/ActionsDial'
import ArtistEventsList from 'artists/components/EventsList'
import Description from 'artists/components/Description'
import Links from 'artists/components/Links'
import SpotifyPlayer from 'artists/components/SpotifyPlayer'
import {useRoles} from 'auth/hooks'
import {useHotkeys} from 'react-hotkeys-hook'

const gridSx: SxProps = {
  py: 4,
  display: 'grid',
  gridTemplateColumns: '1fr minmax(300px, auto)',
  gridTemplateAreas: `
    "main sidebar"
  `,
  columnGap: 6,
  rowGap: 2,
}

const mainSx: SxProps = {
  gridArea: 'main',
  display: 'grid',
  alignContent: 'start',
  gap: 2,
}

const sidebarSx: SxProps = {
  gridArea: 'sidebar',
  display: 'grid',
  alignContent: 'start',
  gap: 2,
}

interface DesktopArtistDetailsProps {
  artist: Artist,
}

const DesktopArtistDetails = ({artist}: DesktopArtistDetailsProps): JSX.Element | null => {
  const navigate = useNavigate()
  const {isContentManager} = useRoles()
  const location = useLocation()

  useHotkeys('ctrl+Enter, meta+Enter', () => newEvent(),
    {enableOnFormTags: true, preventDefault: true})

  const {name, description, links} = artist

  const newEvent = () => {
    if (isContentManager)
      navigate(`/events/new?artistId=${artist.id}`, {state: {from: location}})
  }

  return (
    <Container maxWidth="lg">
      <ActionsDial artist={artist}/>

      <Box sx={gridSx}>
        <Box sx={mainSx}>
          <Typography variant="h2" color="warning.main">{name}</Typography>
          <Description description={description}/>
          <ArtistEventsList artist={artist} my={2}/>
        </Box>

        <Box sx={sidebarSx}>
          <Links links={links} justify="end"/>
          <SpotifyPlayer links={links}/>
        </Box>
      </Box>
    </Container>
  )
}

export default DesktopArtistDetails
