import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {
  Box,
  Button,
  Paper,
  SxProps,
  TextField,
  Typography,
} from '@mui/material'
import HapnynLogo from 'ui/hapnyn/Logo'
import {useSignIn} from 'auth/hooks'

const gridSx: SxProps = {
  display: 'grid',
  rowGap: 2,
  minWidth: 360,
}

type TextFieldChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

const SignInForm = (): JSX.Element => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const {signIn, loading, error} = useSignIn()

  const handleEmailChange = (e: TextFieldChangeEvent) => setEmail(e.target.value)
  const handlePasswordChange = (e: TextFieldChangeEvent) => setPassword(e.target.value)
  const handleSignIn = () => signIn({email, password})

  const disableFields = loading
  const disableButton = !email || !password || loading

  let errorMessage
  if (error) errorMessage = <Typography color="error">Invalid email or password</Typography>

  return (
    <Paper sx={{p: 2}}>
      <Box component="form" noValidate>
        <Box sx={gridSx}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <HapnynLogo fontSize={36} color="primary.main" shadow={false}/>
            <Typography fontSize={18}>sign in</Typography>
          </Box>

          {errorMessage}

          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            disabled={disableFields}
            autoFocus
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            disabled={disableFields}
          />

          <Box display="flex" justifyContent="flex-end">
            {/* <Button
              component={Link}
              to="/signup"
              color="secondary"
              sx={{textTransform: 'none'}}
            >
              Sign Up
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              disableElevation
              sx={{textTransform: 'none'}}
              onClick={handleSignIn}
              disabled={disableButton}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default SignInForm
