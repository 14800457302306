import {createTheme} from '@mui/material'
import '@fontsource/oswald/200.css'
import '@fontsource/oswald/300.css'
import '@fontsource/oswald/400.css'
import '@fontsource/oswald/500.css'
import '@fontsource/oswald/600.css'
import '@fontsource/oswald/700.css'
// import '@fontsource/oswald/variable.css'

// Colors based on Bootswatch Superhero theme
//
// https://bootswatch.com/superhero/

const COLORS = {
  grayLighter: '#EBEBEB',
  grayLight: '#4E5D6C',
  gray: '#555555',
  grayDark: '#333333',
  grayDarker: '#222222',

  primary: '#DF691A',   // orange
  success: '#5cb85c',   // green
  info: '#5bc0de',      // light blue
  warning: '#f0ad4e',   // yellow
  danger: '#d9534f',    // red

  bodyBackground: '#2B3E50',
  bodyBackgroundDark: '#1A2530',

  textColor: '#EBEBEB',
  textMuted: '#4e5d6c',

  googleRed: '#dd4b39',
  facebookBlue: '#3b5998',
}

const FONTS = {
  family: {
    sans: '"Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif',
    serif: 'Georgia, "Times New Roman", Times, serif',
    monospace: 'Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  size: 15,
  lineHeight: 1.428571429,
}

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Alba";
          font-style: normal;
          font-weight: normal;
          src: url("https://storage.hapnyn.com/assets/fonts/alba/ALBA____.TTF") format("truetype");
        }
      `,
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: COLORS.primary,
      contrastText: COLORS.grayLighter,
    },
    secondary: {
      main: COLORS.info,
    },
    error: {
      main: COLORS.danger,
    },
    success: {
      main: COLORS.success,
    },
    warning: {
      main: COLORS.warning,
    },
    background: {
      default: COLORS.bodyBackground,
      paper: COLORS.bodyBackgroundDark,

      // light mode:
      // paper: COLORS.grayLight,
    },
  },
  typography: {
    fontFamily: FONTS.family.sans,
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
})

export default theme
