import React from 'react'
import {DateTime} from 'luxon'
import {StaticDatePicker} from '@mui/lab'
import {
  TextField,
} from '@mui/material'
import {useDate} from 'events/hooks'

interface EventDatesSelector {
  replaceUrlOnChange?: boolean,
}

const EventsDateSelector = ({replaceUrlOnChange}: EventDatesSelector): JSX.Element => {
  const [date, setDate] = useDate()

  const handleChange = (newDate: DateTime | null) => {
    if (newDate) setDate(newDate, replaceUrlOnChange)
  }

  return (
    <StaticDatePicker
      value={date}
      onChange={handleChange}
      renderInput={p => <TextField {...p}/>}
      displayStaticWrapperAs="desktop"
      minDate={DateTime.fromObject({year: 2014})}
    />
  )
}

export default EventsDateSelector
