import React from 'react'
import CounterCard from './CounterCard'
import {useArtistsCount} from 'artists/hooks'

const ArtistsCount = (): JSX.Element => {
  const {count, result: {loading, error}} = useArtistsCount()

  return (
    <CounterCard
      label="Artists Count"
      count={count}
      loading={loading}
      error={error?.message}
      path="/artists"
    />
  )
}

export default ArtistsCount
