import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  Box,
  Container,
  SxProps,
  Typography,
} from '@mui/material'
import type {Place} from 'data/types'
import ActionsDial from 'places/components/ActionsDial'
import Description from 'places/components/Description'
import PlaceLinks from 'places/components/Links'
import PlaceEventsList from 'places/components/EventsList'
import PlaceLocation from 'places/components/Location'
import {useRoles} from 'auth/hooks'
import {useHotkeys} from 'react-hotkeys-hook'

const gridSx: SxProps = {
  py: 4,
  display: 'grid',
  gridTemplateColumns: '1fr minmax(300px, auto)',
  gridTemplateAreas: `
    "main sidebar"
  `,
  columnGap: 6,
  rowGap: 2,
}

const mainSx: SxProps = {
  gridArea: 'main',
  display: 'grid',
  alignContent: 'start',
  gap: 2,
}

const sidebarSx: SxProps = {
  gridArea: 'sidebar',
  display: 'grid',
  alignContent: 'start',
  gap: 2,
}

interface DesktopDetailsProps {
  place: Place,
}

const DesktopDetails = ({place}: DesktopDetailsProps): JSX.Element => {
  const {name, description, links} = place
  const location = useLocation()
  const navigate = useNavigate()
  const {isContentManager} = useRoles()

  useHotkeys('ctrl+Enter, meta+Enter', () => newEvent(),
    {enableOnFormTags: true, preventDefault: true})

  const newEvent = () => {
    if (isContentManager)
      navigate(`/events/new?placeId=${place.id}`, {state: {from: location}})
  }

  return (
    <Container maxWidth="lg">
      <ActionsDial place={place}/>

      <Box sx={gridSx}>
        <Box sx={mainSx}>
          <Typography variant="h2" color="primary.main">{name}</Typography>
          <Description description={description}/>
          <PlaceEventsList place={place} my={2}/>
        </Box>

        <Box sx={sidebarSx}>
          <PlaceLocation place={place}/>
          <PlaceLinks links={links} justify="end"/>
        </Box>
      </Box>
    </Container>
  )
}

export default DesktopDetails
