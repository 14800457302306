import React from 'react'
import {DateTime, Interval} from 'luxon'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {
  Box,
  Card,
  CardActionArea,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import type {Artist, Event} from 'data/types'
import {formatInterval} from 'utils'
import {getEventName} from 'events/utils'
import {getPrimaryUrl} from 'utils/urls'

const eventNameSx: SxProps<Theme> = {
  color: theme => theme.palette.warning.main,
  fontSize: {
    xs: 16,
    md: 18,
  },
  fontWeight: 300,
  mt: 2,
}

const placeNameSx: SxProps = {
  fontSize: {
    xs: 18,
    md: 24,
  },
}

const placeRegionSx: SxProps = {
  fontSize: {
    xs: 14,
    md: 16,
  },
  fontWeight: 300,
}

type ArtistEventCardProps = {
  event: Event,
  artist: Artist,
}

const ArtistEventCard = ({event, artist}: ArtistEventCardProps): JSX.Element => {
  const location = useLocation()

  // isPromoted if artist.id in event.promotedArtists.artist.id

  const promotedArtistIds = event.promotedArtists?.map(a => a.id) ?? []

  const isPromoted = promotedArtistIds.includes(artist.id)

  const eventName = isPromoted ? getEventName(event) : getEventName(event, {artist})

  let eventTime
  if (event.endTime) {
    eventTime = formatInterval(Interval.fromDateTimes(event.startTime, event.endTime))
  }

  const to = getPrimaryUrl(event)
  const state = {
    backgroundLocation: location,
  }

  const placeName = event.location.name
  let placeRegion
  if (event.location.physicalAddress) {
    const {locality, region} = event.location.physicalAddress

    placeRegion = `${locality}, ${region}`
  }

  const ended = event.endTime && DateTime.now() > DateTime.fromJSDate(event.endTime)

  return (
    <Card elevation={0} sx={{mb: 1}}>
      <CardActionArea component={RouterLink} to={to} state={state}>
        <Box p={2}>
          <Box display="grid" gridTemplateColumns="1fr max(45%)" columnGap={2} alignItems="start">
            <Box>
              <Typography color="primary.main" sx={placeNameSx}>{placeName}</Typography>
              {placeRegion && <Typography sx={placeRegionSx}>{placeRegion}</Typography>}
            </Box>

            <Typography
              sx={{
                textAlign: 'right',
                fontStyle: ended ? 'italic' : 'normal',
              }}
              color={ended ? 'text.disabled' : 'inherit'}
            >
              {eventTime}
            </Typography>
          </Box>

          {eventName && <Typography sx={eventNameSx}>{eventName}</Typography>}
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default ArtistEventCard
