import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {useRoles} from 'auth/hooks'

const AdminWrapper = (): JSX.Element => {
  const {isAdmin, isContentManager} = useRoles()

  if (isAdmin || isContentManager) return <Outlet/>

  return <Navigate to="/"/>
}

export default AdminWrapper
