import {useCallback} from 'react'
import {DateTime} from 'luxon'
import {gql, LazyQueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_EVENT_FIELDS} from 'data/fragments/event'
import {Artist, Event} from 'data/types'
import {sortEvents} from 'events/utils'

const FETCH_PAST_ARTIST_EVENTS_QUERY = gql `
  ${CORE_EVENT_FIELDS}

  query FetchPastArtistEvents($artistId: ID!, $start: DateTime, $limit: Int) {
    artists(
      where: {id: $artistId}
    ) {
      id

      events(
        where: {
          startTime_LT: $start,
        }
        options: {
          sort: {startTime: DESC},
          limit: $limit,
        }
      ) {
        ...CoreEventFields
      }

      sponsorEvents(
        where: {
          startTime_LT: $start,
        }
        options: {
          sort: {startTime: DESC},
          limit: $limit,
        }
      ) {
        ...CoreEventFields
      }

      promotedEvents(
        where: {
          startTime_LT: $start,
        }
        options: {
          sort: {startTime: DESC},
          limit: $limit,
        }
      ) {
        ...CoreEventFields
      }

    }
  }
`

interface FetchPastArtistEventsVars {
  artistId: string,
  start: Date,
  limit: number,
}

interface FetchPastArtistEventsData {
  artists: Artist[],
}

interface UseFetchPastArtistEventsResult {
  fetchPastArtistEvents: () => void,
  events: Event[],
  result: QueryResult<FetchPastArtistEventsData, FetchPastArtistEventsVars>,
}

type FetchPastArtistEventsOptions = LazyQueryHookOptions<FetchPastArtistEventsData, FetchPastArtistEventsVars>

export const useFetchPastArtistEvents = (artistId: string, options?: FetchPastArtistEventsOptions): UseFetchPastArtistEventsResult => {
  const [query, result] = useLazyQuery<FetchPastArtistEventsData, FetchPastArtistEventsVars>(FETCH_PAST_ARTIST_EVENTS_QUERY, options)

  const fetchPastArtistEvents = useCallback(() => {
    const start = DateTime.now().startOf('day').toJSDate()
    const limit = 250

    if (!result.called)
      query({
        variables: {
          artistId,
          start,
          limit,
        },
      })
  }, [result.called, query, artistId])

  const regularEvents = result.data?.artists[0].events ?? []
  const sponsorEvents = result.data?.artists[0].sponsorEvents ?? []
  const promotedEvents = result.data?.artists[0].promotedEvents ?? []

  const unsortedEvents: Event[] = []

  const ids: string[] = []

  regularEvents.forEach(e => {
    if (!ids.includes(e.id)) {
      ids.push(e.id)

      unsortedEvents.push(e)
    }
  })

  sponsorEvents.forEach(e => {
    if (!ids.includes(e.id)) {
      ids.push(e.id)

      unsortedEvents.push(e)
    }
  })

  promotedEvents.forEach(e => {
    if (!ids.includes(e.id)) {
      ids.push(e.id)

      unsortedEvents.push(e)
    }
  })

  const events = [...unsortedEvents].sort((a, b) => sortEvents(b, a))

  return {
    fetchPastArtistEvents,
    events,
    result,
  }
}
