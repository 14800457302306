import React, {useState} from 'react'
import {
  Box,
  Collapse,
  Paper,
  Typography,
} from '@mui/material'
import ExpandIcon from 'ui/ExpandIcon'
import {Place} from 'data/types'
import {addressToString} from 'places/utils'
import GoogleMap from 'google/components/Map'

interface MobileLocationProps {
  place: Place,
}

const MobileLocation = ({place}: MobileLocationProps): JSX.Element => {
  const [expand, setExpand] = useState(false)

  const toggleExpand = () => setExpand(!expand)

  const {physicalAddress, primaryPhone} = place

  return (
    <Paper elevation={0} sx={{p: 2}}>
      <Box display="flex" justifyContent={{xs: 'space-between', md: 'flex-start'}} onClick={toggleExpand}>
        <Typography
          fontSize={24}
          lineHeight={1}
          color="info.main"
        >
          Location
        </Typography>
        <ExpandIcon expanded={expand} color="info"/>
      </Box>
      <Collapse in={expand}>
        <Box my={2}>
          {physicalAddress && <Typography fontWeight={300}>{addressToString(physicalAddress)}</Typography>}
          {primaryPhone && <Typography fontWeight={300}>{primaryPhone.number}</Typography>}
        </Box>
        <GoogleMap placeId={place.googlePlaceId} height={250} width="100%"/>
      </Collapse>
    </Paper>
  )
}

export default MobileLocation
