import React, {useState} from 'react'
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  SxProps,
  Typography,
} from '@mui/material'
import {Artist} from 'data/types'
import UpcomingEventsList from './UpcomingList'
import PastEventsList from './PastList'
import EventDetailsDialog from 'events/components/DetailsDialog'

const titleSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: "info.main",
  px: 2,
  py: 1,
  borderRadius: 1,
  mb: 1,
}

interface ArtistEventsListProps extends BoxProps {
  artist: Artist,
}

const ArtistEventsList = ({artist, ...boxProps}: ArtistEventsListProps): JSX.Element | null => {
  const [upcoming, setUpcoming] = useState(true)
  const [loading, setLoading] = useState(false)

  const listTitle = upcoming ? "Upcoming Events" : "Past Events"
  const buttonTitle = upcoming ? "Past" : "Upcoming"

  const showLoading = (loading: boolean) => {
    setLoading(loading)
  }

  return (
    <Box {...boxProps}>
      <EventDetailsDialog/>

      <Box sx={titleSx}>
        <Typography variant="h4" sx={{display: 'flex', alignItems: 'center'}}>
          {listTitle}
          {loading && <CircularProgress size="1em" color="inherit" sx={{ml: 1}}/>}
        </Typography>
        <Button
          color="inherit"
          size="small"
          onClick={() => setUpcoming(!upcoming)}
        >
          {buttonTitle}
        </Button>
      </Box>

      {
        upcoming
          ? <UpcomingEventsList artist={artist} showLoading={showLoading}/>
          : <PastEventsList artist={artist} showLoading={showLoading}/>
      }
    </Box>
  )
}

export default ArtistEventsList
