import React, {useEffect, useState} from 'react'
import {
  Box,
  Button,
  IconButton,
  SxProps,
  Typography,
} from '@mui/material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {DateTime} from 'luxon'
import {useHotkeys} from 'react-hotkeys-hook'

const WEEKDAYS_STARTING_SUN = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
const WEEKDAYS_STARTING_MON = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

const daysSx: SxProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 36px)',
  gridTemplateRows: 'repeat(1, 36px)',
}

const monthSx: SxProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 36px)',
  gridTemplateRows: 'repeat(6, 36px)',
}

type DatePickerProps = {
  date?: DateTime,
  handleChange: (newDate: DateTime) => void,
  showNonMonthDays?: boolean,
  startOnSun?: boolean,
}

const DatePicker = ({date=DateTime.now(), handleChange, showNonMonthDays=false, startOnSun=false}: DatePickerProps): JSX.Element => {
  const [currentMonth, setCurrentMonth] = useState<DateTime>(date.startOf('month'))
  const [cursorDate, setCursorDate] = useState<DateTime>(date)

  useHotkeys('arrowLeft', () => toPrevDay())
  useHotkeys('arrowRight', () => toNextDay())
  useHotkeys('arrowUp', () => toPrevWeek())
  useHotkeys('arrowDown', () => toNextWeek())
  useHotkeys('<', () => toPrevMonth(), {ignoreModifiers: true})
  useHotkeys('>', () => toNextMonth(), {ignoreModifiers: true})
  useHotkeys('t', () => toToday())
  useHotkeys('enter', () => selectDate())

  useEffect(() => {
    setCurrentMonth(date.startOf('month'))
    setCursorDate(date)
  }, [date, setCurrentMonth, setCursorDate])

  const showOnlyMonthDays = !showNonMonthDays

  const daysInMonth = currentMonth.daysInMonth

  const daysOfMonth: DateTime[] = []

  for (let i = 0; i < daysInMonth; i++) {
    daysOfMonth[i] = DateTime.local(currentMonth.year, currentMonth.month, i + 1)
  }

  const lastOfMonth = DateTime.local(currentMonth.year, currentMonth.month, daysInMonth)

  let firstOfMonthOffset = currentMonth.weekday - 1
  let lastOfMonthOffset = - (lastOfMonth.weekday - 7)

  if (startOnSun) {
    firstOfMonthOffset++
    lastOfMonthOffset--

    if (lastOfMonthOffset < 0) lastOfMonthOffset = 6

    if (firstOfMonthOffset > 6) firstOfMonthOffset = 0
  }

  const prevMonthDays = Array<DateTime>()
  const nextMonthDays = Array<DateTime>()

  const prevMonth = currentMonth.minus({months: 1})
  const nextMonth = currentMonth.plus({months: 1})

  const lastDayPrevMonth = prevMonth.daysInMonth

  for (let l = lastDayPrevMonth, i = 0; i < firstOfMonthOffset; i++, l--) {
    const day = DateTime.local(prevMonth.year, prevMonth.month, l)

    prevMonthDays.unshift(day)
  }

  for (let i = 0; i < lastOfMonthOffset; i++) {
    const day = DateTime.local(nextMonth.year, nextMonth.month, i + 1)

    nextMonthDays.push(day)
  }

  const calendarDays = [...prevMonthDays, ...daysOfMonth, ...nextMonthDays]

  const setDateAndClose = (date: DateTime) => {
    handleChange(date)
  }

  const WEEKDAYS = startOnSun ? WEEKDAYS_STARTING_SUN : WEEKDAYS_STARTING_MON

  const dayVisible = (date: DateTime): boolean => {
    const beforeCalendar = date.startOf('day') < calendarDays[0].startOf('day')
    const afterCalendar = date.startOf('day') > calendarDays[calendarDays.length - 1].startOf('day')

    const inLeftBounds =
      (showOnlyMonthDays && date.startOf('day') >= currentMonth.startOf('day')) ||
      (!showOnlyMonthDays && !beforeCalendar)

    const inRightBounds =
      (showOnlyMonthDays && date.startOf('day') <= lastOfMonth.startOf('day')) ||
      (!showOnlyMonthDays && !afterCalendar)

    return inLeftBounds && inRightBounds
  }

  const toPrevDay = () => {
    const date = cursorDate.minus({days: 1})

    if (!dayVisible(date)) setCurrentMonth(currentMonth.minus({months: 1}))

    setCursorDate(date)
  }

  const toNextDay = () => {
    const date = cursorDate.plus({days: 1})

    if (!dayVisible(date)) setCurrentMonth(currentMonth.plus({months: 1}))

    setCursorDate(date)
  }

  const toPrevWeek = () => {
    const date = cursorDate.minus({weeks: 1})

    if (!dayVisible(date)) setCurrentMonth(currentMonth.minus({months: 1}))

    setCursorDate(date)
  }

  const toNextWeek = () => {
    const date = cursorDate.plus({weeks: 1})

    if (!dayVisible(date)) setCurrentMonth(currentMonth.plus({months: 1}))

    setCursorDate(date)
  }

  const toPrevMonth = () => {
    setCurrentMonth(currentMonth.minus({months: 1}))
    setCursorDate(cursorDate.minus({months: 1}))
  }

  const toNextMonth = () => {
    setCurrentMonth(currentMonth.plus({months: 1}))
    setCursorDate(cursorDate.plus({months: 1}))
  }

  const toToday = () => {
    const today = DateTime.now()
    setCursorDate(today)
    setCurrentMonth(today.startOf('month'))
  }

  const selectDate = () => {
    setDateAndClose(cursorDate)
  }

  return (
    <Box mt={1}>
      <Box display='flex' justifyContent='center'>
        <Box display='flex' alignItems='center'>
          <IconButton size='small' onClick={() => toPrevMonth()}>
            <ArrowLeftIcon/>
          </IconButton>
          <Typography textAlign='center' px={1} fontSize={14}>
            {currentMonth.toLocaleString({month: 'long', year: 'numeric'})}
          </Typography>
          <IconButton size='small' onClick={() => toNextMonth()}>
            <ArrowRightIcon/>
          </IconButton>
        </Box>
      </Box>
      <Box sx={daysSx}>
        {WEEKDAYS.map((weekday, index) => (
          <Box key={index}>
            <Typography textAlign='center' fontSize={14}>{weekday}</Typography>
          </Box>
        ))}
      </Box>
      <Box sx={monthSx}>
        {calendarDays.map((calendarDay, index) => {
          // calendarDays is array of prev month days + current + next.
          // Non-month days are only visible and active when showOnlyMonthDays is false.

          const now = DateTime.now()

          const sameMonthAsDate = date.hasSame(currentMonth, 'month')
          const sameMonthAsToday = now.hasSame(currentMonth, 'month')

          const isCurrentMonth = calendarDay.hasSame(currentMonth, 'month')

          const boxBorderColor = isCurrentMonth && sameMonthAsToday && now.hasSame(calendarDay, 'day') ? 'secondary.main' : 'transparent'
          const cursorBorderColor = cursorDate.hasSame(calendarDay, 'day') ? 'common.white' : 'transparent'

          const buttonColor = (isCurrentMonth && sameMonthAsDate && date.hasSame(calendarDay, 'day')) ? 'primary.main' : undefined
          const showButton = (showOnlyMonthDays && isCurrentMonth) || !showOnlyMonthDays

          const dayColor = isCurrentMonth ? 'inherit' : 'gray'

          return (
            <Box
              key={index}
              sx={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: boxBorderColor,
              }}
            >
              {showButton && <Button
                sx={{
                  color: dayColor,
                  maxWidth: 36,
                  minWidth: 36,
                  borderRadius: 18,
                  backgroundColor: buttonColor,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: cursorBorderColor,
                }}
                onClick={() => setDateAndClose(calendarDay)}
              >
                <Typography fontSize={14}>{calendarDay.day}</Typography>
              </Button>}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default DatePicker
