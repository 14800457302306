import React from 'react'
import {DateTime, Interval} from 'luxon'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {
  Card,
  CardActionArea,
  SxProps,
  Typography,
} from '@mui/material'
import type {Event} from 'data/types'
import {formatInterval} from 'utils'
import {getEventName} from 'events/utils'
import {getPrimaryUrl} from 'utils/urls'

const cardSx: SxProps = {
  p: 2,
  display: 'grid',
  gridTemplateColumns: '1fr max(45%)',
  columnGap: 2,
  alignItems: 'start',
}

type PlaceEventCardProps = {
  event: Event,
}

const PlaceEventCard = ({event}: PlaceEventCardProps): JSX.Element => {
  const location = useLocation()

  const eventName = getEventName(event)

  let eventTime
  if (event.endTime) {
    eventTime = formatInterval(Interval.fromDateTimes(event.startTime, event.endTime))
  }

  const to = getPrimaryUrl(event)
  const state = {
    backgroundLocation: location,
  }

  const ended = event.endTime && DateTime.now() > DateTime.fromJSDate(event.endTime)

  return (
    <Card elevation={0} sx={{mb: 1}}>
      <CardActionArea component={RouterLink} to={to} state={state} sx={cardSx}>
        <Typography color="warning.main" fontSize={18}>{eventName}</Typography>
        <Typography
          sx={{
            textAlign: 'right',
            fontStyle: ended ? 'italic' : 'normal',
          }}
          color={ended ? 'text.disabled' : 'inherit'}
        >
          {eventTime}
        </Typography>
      </CardActionArea>
    </Card>
  )
}

export default PlaceEventCard
