import {useCallback} from 'react'
import {gql, MutationHookOptions, MutationResult, useMutation} from '@apollo/client'
import {COMPLETE_ARTIST_FIELDS} from 'data/fragments/artist'
import type {Artist, Handle, Input, Link, Where} from 'data/types'

interface ArtistUpdateVars {
  where: Where<Artist>,
  update: Input<Partial<Artist>>,
}

const UPDATE_ARTIST_MUTATION = gql `
  ${COMPLETE_ARTIST_FIELDS}

  mutation UpdateArtist($where: ArtistWhere, $update: ArtistUpdateInput) {
    updateArtists(where: $where, update: $update) {
      artists {
        ...CompleteArtistFields
      }
    }
  }
`

interface ArtistUpdateData {
  updateArtists: {
    artists: Artist[],
  },
}

interface UseUpdateArtistResult {
  updateArtist: (data: Partial<Artist>) => void,
  result: MutationResult,
}

type UpdateArtistOptions = MutationHookOptions<ArtistUpdateData, ArtistUpdateVars>

export const useUpdateArtist = (artist: Artist, options?: UpdateArtistOptions): UseUpdateArtistResult => {
  const [mutate, result] = useMutation<ArtistUpdateData, ArtistUpdateVars>(UPDATE_ARTIST_MUTATION, {
    onError: () => {/* prevent exception from being thrown */},
    ...options,
  })

  const updateArtist = useCallback((data: Partial<Artist>) => {
    const whereInput: Where<Artist> = {
      id: artist.id,
    }

    const updateInput: Input<Partial<Artist>> = {
    }

    // name
    if (data.name && data.name !== artist.name) {
      updateInput.name = data.name
    }

    // description
    if (data.description) {
      const node = {
        id: data.description.id,
        format: data.description.format,
        content: data.description.content,
      }

      if (artist.description?.createdAt) {
        if (node.content !== artist.description.content) {
          updateInput.description = {
            update: {
              node,
            },
          }
        }
      } else {
        updateInput.description = {
          create: {
            node,
          },
        }
      }
    } else {
      if (artist.description) {
        updateInput.description = {
          delete: {
            where: {
              node: {
                id: artist.description.id,
              },
            },
          },
        }
      }
    }

    // links
    const addedLinks: Link[] = []
    for (const link of data.links ?? []) {
      if (artist.links?.filter(l => l.url === link.url).length === 0) {
        addedLinks.push(link)
      }
    }

    const removedLinks: Link[] = []
    for (const link of artist.links ?? []) {
      if (data.links?.filter(l => l.url === link.url).length === 0) {
        removedLinks.push(link)
      }
    }

    if (addedLinks.length > 0) {
      if (!updateInput.links) updateInput.links = {}

      updateInput.links.create = addedLinks.map((link) => {
        const node = {
          id: link.id,
          url: link.url,
        }

        return {node}
      })
    }

    if (removedLinks.length > 0) {
      if (!updateInput.links) updateInput.links = {}

      updateInput.links.delete = removedLinks.map((link) => {
        return {
          where: {
            node: {
              id: link.id,
            },
          },
        }
      })
    }

    // handles
    const addedHandles: Handle[] = []
    for (const handle of data.handles ?? []) {
      if (artist.handles?.filter(h => h.name === handle.name).length === 0) {
        addedHandles.push(handle)
      }
    }

    if (addedHandles.length > 0) {
      if (!updateInput.handles) updateInput.handles = {}

      updateInput.handles.create = addedHandles.map((handle) => {
        const node = {
          name: handle.name,
        }

        return {node}
      })
    }

    if (data.primaryHandle && data.primaryHandle.name !== artist.primaryHandle?.name) {
      updateInput.primaryHandle = {
        disconnect: {
          where: {
            node: {
              name_NOT: data.primaryHandle.name,
            },
          },
        },
        connect: {
          where: {
            node: {
              name: data.primaryHandle.name,
            },
          },
        },
      }
    }

    if (Object.keys(updateInput).length > 0) {
      mutate({
        variables: {
          where: whereInput,
          update: updateInput,
        },
      })
    }

    console.log({
      artist,
      data,
      updateInput,
    })
  }, [artist, mutate])

  return {
    updateArtist,
    result,
  }
}
