import React, {useEffect} from 'react'
import {
  Paper,
  Typography,
} from '@mui/material'
import {Artist} from 'data/types'
import {useFetchPastArtistEvents} from 'artists/hooks'
import Card from './Card'

const FETCH_OPTIONS = {
  fetchPolicy: 'cache-and-network',
} as const

interface PastEventsListProps {
  artist: Artist,
  showLoading: (loading: boolean) => void,
}

const PastEventsList = ({artist, showLoading}: PastEventsListProps): JSX.Element | null => {
  const {fetchPastArtistEvents, events=[], result} = useFetchPastArtistEvents(artist.id, FETCH_OPTIONS)

  useEffect(() => {
    result.startPolling(60000)

    return () => result.stopPolling()
  })

  fetchPastArtistEvents()

  useEffect(() => {
    showLoading(result.loading)
  }, [showLoading, result.loading])

  return (
    <>
      {events.map(e => <Card key={e.id} event={e} artist={artist}/>)}

      {!result.loading && events.length === 0 &&
        <Paper sx={{p: 2, mb: 1}}>
          <Typography>There are no past events.</Typography>
        </Paper>
      }
    </>
  )
}

export default PastEventsList
