import React from 'react'
import {
  Box,
  Container,
  SxProps,
  Typography,
} from '@mui/material'
import UsersTable from './Table'
import {useUsers} from 'users/hooks/useUsers'

const headerSx: SxProps = {
  my: 2,
  px: 2,
  py: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'info.main',
  borderRadius: 1,
}

const UsersAdminPanel = (): JSX.Element => {
  const {users} = useUsers()

  return (
    <Container>
      <Box sx={headerSx}>
        <Typography variant="h2" fontSize={24} fontWeight={300}>Hapnyn Users</Typography>
        <Typography fontSize={24} fontWeight={300}>Total Count: {users?.length || 0}</Typography>
      </Box>

      <UsersTable users={users}/>
    </Container>
  )
}

export default UsersAdminPanel
