import {useApolloClient} from '@apollo/client'
import {clearJwt} from 'data/auth'
import {useCallback} from 'react'

type SignOutFunction = () => void

export const useSignOut = (): SignOutFunction => {
  const client = useApolloClient()

  const signOut = useCallback(() => {
    clearJwt()
    client.clearStore()
  }, [client])

  return signOut
}
