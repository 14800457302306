import {gql, QueryResult, useQuery} from '@apollo/client'
import {CORE_USER_FIELDS} from 'data/fragments/user'
import {User} from 'data/types'

const USERS_QUERY = gql `
  ${CORE_USER_FIELDS}

  query Users {
    users(options: {sort: [{name: ASC}]}) {
      ...CoreUserFields
    }
  }
`

interface UsersQueryData {
  users: User[],
}

interface FetchUsersQueryResult {
  users?: User[],
  result: QueryResult<UsersQueryData>,
}

export const useUsers = (): FetchUsersQueryResult => {
  const result = useQuery<UsersQueryData>(USERS_QUERY)

  const users = result.data?.users

  return {
    users,
    result,
  }
}
