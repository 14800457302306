import {useEffect, useState} from 'react'
import {Loader} from '@googlemaps/js-api-loader'
import {googleApiKey} from 'google/utils'

const loader = new Loader({
  apiKey: googleApiKey(),
  version: 'weekly',
  libraries: ['places'],
})

export const useLoader = (): boolean => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    loader.load().then(() => setLoaded(true))
  }, [])

  return loaded
}
