import React from 'react'
import {
  Box,
  BoxProps,
} from '@mui/material'
import type {Link} from 'data/types'
import {SPOTIFY_ARTIST_URL} from 'utils/regex'

interface SpotifyPlayerProps extends BoxProps {
  links?: Link[],
  height?: string | number,
}

const SpotifyPlayer = ({links, height, ...boxProps}: SpotifyPlayerProps): JSX.Element | null => {
  const spotifyLinks = links?.filter(l => l.url.match(SPOTIFY_ARTIST_URL))

  if (!spotifyLinks) return null

  return (
    <>
      {
        spotifyLinks.map((link) => {
          const match = link.url.match(SPOTIFY_ARTIST_URL)
          const spotifyId = match?.groups?.['id']

          const url = `https://open.spotify.com/embed/artist/${spotifyId}`

          return (
            <Box key={url} {...boxProps}>
              <iframe
                src={url}
                width="100%"
                height={height ?? 400}
                frameBorder="0"
                allow="encrypted-media"
              />
            </Box>
          )
        })
      }
    </>
  )
}

export default SpotifyPlayer
