import React from 'react'
import {Helmet} from 'react-helmet-async'
import {useParams} from 'react-router-dom'
import {useFetchEventById} from 'events/hooks'
import {LocationParams} from 'types/location'
import EventDetails from 'events/components/Details'
import {getEventName} from 'events/utils'

const EventDetailsView = (): JSX.Element | null => {
  const {id} = useParams<LocationParams>()
  const {fetchEventById, event} = useFetchEventById()

  if (id) fetchEventById(id)

  if (!event) return null

  const title = "Hapnyn - " + getEventName(event) + " at " + event.location.name

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <EventDetails event={event}/>
    </>
  )
}

export default EventDetailsView
