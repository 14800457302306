import {useCallback} from 'react'
import {gql, QueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_PLACE_FIELDS} from 'data/fragments/place'
import {CORE_LINK_FIELDS} from 'data/fragments/link'
import type {Place} from 'data/types'

const FETCH_PLACES_QUERY = gql `
  ${CORE_PLACE_FIELDS}
  ${CORE_LINK_FIELDS}

  query FetchPlaces($includeLinks: Boolean = false, $includeEventsCount: Boolean = false, $today: DateTime) {
    places(options: {sort: [{name: ASC}]}) {
      ...CorePlaceFields

      links @include(if: $includeLinks) {
        ...CoreLinkFields
      }

      eventsTotal: eventsAggregate @include(if: $includeEventsCount) {
        count
      }

      eventsUpcoming: eventsAggregate(where: {startTime_GTE: $today}) @include(if: $includeEventsCount) {
        count
      }
    }
  }
`

interface FetchPlacesQueryVars {
  includeLinks?: boolean,
  includeEventsCount?: boolean,
  today: Date,
}

interface FetchPlacesQueryResult {
  places: Place[],
}

interface UseFetchPlacesResult {
  fetchPlaces: (vars?: Partial<FetchPlacesQueryVars>) => void,
  places?: Place[],
  result: QueryResult<FetchPlacesQueryResult, FetchPlacesQueryVars>,
}

type FetchPlacesOptions = QueryHookOptions<FetchPlacesQueryResult, FetchPlacesQueryVars>

export const useFetchPlaces = (options?: FetchPlacesOptions): UseFetchPlacesResult => {
  const [query, result] = useLazyQuery<FetchPlacesQueryResult, FetchPlacesQueryVars>(FETCH_PLACES_QUERY, options)

  const fetchPlaces = useCallback((vars?: Partial<FetchPlacesQueryVars>) => {
    const variables = {
      today: new Date(),
      ...vars,
    }

    if (!result.called) query({variables})
  }, [result.called, query])

  const places = result.data?.places

  return {
    fetchPlaces,
    places,
    result,
  }
}
