import React from 'react'
import {
  Theme,
  useMediaQuery,
} from '@mui/material'
import type {Event} from 'data/types'
import MobileEventLocation from './Mobile'
import DesktopEventLocation from './Desktop'

interface EventLocationProps {
  event: Event,
}

const EventLocation = ({event}: EventLocationProps): JSX.Element | null => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (isMobile) return <MobileEventLocation event={event}/>

  return <DesktopEventLocation event={event}/>
}

export default EventLocation
