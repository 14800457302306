import React from 'react'
import {
  AppBar,
  SxProps,
  Toolbar,
} from '@mui/material'
import AuthMenu from 'auth/Menu'
import HomeLink from 'hapnyn/components/HomeLink'
import DesktopNav from 'hapnyn/components/Nav/Desktop'

const toolbarSx: SxProps = {
  userSelect: 'none',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  gap: 2,
  alignItems: 'center',
}

const DesktopMenu = (): JSX.Element => {
  return (
    <AppBar
      position="sticky"
      color="primary"
      enableColorOnDark
      elevation={0}
    >
      <Toolbar variant="dense" sx={toolbarSx}>
        <HomeLink/>
        <DesktopNav/>
        <AuthMenu/>
      </Toolbar>
    </AppBar>
  )
}

export default DesktopMenu
