import React from 'react'
import {
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {User} from 'data/types'

const headerSx: SxProps = {
  color: 'primary.main',
  fontSize: 18,
}

type UsersTableProps = {
  users?: User[],
}

const UsersTable = ({users}: UsersTableProps): JSX.Element | null => {
  if (!users || users.length === 0) return null

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={headerSx}>User Name</TableCell>
            <TableCell sx={headerSx}>Primary Email</TableCell>
            <TableCell sx={headerSx}>Roles</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users && users.map((user) => {
            return (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.primaryEmail.address}</TableCell>
                <TableCell>{user.roles?.map(r => r.name).join(", ")}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UsersTable
