import React from 'react'
import CounterCard from './CounterCard'
import {useUsersCount} from 'users/hooks'

const UsersCount = (): JSX.Element => {
  const {count, result: {loading, error}} = useUsersCount()

  return (
    <CounterCard
      label="Users Count"
      count={count}
      loading={loading}
      error={error?.message}
      path="users"
    />
  )
}

export default UsersCount
