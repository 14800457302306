import {v4 as uuid} from 'uuid'
import type {Address, Phone, Place} from 'data/types'
import {ContactMethod} from 'data/types/Contact'

export const googleApiKey = (): string => {
  return process.env.REACT_APP_GOOGLE_API_KEY ?? "AIzaSyD0HX7D4KvjeteF3mNb_n_I1v1AZ6PIets"
}

export const googleToHapnynPlace = (googlePlace: google.maps.places.PlaceResult): Place | undefined => {
  const {
    place_id: googlePlaceId,
    name,
    geometry,
    address_components,
    formatted_phone_number: phoneNumber,
    url,
    website,
  } = googlePlace

  const latitude = geometry?.location?.lat()
  const longitude = geometry?.location?.lng()

  if (!name || !googlePlaceId || !latitude || !longitude) return undefined

  const location = {
    latitude,
    longitude,
  }

  let addresses: Address[] | undefined
  let physicalAddress: Address | undefined

  const streetNumber = address_components?.find(c => c.types.includes('street_number'))?.short_name
  const route = address_components?.find(c => c.types.includes('route'))?.short_name
  const neighborhood = address_components?.find(c => c.types.includes('neighborhood'))?.short_name
  const locality = address_components?.find(c => c.types.includes('locality'))?.short_name
  const region = address_components?.find(c => c.types.includes('administrative_area_level_1'))?.short_name
  const postalCode = address_components?.find(c => c.types.includes('postal_code'))?.short_name
  const country = address_components?.find(c => c.types.includes('country'))?.short_name

  const street = [streetNumber, route].filter(s => s).join(" ")

  if (locality && region && postalCode) {
    physicalAddress = {
      id: uuid(),
      street: street.length > 0 ? street : undefined,
      neighborhood,
      locality,
      region,
      postalCode,
      country,
      location,
    }

    addresses = [physicalAddress]
  }

  let contactMethods: ContactMethod[] | undefined
  let primaryPhone: Phone | undefined

  if (phoneNumber) {
    primaryPhone = {
      number: phoneNumber,
    }

    contactMethods = [primaryPhone]
  }

  const links = [url, website].filter((u): u is string => !!u).map(u => ({id: uuid(), url: u}))

  return {
    id: uuid(),
    googlePlaceId,
    name,
    location,

    addresses,
    physicalAddress,

    contactMethods,
    primaryPhone,

    links,
  }
}
