import {gql, QueryHookOptions, QueryResult, useQuery} from '@apollo/client'

const EVENTS_COUNT_QUERY = gql `
  query EventsCount {
    eventsAggregate {
      count
    }
  }
`

interface EventsCountData {
  eventsAggregate: {
    count: number,
  },
}

interface EventsCountQueryResult {
  count?: number,
  result: QueryResult<EventsCountData>,
}

type EventsCountOptions = QueryHookOptions<EventsCountData>

export const useEventsCount = (options?: EventsCountOptions): EventsCountQueryResult => {
  const result = useQuery<EventsCountData>(EVENTS_COUNT_QUERY, {
    fetchPolicy: 'network-only',
    ...options,
  })

  const count = result.data?.eventsAggregate.count

  return {
    count,
    result,
  }
}
