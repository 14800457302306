import {useCallback} from 'react'
import {gql, LazyQueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_EVENT_FIELDS} from 'data/fragments/event'
import {Artist} from 'data/types'

interface FetchArtistEventsVars {
  artistId: string,
}

const FETCH_ARTIST_EVENTS_QUERY = gql `
  ${CORE_EVENT_FIELDS}

  query FetchArtistEvents($artistId: ID!) {
    artists(
      where: {id: $artistId}
    ) {
      id
      events(
        options: {sort: {startTime: ASC}}
      ) {
        ...CoreEventFields
      }
    }
  }
`

interface FetchArtistEventsData {
  artists: Artist[],
}

interface UseFetchArtistEventsResult {
  fetchArtistEvents: () => void,
  result: QueryResult<FetchArtistEventsData, FetchArtistEventsVars>,
}

type FetchArtistEventsOptions = LazyQueryHookOptions<FetchArtistEventsData, FetchArtistEventsVars>

export const useFetchArtistEvents = (artistId: string, options?: FetchArtistEventsOptions): UseFetchArtistEventsResult => {
  const [query, result] = useLazyQuery<FetchArtistEventsData, FetchArtistEventsVars>(FETCH_ARTIST_EVENTS_QUERY, options)

  const fetchArtistEvents = useCallback(() => {
    if (!result.called) query({variables: {artistId}})
  }, [result.called, query, artistId])

  return {
    fetchArtistEvents,
    result,
  }
}
