import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Link as MuiLink,
  SxProps,
  Theme,
} from '@mui/material'
import type {Event} from 'data/types'
import LinkButtons from 'ui/LinkButtons'
import EventArtists from 'events/components/Details/Artists'
import EventSponsors from 'events/components/Details/Sponsors'
import EventPromoters from 'events/components/Details/Promoters'
import EventDescription from 'events/components/Details/Description'
import EventLocation from 'events/components/Details/Location'
import EventTime from 'events/components/Details/Time'
import EventTitle from 'events/components/Details/Title'
import ActionsDial from 'events/components/ActionsDial'
import {useRoles} from 'auth/hooks'
import {useHotkeys} from 'react-hotkeys-hook'
import {VENMO_URL} from 'utils/regex'
import Icon from 'ui/LinkButtons/Icon'

const gridSx: SxProps = {
  display: 'grid',
  gridTemplateColumns: '1fr minmax(300px, 40%)',
  gridTemplateAreas: `
    "main sidebar"
  `,
  columnGap: 6,
  mb: 2,
}

const mainSx: SxProps<Theme> = {
  gridArea: 'main',
  display: 'grid',
  alignContent: 'start',
  gap: 2,
}

const sidebarSx: SxProps<Theme> = {
  gridArea: 'sidebar',
  display: 'grid',
  alignContent: 'start',
  gap: 2,
}

interface DesktopEventDetailsProps {
  event: Event,
}

const DesktopEventDetails = ({event}: DesktopEventDetailsProps): JSX.Element => {
  const {isContentManager} = useRoles()
  const navigate = useNavigate()
  const location = useLocation()

  useHotkeys('ctrl+Enter, meta+Enter', () => editEvent(),
    {enableOnFormTags: true, preventDefault: true})
  useHotkeys('ctrl+d, meta+d', () => duplicateEvent(),
    {enableOnFormTags: true, preventDefault: true})

  const editEvent = () => {
    if (isContentManager)
      navigate(`/events/edit/${event.id}`, {state: {from: location}})
  }

  const duplicateEvent = () => {
    if (isContentManager)
      navigate(`/events/new?sourceEventId=${event.id}`, {state: {from: location}})
  }

  const {links} = event
  const venmoLinks = links?.filter(l => l.url.match(VENMO_URL))
  const otherLinks = links?.filter(l => !l.url.match(VENMO_URL))

  let artistHeader
  let sponsorHeader
  let promoterHeader

  if (event.sponsorArtists && event.sponsorArtists.length > 0) {
    artistHeader = "Artists"
    sponsorHeader = "Sponsors"
  }

  return (
    <Container maxWidth="lg" sx={{my: 4}}>
      <ActionsDial event={event}/>

      <Box sx={gridSx}>
        <Box sx={mainSx}>
          <EventTitle event={event} sx={{mb: 1}}/>
          <EventTime event={event}/>
          <EventDescription description={event.description}/>
          <EventArtists artists={event.artists} header={artistHeader}/>
          <EventSponsors artists={event.sponsorArtists} header={sponsorHeader}/>
          <EventPromoters artists={event.promotedArtists} header={promoterHeader}/>
        </Box>

        <Box sx={sidebarSx}>
          <EventLocation event={event}/>
          {venmoLinks && venmoLinks.length > 0 &&
            <Box justifySelf="end">
              {venmoLinks.map((link, i) => {
                const handleFromUrl = (url: string) => {
                  let s = url.split("?")[0]
                  s = (s.slice(-1)) == "/" ? s.slice(0, -1) : s
                  return s.split("/").pop()
                }

                return (
                  <Button
                    key={i}
                    color="inherit"
                    startIcon={<Icon link={link}/>}
                    size="large"
                    component={MuiLink}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {handleFromUrl(link.url)}
                  </Button>
                )
              })}
            </Box>
          }
          {otherLinks && otherLinks.length > 0 && <LinkButtons links={otherLinks} justify="end"/>}
        </Box>
      </Box>
    </Container>
  )
}

export default DesktopEventDetails
