import React from 'react'
import {
  Box,
  BoxProps,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import TimeIcon from '@mui/icons-material/AccessTime'
import type {Event} from 'data/types'
import {eventTimeStr} from 'events/utils'

const sectionSx: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: theme => `${theme.spacing(5)} auto`,
}

const iconSx: SxProps<Theme> = {
  color: theme => theme.palette.text.disabled,
  fontSize: 28,
}

interface EventTimeProps extends BoxProps {
  event: Event,
}

const EventTime = ({event, ...boxProps}: EventTimeProps): JSX.Element => {
  const time = eventTimeStr({event, includeDay: true})

  return (
    <Box sx={sectionSx} {...boxProps}>
      <TimeIcon sx={iconSx}/>
      <Typography
        fontSize={24}
        lineHeight={1}
        color="info.main"
      >
        {time}
      </Typography>
    </Box>
  )
}

export default EventTime
