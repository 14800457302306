import React from 'react'
import {
  Box, CircularProgress, Typography,
} from '@mui/material'
import type {Place} from 'data/types'
import {useFetchPlaceById} from 'places/hooks'

interface DetailsProps {
  place: Place,
}

const Details = ({place}: DetailsProps): JSX.Element => {
  const {fetchPlaceById, result} = useFetchPlaceById()

  if (!result.called) fetchPlaceById(place.id)

  if (result.loading) {
    return (
      <Box py={1} textAlign="center"><CircularProgress/></Box>
    )
  }

  return (
    <Box pb={2}>
      <Typography>
        <Typography component="span" color="primary.main">Google Place ID: </Typography>
        {place.googlePlaceId}
      </Typography>
      <Typography>
        <Typography component="span" color="primary.main">Lat/Lng: </Typography>
        {place.location.latitude} / {place.location.longitude}
      </Typography>
    </Box>
  )
}

export default Details
