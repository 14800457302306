import React from 'react'
import {matchPath,  useLocation, useNavigate} from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  IconButton,
  SxProps,
  Theme,
  useMediaQuery,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {useFetchEventById} from 'events/hooks'
import {LocationState} from 'types/location'
import EventDetails from 'events/components/Details'
import {useRoles} from 'auth/hooks/useRoles'
import {useHotkeys} from 'react-hotkeys-hook'

const closeButtonSx: SxProps = {
  position: 'absolute',
  top: 8,
  right: 8,
}

const EventDetailsDialog = (): JSX.Element | null => {
  const {isContentManager} = useRoles()
  const location = useLocation()
  const navigate = useNavigate()
  const {fetchEventById, event} = useFetchEventById()
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const ref = useHotkeys<HTMLDivElement>('ctrl+Enter, meta+Enter', () => editEvent(),
    {enableOnFormTags: true, preventDefault: true})

  const state = location.state as LocationState
  const foregroundLocation = state?.foregroundLocation

  const match = matchPath('/events/:id', foregroundLocation?.pathname ?? '')

  const eventId = match?.params.id

  // TODO: support fetch by handle
  if (eventId) fetchEventById(eventId)

  if (!eventId) return null

  const editEvent = () => {
    if (isContentManager && eventId)
      navigate(`/events/edit/${eventId}`, {state: {from: location}})
  }

  const handleClose = () => {
    navigate(location, {replace: true})
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      fullScreen={fullScreen}
      open={!!event}
      onClose={handleClose}
      scroll="body"
      ref={ref}
    >
      <DialogContent sx={{mt: {xs: 3, md: 0}, p: {xs: 0, md: 2}}}>
        {event && <EventDetails event={event}/>}
        <IconButton sx={closeButtonSx} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
      </DialogContent>
    </Dialog>
  )
}

export default EventDetailsDialog
