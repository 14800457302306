import type {Artist, Event, Place} from 'data/types'

type HapnynObject = Artist | Event | Place

export const getPrimaryUrl = (hapnynObject: HapnynObject): string => {
  if (hapnynObject.primaryHandle) {
    return `/@${hapnynObject.primaryHandle.name}`
  } else {
    switch (hapnynObject.__typename) {
      case 'Artist':
        return `/artists/${hapnynObject.id}`
      case 'Event':
        return `/events/${hapnynObject.id}`
      case 'Place':
        return `/places/${hapnynObject.id}`
      default:
        throw Error("missing __typename")
    }
  }
}

export const getEditUrl = (hapnynObject: HapnynObject): string => {
  switch (hapnynObject.__typename) {
    case 'Artist':
      return `/artists/edit/${hapnynObject.id}`
    case 'Event':
      return `/events/edit/${hapnynObject.id}`
    case 'Place':
      return `/places/edit/${hapnynObject.id}`
    default:
      throw Error("missing __typename")
  }
}
