import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import EventIcon from '@mui/icons-material/Event'
import {useRoles} from 'auth/hooks'
import {Place} from 'data/types'
import {ctrlOs} from 'utils'
import TooltipLabel from 'ui/TooltipLabel'

interface ActionsDialProps {
  place: Place,
}

const ActionsDial = ({place}: ActionsDialProps): JSX.Element | null => {
  const {isContentManager} = useRoles()
  const navigate = useNavigate()
  const location = useLocation()

  if (!isContentManager) return null

  return (
    <SpeedDial
      ariaLabel="Place Actions"
      sx={{position: 'fixed', bottom: 16, right: 16}}
      icon={<SpeedDialIcon/>}
    >
      <SpeedDialAction
        icon={<EventIcon/>}
        tooltipTitle={<TooltipLabel title="Add Event" shortcut={`${ctrlOs()} + Enter`}/>}
        onClick={() => navigate(`/events/new?placeId=${place.id}`, {state: {from: location}})}
      />
      <SpeedDialAction
        icon={<EditIcon/>}
        tooltipTitle={<TooltipLabel title="Edit Place"/>}
        onClick={() => navigate(`/places/edit/${place.id}`, {state: {from: location}})}
      />
    </SpeedDial>
  )
}

export default ActionsDial
