import React from 'react'
import {
  Box,
  Container,
  Typography,
} from '@mui/material'
import ArtistCount from 'admin/components/Counters/ArtistsCount'
import PlacesCount from 'admin/components/Counters/PlacesCount'
import UsersCount from 'admin/components/Counters/UsersCount'
import EventsCount from 'admin/components/Counters/EventsCount'

const AdminDashboard = (): JSX.Element => {
  return (
    <Container>
      <Typography variant="h2" mt={4}>Admin Dashboard</Typography>
      <Box my={2} display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, auto))" gap={2}>
        <ArtistCount/>
        <PlacesCount/>
        <EventsCount/>
        <UsersCount/>
      </Box>
    </Container>
  )
}

export default AdminDashboard
