import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {debounce} from 'lodash'
import {useSearchParams} from 'react-router-dom'
import {Searcher} from 'fast-fuzzy'
import {
  Autocomplete,
  FilterOptionsState,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material'
import type {Place} from 'data/types'
import {useFetchPlaceById, useFetchPlaceByName} from 'places/hooks'
import {addressToString} from 'places/utils'

interface PlacePickerProps {
  place?: Place,
  onUpdate?: (place?: Place) => void,
  autoFocus?: boolean,
}

const PlacePicker = ({place, onUpdate, autoFocus=false}: PlacePickerProps): JSX.Element => {
  // ======================================================================
  // TODO: move loading from searchParams elsewhere
  // ======================================================================
  const [searchParams] = useSearchParams()
  const {fetchPlaceById, result: fetchPlaceByIdResult} = useFetchPlaceById({fetchPolicy: 'cache-and-network'})

  const placeId = searchParams.get('placeId')

  if (placeId) {
    if (!fetchPlaceByIdResult.called) fetchPlaceById(placeId)
  }

  useEffect(() => {
    const place = fetchPlaceByIdResult.data?.places?.[0]

    if (place && onUpdate) onUpdate(place)
  }, [onUpdate, fetchPlaceByIdResult.data])

  // ======================================================================
  // END TODO
  // ======================================================================

  const {
    fetchPlaceByName,
    places: fetchedPlaces = [],
    result: fetchPlaceByNameResult,
  } = useFetchPlaceByName({fetchPolicy: 'cache-and-network'})

  const handleChange = (place?: Place) => {
    if (place && onUpdate) onUpdate(place)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchPlacesByName = useCallback(debounce(fetchPlaceByName, 500), [fetchPlaceByName])

  const fetchedPlacesUnion = useRef<Place[]>([])
  const fetchedPlacesUnionIds = useRef<string[]>([])

  const newPlaces = fetchedPlaces.filter(a => !fetchedPlacesUnionIds.current.includes(a.id))
  const newPlacesIds = newPlaces.map(a => a.id)

  fetchedPlacesUnion.current = [...fetchedPlacesUnion.current, ...newPlaces]
  fetchedPlacesUnionIds.current = [...fetchedPlacesUnionIds.current, ...newPlacesIds]

  const disabled = !!placeId

  const loading = fetchPlaceByIdResult.loading || fetchPlaceByNameResult.loading

  const options = fetchedPlacesUnion.current ?? fetchPlaceByIdResult.data?.places ?? []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searcher = useMemo(() => new Searcher(fetchedPlacesUnion.current, {keySelector: p => p.name}), [fetchedPlacesUnion.current])

  const filterOptions = (options: Place[], state: FilterOptionsState<Place>) => searcher.search(state.inputValue)

  const handleInputChange = (_event: React.SyntheticEvent, value: string) => {
    if (value.length > 0) debouncedFetchPlacesByName(value)
  }

  return (
    <Autocomplete
      autoHighlight
      disabled={disabled}
      value={place ?? null}
      filterOptions={filterOptions}
      onChange={(_event, value) => handleChange(value ?? undefined)}
      onInputChange={handleInputChange}
      fullWidth
      options={options}
      getOptionLabel={o => o.name}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      loading={loading}
      renderInput={(params) =>
        <TextField {...params} label="Where" autoFocus={autoFocus}/>}
      disableClearable={!!placeId || !!onUpdate}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.id}>
          <ListItemText
            primary={option.name}
            primaryTypographyProps={{color: 'primary', fontSize: {xs: '1em', sm: '1.3em'}}}
            secondary={option.physicalAddress ? addressToString(option.physicalAddress) : null}
            secondaryTypographyProps={{color: 'inherit', fontWeight: 300}}
          />
        </ListItem>
      )}
    />
  )
}

export default PlacePicker
