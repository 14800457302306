import React, {useEffect} from 'react'
import {
  Paper,
  Typography,
} from '@mui/material'
import {useFetchUpcomingPlaceEvents} from 'places/hooks'
import Card from './Card'

const FETCH_OPTIONS = {
  fetchPolicy: 'cache-and-network',
} as const

interface UpcomingEventsListProps {
  id: string,
  showLoading: (loading: boolean) => void,
}

const UpcomingEventsList = ({id, showLoading}: UpcomingEventsListProps): JSX.Element | null => {
  const {fetchUpcomingPlaceEvents, result} = useFetchUpcomingPlaceEvents(id, FETCH_OPTIONS)

  useEffect(() => {
    result.startPolling(60000)

    return () => result.stopPolling()
  })

  fetchUpcomingPlaceEvents()

  const events = result.data?.places[0].events ?? []

  showLoading(result.loading)

  return (
    <>
      {events.map(e => <Card key={e.id} event={e}/>)}

      {!result.loading && events.length === 0 &&
        <Paper sx={{p: 2, mb: 1}}>
          <Typography>There are no upcoming events.</Typography>
        </Paper>
      }
    </>
  )
}

export default UpcomingEventsList
