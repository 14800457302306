import React, {useState} from 'react'
import {
  Box,
  Collapse,
  Paper,
  Typography,
} from '@mui/material'
import ExpandIcon from 'ui/ExpandIcon'
import type {Text} from 'data/types'

interface DescriptionProps {
  description: Text,
}

const MobileDescription = ({description}: DescriptionProps): JSX.Element | null => {
  const [expand, setExpand] = useState(false)

  const toggleExpand = () => setExpand(!expand)

  return (
    <Paper elevation={0} sx={{p: 2}}>
      <Box display="flex" justifyContent={{xs: 'space-between', md: 'flex-start'}} onClick={toggleExpand}>
        <Typography
          fontSize={24}
          lineHeight={1}
          color="info.main"
        >
          About
        </Typography>
        <ExpandIcon expanded={expand} color="info"/>
      </Box>
      <Collapse in={expand}>
        <Typography fontSize={18} fontWeight={300} whiteSpace="pre-wrap" mt={2}>
          {description.content}
        </Typography>
      </Collapse>
    </Paper>
  )
}

export default MobileDescription
