import React from 'react'

const src = "https://open.spotify.com/embed/show/3QNVjeNFgYlot4986f0XWJ?theme=0"

const PodcastPlayer = (): JSX.Element => {
  return (
    <iframe
      src={src}
      width="100%"
      height="152"
      frameBorder="0"
      allow="encrypted-media"
      style={{borderRadius: 12}}
    />
  )
}

export default PodcastPlayer
