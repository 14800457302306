import React from 'react'
import {
  Theme,
  useMediaQuery,
} from '@mui/material'
import {Place} from 'data/types'
import DesktopLocation from './Desktop'
import MobileLocation from './Mobile'

interface PlaceLocationProps {
  place?: Place,
}

const PlaceLocation = ({place}: PlaceLocationProps): JSX.Element | null => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (!place) return null

  if (isMobile) return <MobileLocation place={place}/>

  return <DesktopLocation place={place}/>
}

export default PlaceLocation
