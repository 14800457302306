import React from 'react'
import {
  Box,
  IconButton,
  Link as MuiLink,
} from '@mui/material'
import {INSTAGRAM_URL} from 'utils/regex'
import type {Artist} from 'data/types'
import Icon from 'ui/LinkButtons/Icon'
import InstagramHandle from './InstagramHandle'

interface ArtistLinksProps {
  artist: Artist,
}

const ArtistLinks = ({artist}: ArtistLinksProps): JSX.Element | null => {
  const {links} = artist

  if (!links) return null

  const instagramLinks = links.filter(l => l.url.match(INSTAGRAM_URL))

  return (
    <Box display="grid" gridAutoFlow="column" gap={1}>
      {instagramLinks.map((link) => {
        return <InstagramHandle key={link.url} url={link.url}/>
      })}

      <Box display="grid" gridAutoFlow="column" gap={1}>
        {links.map((link) => {
          if (link.url.match(INSTAGRAM_URL)) return null

          return (
            <Box key={link.id}>
              <IconButton
                component={MuiLink}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon link={link}/>
              </IconButton>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default ArtistLinks
