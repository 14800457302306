import React from 'react'
import {Helmet} from 'react-helmet-async'
import {useParams} from 'react-router-dom'
import {LocationParams} from 'types/location'
import {useFetchArtistById} from 'artists/hooks'
import ArtistDetails from 'artists/components/Details'

const ArtistDetailsView = (): JSX.Element | null => {
  const {id} = useParams<LocationParams>()
  const {fetchArtistById, artist} = useFetchArtistById()

  if (id) fetchArtistById(id)

  if (!artist) return null

  const title = "Hapnyn - " + artist.name

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <ArtistDetails artist={artist}/>
    </>
  )
}

export default ArtistDetailsView
