import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import EventIcon from '@mui/icons-material/Event'
import {useRoles} from 'auth/hooks'
import {Artist} from 'data/types'
import {ctrlOs} from 'utils'
import TooltipLabel from 'ui/TooltipLabel'

type ActionsDialProps = {
  artist: Artist,
}

const ActionsDial = ({artist}: ActionsDialProps): JSX.Element | null => {
  const {isContentManager} = useRoles()
  const navigate = useNavigate()
  const location = useLocation()

  if (!isContentManager) return null

  return (
    <SpeedDial
      ariaLabel="Artist Actions"
      sx={{position: 'fixed', bottom: 16, right: 16}}
      icon={<SpeedDialIcon/>}
    >
      <SpeedDialAction
        icon={<EventIcon/>}
        tooltipTitle={<TooltipLabel title="Add Event" shortcut={`${ctrlOs()} + Enter`}/>}
        onClick={() => navigate(`/events/new?artistId=${artist.id}`, {state: {from: location}})}
      />
      <SpeedDialAction
        icon={<EditIcon/>}
        tooltipTitle={<TooltipLabel title="Edit Artist"/>}
        onClick={() => navigate(`/artists/edit/${artist.id}`, {state: {from: location}})}
      />
    </SpeedDial>
  )
}

export default ActionsDial
