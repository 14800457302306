import {gql} from '@apollo/client'

export const CORE_USER_FIELDS = gql `
  fragment CoreUserFields on User {
    id
    createdAt
    updatedAt

    name

    primaryEmail {
      address
    }

    primaryPhone {
      number
    }

    emails {
      address
    }

    phones {
      number
    }

    roles {
      id
      name
    }
  }
`
