import React from 'react'
import {
  Typography,
} from '@mui/material'
import {useMatch} from 'react-router-dom'

const Region = (): JSX.Element => {
  const admin = useMatch("admin/*")

  const region = admin ? "admin" : "san diego"

  return (
    <Typography>{region}</Typography>
  )
}

export default Region
