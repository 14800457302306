import {useCallback} from 'react'
import {gql, QueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {COMPLETE_EVENT_FIELDS} from 'data/fragments/event'
import {Event} from 'data/types'

const FETCH_EVENT_BY_ID_QUERY = gql `
  ${COMPLETE_EVENT_FIELDS}

  query FetchEventById($id: ID!) {
    events(
      where: {id: $id}
    ) {
      ...CompleteEventFields
    }
  }
`

interface FetchEventByIdQueryVars {
  id: string,
}

interface FetchEventByIdQueryData {
  events: Event[],
}

interface UseFetchEventByIdResult {
  fetchEventById: (id: string) => void,
  event?: Event,
  result: QueryResult<FetchEventByIdQueryData, FetchEventByIdQueryVars>,
}

type FetchEventByIdOptions = QueryHookOptions<FetchEventByIdQueryData, FetchEventByIdQueryVars>

export const useFetchEventById = (options?: FetchEventByIdOptions): UseFetchEventByIdResult => {
  const [query, result] = useLazyQuery<FetchEventByIdQueryData, FetchEventByIdQueryVars>(FETCH_EVENT_BY_ID_QUERY, options)

  const event = result.data?.events?.[0]

  const fetchEventById = useCallback((id: string) => {
    if (!result.called || (event && event.id !== id)) query({variables: {id}})
  }, [result.called, event, query])

  return {
    fetchEventById,
    event,
    result,
  }
}
