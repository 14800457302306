import React, {useEffect} from 'react'
import {useImmer} from 'use-immer'
import {DateTime, Interval} from 'luxon'
import {
  Alert,
  Box,
  SxProps,
  Theme,
} from '@mui/material'
import DateSelector from './DateSelector'
import TimeSelector from './TimeSelector'
import DurationSelector from './DurationSelector'

const pickerSx: SxProps<Theme> = (theme) => ({
  display: 'grid',
  gap: 2,

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: `2fr 1fr 1fr 1fr 2fr`,
    columnGap: 1,
  },
})

interface WhenPickerProps {
  start?: Date,
  end?: Date,
  onUpdate: (startTime: DateTime, endTime: DateTime) => void
  autoFocus?: boolean,
}

// utility function used to convert JS Dates to Luxon DateTimes
const convertDateToDateTime = (date?: Date) => date ? DateTime.fromJSDate(date) : undefined

const WhenPicker = ({start, end, onUpdate, autoFocus=false}: WhenPickerProps) => {
  const [internalStartTime, setInternalStartTime] = useImmer(convertDateToDateTime(start))
  const [internalEndTime, setInternalEndTime] = useImmer(convertDateToDateTime(end))

  useEffect(() => {
    if (start) setInternalStartTime(convertDateToDateTime(start))
    if (end) setInternalEndTime(convertDateToDateTime(end))
  }, [start, end, setInternalStartTime, setInternalEndTime])

  // check for endless re-renders
  // console.log({internalStartTime, internalEndTime, start, end})

  useEffect(() => {
    if (internalStartTime && internalEndTime) {
      const prevStartTime = convertDateToDateTime(start)
      const prevEndTime = convertDateToDateTime(end)

      const startChanged = !prevStartTime || !internalStartTime.equals(prevStartTime)
      const endChanged = !prevEndTime || !internalEndTime.equals(prevEndTime)

      if (startChanged || endChanged) onUpdate(internalStartTime, internalEndTime)
    }
  }, [start, end, internalStartTime, internalEndTime, onUpdate])

  let duration
  if (internalStartTime && internalEndTime) {
    duration = Interval.fromDateTimes(internalStartTime, internalEndTime).toDuration('minutes').minutes
  }

  let warning
  if (duration && duration > 1440) warning = "The duration is longer than 24 hours"

  let endDateError
  if (internalStartTime && internalEndTime && internalEndTime <= internalStartTime) endDateError = "End time cannot be before start time"

  const handleChangeStartDate = (date?: DateTime) => {
    if (date) {
      if (internalStartTime) {
        // calculate difference
        const oldStartDate = internalStartTime.startOf('day')
        const newStartDate = date.startOf('day')
        const diff = newStartDate.diff(oldStartDate, 'days')

        // shift start & end dates by difference
        setInternalStartTime(internalStartTime.plus(diff))
        setInternalEndTime(internalEndTime?.plus(diff))
      } else {
        // initialize to 7pm with 3 hour duration
        const newStartTime = date.startOf('day').plus({hours: 19})
        setInternalStartTime(newStartTime)
        setInternalEndTime(newStartTime.plus({minutes: 180}))
      }
    } else {
      if (!internalStartTime) {
        // initialize to today at 7pm with 3 hour duration
        const newStartTime = DateTime.now().startOf('day').plus({hours: 19})
        setInternalStartTime(newStartTime)
        setInternalEndTime(newStartTime.plus({minutes: 180}))
      }
    }
  }

  const handleChangeStartTime = (time?: DateTime) => {
    if (time) {
      if (internalStartTime) {
        // calculate difference
        const diff = time.diff(internalStartTime, 'minutes')

        // shift end time by difference
        setInternalEndTime(internalEndTime?.plus(diff))
      }

      // set new start time
      setInternalStartTime(time)
    }
  }

  const handleChangeDuration = (duration: number) => {
    if (internalStartTime) setInternalEndTime(internalStartTime.plus({minutes: duration}))
  }

  const handleChangeEndTime = (time?: DateTime) => {
    if (time && internalStartTime) {
      time > internalStartTime
        ? setInternalEndTime(time)
        : setInternalEndTime(time.plus({day: 1}))
    }
  }

  const handleChangeEndDate = (date?: DateTime) => {
    if (date) {
      if (internalStartTime && internalStartTime >= date) {
        date = date.set({
          year: internalStartTime.year,
          month: internalStartTime.month,
          day: internalStartTime.day,
        })
      }

      if (internalStartTime && internalStartTime >= date) {
        date = date.set({
          day: internalStartTime.day + 1,
        })
      }

      setInternalEndTime(date)
    }
  }

  return (
    <Box>
      <Box sx={pickerSx}>
        <DateSelector date={internalStartTime} onChange={handleChangeStartDate} label="Start Date" autoFocus={autoFocus}/>
        <TimeSelector time={internalStartTime} onChange={handleChangeStartTime} label="Start Time"/>
        <DurationSelector durationInMinutes={duration} onChange={handleChangeDuration}/>
        <TimeSelector time={internalEndTime} onChange={handleChangeEndTime} label="End Time"/>
        <DateSelector
          date={internalEndTime}
          minDate={internalStartTime}
          onChange={handleChangeEndDate}
          label="End Date"
          error={endDateError}
        />
      </Box>
      {warning && <Alert severity="warning" sx={{mt: 1}}>{warning}</Alert>}
    </Box>
  )
}

export default WhenPicker
