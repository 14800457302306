import {gql} from '@apollo/client'
import {CORE_ADDRESS_FIELDS} from './address'
import {CONTACT_METHOD_FIELDS} from './contactMethod'
import {CORE_IMAGE_FIELDS} from './image'
import {CORE_LINK_FIELDS} from './link'
import {CORE_TEXT_FIELDS} from './text'
// import {CORE_USER_FIELDS} from './user'

export const CORE_PLACE_FIELDS = gql `
  ${CORE_ADDRESS_FIELDS}
  ${CORE_IMAGE_FIELDS}
  ${CORE_TEXT_FIELDS}
  ${CORE_LINK_FIELDS}

  fragment CorePlaceFields on Place {
    id
    createdAt
    updatedAt

    googlePlaceId
    name

    location {
      latitude
      longitude
    }

    description {
      ...CoreTextFields
    }

    physicalAddress {
      ...CoreAddressFields
    }

    primaryPhone {
      number
    }

    primaryHandle {
      name
    }

    primaryImage {
      ...CoreImageFields
    }

    links {
      ...CoreLinkFields
    }
  }
`

export const COMPLETE_PLACE_FIELDS = gql `
  ${CORE_PLACE_FIELDS}
  ${CONTACT_METHOD_FIELDS}
  ${/* CORE_USER_FIELDS */ ""}

  fragment CompletePlaceFields on Place {
    ...CorePlaceFields

    addresses {
      ...CoreAddressFields
    }

    handles {
      name
    }

    contactMethods {
      ...ContactMethodFields
    }

    images {
      ...CoreImageFields
    }

    # managers {
    #   ...CoreUserFields
    # }
  }
`
