import {gql} from '@apollo/client'

export const CONTACT_METHOD_FIELDS = gql `
  fragment ContactMethodFields on ContactMethod {
    ... on Email {
      address
    }

    ... on Phone {
      number
    }
  }
`
