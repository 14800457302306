import {useCallback} from 'react'
import {gql, LazyQueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_PLACE_FIELDS} from 'data/fragments/place'
import {Place} from 'data/types'
import {generateSearchString} from 'utils'

const FETCH_PLACE_BY_NAME_QUERY = gql `
  ${CORE_PLACE_FIELDS}

  query FetchPlaceByName($phrase: String!) {
    placesFulltextPlaceName(phrase: $phrase)
    {
      place {
        ...CorePlaceFields
      }
    }
  }
`

interface FetchPlaceByNameQueryVars {
  phrase: string,
}

type PlaceFulltextResult = {
  place: Place,
  score: number,
}

interface FetchPlaceByNameQueryData {
  placesFulltextPlaceName: PlaceFulltextResult[]
}

interface UseFetchPlaceByNameResult {
  fetchPlaceByName: (phrase: string) => void,
  places?: Place[],
  result: QueryResult<FetchPlaceByNameQueryData, FetchPlaceByNameQueryVars>,
}

type FetchPlaceByNameOptions = LazyQueryHookOptions<FetchPlaceByNameQueryData, FetchPlaceByNameQueryVars>

export const useFetchPlaceByName = (options?: FetchPlaceByNameOptions): UseFetchPlaceByNameResult => {
  const [query, result] = useLazyQuery<FetchPlaceByNameQueryData, FetchPlaceByNameQueryVars>(FETCH_PLACE_BY_NAME_QUERY, options)

  const fetchPlaceByName = useCallback((name: string) => {
    const phrase = generateSearchString(name)

    query({variables: {phrase}})
  }, [query])

  const places = result.data?.placesFulltextPlaceName.map(p => p.place)

  return {
    fetchPlaceByName,
    places,
    result,
  }
}
