import React, {useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Collapse,
  IconButton,
  SxProps,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import type {Place} from 'data/types'
import Details from './Details'
import {addressToString} from 'places/utils'
import {getPrimaryUrl} from 'utils/urls'

const upperRowSx: SxProps = {
  '& td, & td': {
    borderBottom: 'unset',
  },

  '&:last-child th, &:last-child td': {
    border: 0,
  },
}

const lowerRowSx: SxProps = {
  '& td, & td': {
    borderTop: 'unset',
  },

  '&:last-child th, &:last-child td': {
    border: 0,
  },
}

interface PlaceRowProps {
  place: Place,
}

const PlaceRow = ({place}: PlaceRowProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false)

  const addressString = place.physicalAddress ? addressToString(place.physicalAddress) : ""

  const url = getPrimaryUrl(place)

  const toggleExpanded = () => setExpanded(!expanded)

  return (
    <>
      <TableRow hover={!expanded} sx={upperRowSx} onClick={toggleExpanded}>
        <TableCell>
          <Typography>{place.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{addressString}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography>{place.eventsUpcoming?.count} / {place.eventsTotal?.count}</Typography>
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton component={RouterLink} to={url} target="_blank" rel="noopener noreferrer">
            <OpenInNewIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={lowerRowSx}>
        <TableCell colSpan={4} sx={{py: 0}}>
          <Collapse in={expanded} mountOnEnter unmountOnExit>
            <Details place={place}/>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default PlaceRow
