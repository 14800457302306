import React from 'react'
import {useParams} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import {
  Box,
  CircularProgress,
} from '@mui/material'
import {LocationParams} from 'types/location'
import {useFetchDataByHandle} from 'handles/hooks'
import ArtistDetails from 'artists/components/Details'
import EventDetails from 'events/components/Details'
import PlaceDetails from 'places/components/Details'
import {getEventName} from 'events/utils'

const HandleDetails = (): JSX.Element | null => {
  const {handle} = useParams<LocationParams>()
  const {fetchDataByHandle, artist, event, place, result} = useFetchDataByHandle()

  if (handle) fetchDataByHandle(handle)

  if (result.loading) return (
    <Box display="grid" justifyContent="center" alignContent="center" height="80vh">
      <CircularProgress/>
    </Box>
  )

  let title = "Hapnyn"
  let component = null

  if (artist) {
    title += " - " + artist.name
    component = <ArtistDetails artist={artist}/>
  } else if (event) {
    title += " - " + getEventName(event) + " at " + event.location.name
    component = <EventDetails event={event}/>
  } else if (place) {
    title += " - " + place.name
    component = <PlaceDetails place={place}/>
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {component}
    </>
  )
}

export default HandleDetails
