import {gql, QueryHookOptions, QueryResult, useQuery} from '@apollo/client'

const USERS_COUNT_QUERY = gql `
  query UsersCount{
    usersAggregate {
      count
    }
  }
`

interface UsersCountData {
  usersAggregate: {
    count: number,
  },
}

interface UsersCountQueryResult {
  count?: number,
  result: QueryResult<UsersCountData>,
}

type UsersCountOptions = QueryHookOptions<UsersCountData>

export const useUsersCount = (options?: UsersCountOptions): UsersCountQueryResult => {
  const result = useQuery<UsersCountData>(USERS_COUNT_QUERY, {
    fetchPolicy: 'network-only',
    ...options,
  })

  const count = result.data?.usersAggregate.count

  return {
    count,
    result,
  }
}
