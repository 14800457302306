import React, {useEffect, useState} from 'react'
import {DateTime} from 'luxon'
import {Searcher} from 'fast-fuzzy'
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import type {Event} from 'data/types'
import {useDate, useFetchEventsByDateRange} from 'events/hooks'
import {getRelativeDateString} from 'utils'
import EventCard from './Card'
import DateSelectorButton from 'events/components/DateSelectorButton'
import EventDetailsDialog from 'events/components/DetailsDialog'
import {filterStatusEvents, getEventName, sortEvents} from 'events/utils'

const titleSx: SxProps = {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  columnGap: 4,
  alignItems: 'center',
  backgroundColor: 'info.main',
  px: 2,
  py: 1,
  mb: 2,
  borderRadius: 1,
}

const searchWrapperSx: SxProps<Theme> = {
  display: 'flex',
  borderRadius: 1,
  pl: 1.5,
  pt: 1,
  mb: 1,
}

const emptySx: SxProps<Theme> = {
  p: 2,
  backgroundColor: theme => theme.palette.background.paper,
  borderRadius: 1,
}

const getKeySelectorArray = (event: Event): string[] => {
  const keySelectors: string[] = []

  const eventName = getEventName(event)
  if (eventName) keySelectors.push(eventName)

  keySelectors.push(event.location.name)

  event.artists?.forEach(a => keySelectors.push(a.name))

  const neighborhood = event.location.physicalAddress?.neighborhood
  if (neighborhood) keySelectors.push(neighborhood)

  const locality = event.location.physicalAddress?.locality
  if (locality) keySelectors.push(locality)

  return keySelectors
}

const EventsList = (): JSX.Element => {
  const [date] = useDate()
  const {fetchEventsByDateRange, events: fetchedEvents, result} = useFetchEventsByDateRange({fetchPolicy: 'cache-and-network'})
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [input, setInput] = useState("")

  useEffect(() => {
    fetchEventsByDateRange(date.toJSDate())
  }, [date, fetchEventsByDateRange])

  useEffect(() => {
    result.startPolling(60000)

    return () => result.stopPolling()
  })

  let sortedEvents: Event[] = []
  let sortedSearcherEvents: Event[] = []

  // TODO: handle events that are longer than 24 hours

  // const dateInterval = Interval.fromDateTimes(date.startOf('day'), date.endOf('day'))

  if (fetchedEvents) {
    const filteredEvents = fetchedEvents.filter((event) => {
      const startTime = DateTime.fromJSDate(event.startTime)
      // const endTime = event.endTime ? DateTime.fromJSDate(event.endTime) : startTime.plus({hours: 3})
      // const eventInterval = Interval.fromDateTimes(startTime, endTime)

      return startTime.hasSame(date, 'day')
      // return dateInterval.overlaps(eventInterval)
    })

    const filteredStatusEvents = filterStatusEvents(filteredEvents)

    sortedEvents = [...filteredStatusEvents].sort((a, b) => sortEvents(a, b))

    const searcher = new Searcher(filteredStatusEvents, {keySelector: e => getKeySelectorArray(e)})

    const searcherEvents = searcher.search(input, {threshold: 0.8})

    sortedSearcherEvents = [...searcherEvents].sort((a, b) => sortEvents(a, b))
  }

  const events = input === "" ? sortedEvents : sortedSearcherEvents

  const dateString = getRelativeDateString(date)

  const clearInput = () => setInput("")

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        clearInput()
        break
    }
  }

  const hasEvents = !(events.length === 0 && !result.loading)

  return (
    <Box>
      <EventDetailsDialog/>

      <Box sx={titleSx}>
        <Typography variant="h2" fontSize={24}>Hapnyn Events for {dateString}</Typography>
        {isMobile && <DateSelectorButton/>}
      </Box>

      <Box sx={searchWrapperSx} border={2} borderColor='primary.main'>
        <InputBase
          value={input}
          onChange={event => setInput(event.target.value)}
          onKeyDown={handleKeyDown}
          autoComplete='off'
          placeholder='Search'
          autoFocus
          fullWidth
          sx={{mb: 1}}
        />
        <IconButton
          sx={{mb: 1}}
          onClick={clearInput}
          disabled={!input}
        >
          <CancelIcon/>
        </IconButton>
      </Box>

      {events.map((event) => {
        return (
          <EventCard key={event.id} event={event} listDate={date}/>
        )
      })}
      {!hasEvents &&
        <Box sx={emptySx}>
          <Typography fontSize={18}>There are no events listed.</Typography>
        </Box>
      }
      {events.length === 0 && result.loading &&
        <Box textAlign="center" sx={emptySx}>
          <CircularProgress/>
        </Box>
      }
    </Box>
  )
}

export default EventsList
