import React, {useState} from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import type {Handle} from 'data/types'
import {useShowDialogs} from 'router/hooks'

export const NEW_HANDLE_DIALOG_NAME = "NewHandleDialog"

// eslint-disable-next-line no-useless-escape
const PATTERN = /[a-z0-9\.\-_~]+/g

interface NewHandleDialogProps {
  addHandle: (handle: Handle) => void,
}

const NewHandleDialog = ({addHandle}: NewHandleDialogProps): JSX.Element => {
  const {isDialogOpen, closeDialog} = useShowDialogs()
  const [name, setName] = useState("")

  const isValid = name.length > 0

  const handleAddButton = () => {
    addHandle({name})
    setName("")
    closeDialog(NEW_HANDLE_DIALOG_NAME)
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let {value} = event.target

    // filter out unsafe characters
    value = value.toLowerCase()
    value = value.match(PATTERN)?.join('') || ''

    setName(value)
  }

  return (
    <Dialog
      open={isDialogOpen(NEW_HANDLE_DIALOG_NAME)}
      onClose={() => closeDialog(NEW_HANDLE_DIALOG_NAME)}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>New Handle</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Handle"
          value={name}
          onChange={handleChange}
          margin="dense"
          fullWidth
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog(NEW_HANDLE_DIALOG_NAME)}>Cancel</Button>
        <Button disabled={!isValid} variant="contained" disableElevation onClick={handleAddButton}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewHandleDialog
