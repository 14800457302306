import React from 'react'
import {
  Box,
  Typography,
} from '@mui/material'
import type {Place} from 'data/types'
import ActionsDial from 'places/components/ActionsDial'
import Description from 'places/components/Description'
import PlaceLinks from 'places/components/Links'
import PlaceEventsList from 'places/components/EventsList'
import PlaceLocation from 'places/components/Location'

interface MobileDetailsProps {
  place: Place,
}

const MobileDetails = ({place}: MobileDetailsProps): JSX.Element => {
  const {name, description, links} = place

  return (
    <Box display="grid" gap={1} sx={{m: 2}}>
      <ActionsDial place={place}/>

      <Box mb={1}>
        <Typography
          variant="h2"
          my={2}
          textAlign="center"
          color="primary.main"
        >
          {name}
        </Typography>
      </Box>

      <Description description={description}/>
      <PlaceLocation place={place}/>
      <PlaceEventsList place={place} my={1}/>
      <PlaceLinks links={links}/>
    </Box>
  )
}

export default MobileDetails
