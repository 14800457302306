import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  Container,
} from '@mui/material'
import NewArtistButton from 'artists/components/NewButton'
import List from 'artists/components/List'
import {useRoles} from 'auth/hooks'
import {useHotkeys} from 'react-hotkeys-hook'

const ArtistsList = (): JSX.Element | null => {
  const {isContentManager} = useRoles()
  const location = useLocation()
  const navigate = useNavigate()

  useHotkeys('ctrl+Enter, meta+Enter', () => newArtist(),
    {enableOnFormTags: true, preventDefault: true})

  const newArtist = () => {
    if (isContentManager) navigate('/artists/new', {state: {from: location}})
  }

  return (
    <Container maxWidth="lg" sx={{my: 2, px: {xs: 1, md: 2}}}>
      <NewArtistButton/>
      <List/>
    </Container>
  )
}

export default ArtistsList
