import React from 'react'
import {Helmet} from 'react-helmet-async'
import {
  Theme,
  useMediaQuery,
} from '@mui/material'
import MobileDashboard from './Mobile'
import DesktopDashboard from './Desktop'

const EventsDashboard = (): JSX.Element => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const view = isMobile ? <MobileDashboard/> : <DesktopDashboard/>

  return (
    <>
      <Helmet><title>Hapnyn - Events</title></Helmet>

      {view}
    </>
  )
}

export default EventsDashboard
