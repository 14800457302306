import React from 'react'
import {Link as RouterLink, useMatch} from 'react-router-dom'
import {
  SxProps,
  Tab,
  Tabs,
} from '@mui/material'
import {useRoles} from 'auth/hooks'

const tabSx: SxProps = {
  textTransform: 'none',
  fontSize: 18,
  fontWeight: 300,
}

const values = ['artists', 'places', 'admin']

const DesktopNav = (): JSX.Element => {
  const match = useMatch('/:resource/*')
  const resource = match?.params.resource
  const {isAdmin, isContentManager} = useRoles()

  const value = (resource && values.includes(resource)) ? resource : false

  return (
    <Tabs value={value} textColor="inherit" indicatorColor="secondary">
      <Tab label="Artists" value="artists" sx={tabSx} component={RouterLink} to="/artists"/>
      <Tab label="Places" value="places" sx={tabSx} component={RouterLink} to="/places"/>

      {(isAdmin || isContentManager) &&
        <Tab label="Admin" value="admin" sx={tabSx} component={RouterLink} to="/admin"/>
      }
    </Tabs>
  )
}

export default DesktopNav
