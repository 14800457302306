import React from 'react'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {LocationState} from 'types/location'
import SignInView from 'auth/SignInView'
import SignUpView from 'auth/SignUpView'
import HapnynLayout from 'hapnyn/Layout'
import EventsDashboard from 'events/views/Dashboard'
import EventNewView from 'events/views/New'
import EventEditView from 'events/views/Edit'
import EventDetailView from 'events/views/Details'
import ArtistNewView from 'artists/views/New'
import ArtistEditView from 'artists/views/Edit'
import ArtistListView from 'artists/views/List'
import ArtistDetailView from 'artists/views/Details'
import PlaceNewView from 'places/views/New'
import PlaceEditView from 'places/views/Edit'
import PlaceListView from 'places/views/List'
import PlaceDetailView from 'places/views/Details'
import AdminWrapper from 'admin/components/Wrapper'
import AdminDashboard from 'admin/views/Dashboard'
import EventsAdminPanel from 'events/components/AdminPanel'
import PlacesAdminPanel from 'places/components/AdminPanel'
import UsersAdminPanel from 'admin/components/UsersPanel'
import HandleDetails from 'handles/views/Details'

const HapnynRoutes = (): JSX.Element => {
  const location = useLocation()

  const state = location.state as LocationState
  const backgroundLocation = state?.backgroundLocation

  let routerLocation = location

  if (backgroundLocation) {
    routerLocation = {
      ...backgroundLocation,
      state: {
        ...backgroundLocation.state,
        foregroundLocation: location,
      },
    }
  }

  return (
    <Routes location={routerLocation}>
      <Route path="signin" element={<SignInView/>}/>
      <Route path="signup" element={<SignUpView/>}/>
      <Route path="/" element={<HapnynLayout/>}>
        <Route index element={<EventsDashboard/>}/>
        <Route path="@:handle" element={<HandleDetails/>}/>
        <Route path="artists">
          <Route index element={<ArtistListView/>}/>
          <Route path=":id" element={<ArtistDetailView/>}/>
          <Route path="new" element={<ArtistNewView/>}/>
          <Route path="edit/:id" element={<ArtistEditView/>}/>
        </Route>
        <Route path="events">
          <Route index element={<Navigate to="/"/>}/>
          <Route path=":id" element={<EventDetailView/>}/>
          <Route path="new" element={<EventNewView/>}/>
          <Route path="edit/:id" element={<EventEditView/>}/>
        </Route>
        <Route path="places">
          <Route index element={<PlaceListView/>}/>
          <Route path=":id" element={<PlaceDetailView/>}/>
          <Route path="new" element={<PlaceNewView/>}/>
          <Route path="edit/:id" element={<PlaceEditView/>}/>
        </Route>
        <Route path="admin" element={<AdminWrapper/>}>
          <Route index element={<AdminDashboard/>}/>
          <Route path="events" element={<EventsAdminPanel/>}/>
          <Route path="places" element={<PlacesAdminPanel/>}/>
          <Route path="users" element={<UsersAdminPanel/>}/>
        </Route>
      </Route>
    </Routes>
  )
}

export default HapnynRoutes
