import React from 'react'
import CounterCard from './CounterCard'
import {usePlacesCount} from 'places/hooks'

const PlacesCount = (): JSX.Element => {
  const {count, result: {loading, error}} = usePlacesCount()

  return (
    <CounterCard
      label="Places Count"
      count={count}
      loading={loading}
      error={error?.message}
      path="places"
    />
  )
}

export default PlacesCount
