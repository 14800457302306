import React from 'react'
import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import {sortByName} from 'utils'
import type {Artist} from 'data/types'
import FAIcon from 'ui/FAIcons'
import {faClapperboard} from '@fortawesome/free-solid-svg-icons'
import ArtistDetails from 'events/components/ArtistDetails'

const sectionSx: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: theme => `${theme.spacing(5)} auto`,
}

const iconSx: SxProps<Theme> = {
  color: theme => theme.palette.text.disabled,
}

const headerSx: SxProps<Theme> = {
  fontSize: 24,
  lineHeight: 1,
  fontWeight: 300,
}

interface EventPromotersProps {
  artists?: Artist[],
  header?: string,
}

const EventPromoters = ({artists, header}: EventPromotersProps): JSX.Element | null => {
  if (!artists || artists.length === 0) return null

  const sortedPromoters = [...artists].sort((a, b) => sortByName(a.name, b.name))

  return (
    <Box>
      <Box sx={sectionSx}>
        <Box sx={iconSx}>
          <FAIcon icon={faClapperboard}/>
        </Box>
        <Box display="grid" rowGap={2}>
          {header && <Typography sx={headerSx}>{header}</Typography>}
          {sortedPromoters.map(a => <ArtistDetails key={a.id} artist={a}/>)}
        </Box>
      </Box>
    </Box>
  )
}

export default EventPromoters
