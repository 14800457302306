import {useCallback} from 'react'
import {gql, LazyQueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_ARTIST_FIELDS} from 'data/fragments/artist'
import {Artist} from 'data/types'
import {generateSearchString} from 'utils'

const FETCH_ARTIST_BY_NAME_QUERY = gql `
  ${CORE_ARTIST_FIELDS}

  query FetchArtistByName($phrase: String!) {
    artistsFulltextArtistName(phrase: $phrase)
    {
      artist {
        ...CoreArtistFields
      }
    }
  }
`

interface FetchArtistByNameQueryVars {
  phrase: string,
}

type ArtistFulltextResult = {
  artist: Artist,
  score: number,
}

interface FetchArtistByNameQueryData {
  artistsFulltextArtistName: ArtistFulltextResult[]
}

interface UseFetchArtistByNameResult {
  fetchArtistByName: (phrase: string) => void,
  artists?: Artist[],
  result: QueryResult<FetchArtistByNameQueryData, FetchArtistByNameQueryVars>
}

type FetchArtistByNameOptions = LazyQueryHookOptions<FetchArtistByNameQueryData, FetchArtistByNameQueryVars>

export const useFetchArtistByName = (options?: FetchArtistByNameOptions): UseFetchArtistByNameResult => {
  const [query, result] = useLazyQuery<FetchArtistByNameQueryData, FetchArtistByNameQueryVars>(FETCH_ARTIST_BY_NAME_QUERY, options)

  const fetchArtistByName = useCallback((name: string) => {
    const phrase = generateSearchString(name)

    query({variables: {phrase}})
  }, [query])

  const artists = result.data?.artistsFulltextArtistName.map(a => a.artist)

  return {
    fetchArtistByName,
    artists,
    result,
  }
}
