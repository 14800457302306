import React from 'react'
import CounterCard from './CounterCard'
import {useEventsCount} from 'events/hooks'

const EventsCount = (): JSX.Element => {
  const {count, result: {loading, error}} = useEventsCount()

  return (
    <CounterCard
      label="Events Count"
      count={count}
      loading={loading}
      error={error?.message}
      path="events"
    />
  )
}

export default EventsCount
