import {useCallback} from 'react'
import {gql, QueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {COMPLETE_PLACE_FIELDS} from 'data/fragments/place'
import {Place} from 'data/types'

const FETCH_PLACE_BY_ID_QUERY = gql `
  ${COMPLETE_PLACE_FIELDS}

  query FetchPlaceById($id: ID!) {
    places(
      where: {id: $id}
    ) {
      ...CompletePlaceFields
    }
  }
`

interface FetchPlaceByIdQueryVars {
  id: string,
}

interface FetchPlaceByIdQueryData {
  places: Place[],
}

interface UseFetchPlaceByIdResult {
  fetchPlaceById: (id: string) => void,
  place?: Place,
  result: QueryResult<FetchPlaceByIdQueryData, FetchPlaceByIdQueryVars>,
}

type FetchPlaceByIdOptions = QueryHookOptions<FetchPlaceByIdQueryData, FetchPlaceByIdQueryVars>

export const useFetchPlaceById = (options?: FetchPlaceByIdOptions): UseFetchPlaceByIdResult => {
  const [query, result] = useLazyQuery<FetchPlaceByIdQueryData, FetchPlaceByIdQueryVars>(FETCH_PLACE_BY_ID_QUERY, options)

  const place = result.data?.places[0]

  const fetchPlaceById = useCallback((id: string) => {
    if (!result.called || (place && place.id !== id)) query({variables: {id}})
  }, [result.called, place, query])

  return {
    fetchPlaceById,
    place,
    result,
  }
}
