import React from 'react'
import {ThemeProvider} from '@mui/material/styles'
import {
  CssBaseline,
} from '@mui/material'
import theme from './theme'

type ThemeProviderProps = {
  children: React.ReactNode,
}

const HapnynThemeProvider = ({children}: ThemeProviderProps): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      {children}
    </ThemeProvider>
  )
}

export default HapnynThemeProvider
