import {gql} from '@apollo/client'
import {CORE_ARTIST_FIELDS} from './artist'
import {CONTACT_METHOD_FIELDS} from './contactMethod'
import {CORE_IMAGE_FIELDS} from './image'
import {CORE_LINK_FIELDS} from './link'
import {CORE_PLACE_FIELDS} from './place'
import {CORE_TEXT_FIELDS} from './text'
// import {CORE_USER_FIELDS} from './user'

export const CORE_EVENT_FIELDS = gql `
  ${CORE_ARTIST_FIELDS}
  ${CORE_IMAGE_FIELDS}
  ${CORE_PLACE_FIELDS}
  ${CORE_TEXT_FIELDS}

  fragment CoreEventFields on Event {
    id
    createdAt
    updatedAt

    startTime
    endTime
    title

    location {
      ...CorePlaceFields
    }

    artists {
      ...CoreArtistFields
    }

    sponsorArtists {
      ...CoreArtistFields
    }

    promotedArtists {
      ...CoreArtistFields
    }

    description {
      ...CoreTextFields
    }

    primaryImage {
      ...CoreImageFields
    }
  }
`

export const COMPLETE_EVENT_FIELDS = gql `
  ${CORE_EVENT_FIELDS}
  ${CONTACT_METHOD_FIELDS}
  ${CORE_LINK_FIELDS}
  ${/* CORE_USER_FIELDS */ ""}

  fragment CompleteEventFields on Event {
    ...CoreEventFields

    contactMethods {
      ...ContactMethodFields
    }

    images {
      ...CoreImageFields
    }

    links {
      ...CoreLinkFields
    }

    # managers {
    #   ...CoreUserFields
    # }
  }
`
