import React from 'react'
import {
  Container,
  SxProps,
} from '@mui/material'
import HapnynThemeProvider from 'hapnyn/ThemeProvider'

const containerSx: SxProps = {
  height: '100vh',
}

type AuthLayoutProps = {
  children: React.ReactNode,
}

const AuthLayout = ({children}: AuthLayoutProps): JSX.Element => {
  return (
    <HapnynThemeProvider>
      <Container maxWidth="lg" sx={containerSx}>
        {children}
      </Container>
    </HapnynThemeProvider>
  )
}

export default AuthLayout
