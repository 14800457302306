import React from 'react'
import {ApolloProvider} from '@apollo/client'
import {BrowserRouter} from 'react-router-dom'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import {LocalizationProvider} from '@mui/lab'
import client from 'data/apollo/client'
import Routes from 'hapnyn/Routes'
import HapnynThemeProvider from 'hapnyn/ThemeProvider'
import {HelmetProvider} from 'react-helmet-async'

const App = (): JSX.Element => {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <BrowserRouter>
            <ApolloProvider client={client}>
              <HapnynThemeProvider>
                <Routes/>
              </HapnynThemeProvider>
            </ApolloProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </HelmetProvider>
    </React.StrictMode>
  )
}

export default App
