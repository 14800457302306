import React, {useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Box,
  Button,
  Collapse,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import LocationIcon from '@mui/icons-material/LocationOn'
import type {Event} from 'data/types'
import ExpandIcon from 'ui/ExpandIcon'
import {addressToString} from 'places/utils'
import GoogleMap from 'google/components/Map'
import {getPrimaryUrl} from 'utils/urls'

const sectionSx: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: theme => `${theme.spacing(5)} auto`,
}

const iconSx: SxProps<Theme> = {
  color: theme => theme.palette.text.disabled,
  fontSize: 28,
}

const contactSx: SxProps<Theme> = {
  fontSize: 18,
  fontWeight: 300,
}

interface MobileEventLocationProps {
  event: Event,
}

const MobileEventLocation = ({event}: MobileEventLocationProps): JSX.Element => {
  const [expand, setExpand] = useState(false)

  const {name, googlePlaceId, physicalAddress, primaryPhone} = event.location

  const placeUrl = getPrimaryUrl(event.location)

  const toggleExpand = () => setExpand(!expand)

  return (
    <Box sx={sectionSx}>
      <LocationIcon sx={iconSx}/>
      <Box>
        <Box display="flex" justifyContent={{xs: 'space-between', md: 'flex-start'}} onClick={toggleExpand}>
          <Typography
            fontSize={24}
            lineHeight={1}
            color="primary.main"
          >
            {name}
          </Typography>
          <ExpandIcon expanded={expand} color="primary"/>
        </Box>
        <Collapse in={expand}>
          <Box my={2}>
            {physicalAddress && <Typography sx={contactSx}>{addressToString(physicalAddress)}</Typography>}
            {primaryPhone && <Typography sx={contactSx}>{primaryPhone.number}</Typography>}
          </Box>
          <GoogleMap placeId={googlePlaceId} height={200} width="100%"/>
          <Box display="flex" justifyContent="flex-end">
            <Button
              component={RouterLink}
              to={placeUrl}
              size="large"
              color="primary"
              variant="outlined"
              fullWidth
              sx={{textAlign: 'center', my: 1}}
            >
              More from {name}...
            </Button>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}

export default MobileEventLocation
