import {InMemoryCache} from '@apollo/client'
import {DateTime} from 'luxon'
import {getCurrentUserId} from 'data/auth'

const convertISOtoDateTime = (isoString?: string) => isoString ?DateTime.fromISO(isoString) : undefined

const timestamps = {
  createdAt: {
    read: convertISOtoDateTime,
  },
  updatedAt: {
    read: convertISOtoDateTime,
  },
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        currentUserId() {
          return getCurrentUserId()
        },
      },
    },
    Artist: {
      fields: {
        ...timestamps,
      },
    },
    Event: {
      fields: {
        ...timestamps,
        startTime: {
          read(startTime?: string) {
            return startTime ? new Date(startTime) : undefined
          },
        },
        endTime: {
          read(endTime?: string) {
            return endTime ? new Date(endTime) : undefined
          },
        },
      },
    },
    Place: {
      fields: {
        ...timestamps,
      },
    },
  },
})

export default cache
