export const AMAZON_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*amazon[.]com/
export const APPLE_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*apple[.]com/
export const BANDCAMP_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*bandcamp[.]com/
export const FACEBOOK_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*facebook[.]com/
export const GOOGLE_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*google[.]com/
export const INSTAGRAM_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*instagram[.]com/
export const MIXCLOUD_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*mixcloud[.]com/
export const PAYPAL_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*paypal[.]com/
export const SOUNDCLOUD_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*soundcloud[.]com/
export const SPOTIFY_ARTIST_URL = /^https?:\/\/open\.spotify\.com\/artist\/(?<id>.*)/
export const SPOTIFY_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*spotify[.]com/
export const TIKTOK_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*tiktok[.]com/
export const TWITCH_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*twitch[.]tv/
export const TWITTER_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*twitter[.]com/
export const VENMO_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*venmo[.]com/
export const VIMEO_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*vimeo[.]com/
export const YELP_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*yelp[.]com/
export const YOUTUBE_URL = /^(?:https?:\/\/)(?:[a-zA-Z0-9]+[.])*youtube[.]com/
