import {gql, QueryResult, useLazyQuery} from '@apollo/client'
import type {Artist, Event, Handle, Place} from 'data/types'
import {COMPLETE_ARTIST_FIELDS} from 'data/fragments/artist'
import {COMPLETE_EVENT_FIELDS} from 'data/fragments/event'
import {COMPLETE_PLACE_FIELDS} from 'data/fragments/place'
import {useCallback} from 'react'

const FETCH_DATA_BY_HANDLE_QUERY = gql `
  ${COMPLETE_ARTIST_FIELDS}
  ${COMPLETE_EVENT_FIELDS}
  ${COMPLETE_PLACE_FIELDS}

  query FetchHandleByName($name: String!) {
    handles(
      where: {name: $name}
    ) {
      name
      createdAt
      updatedAt

      handleFor {
        ... on Artist {
          ...CompleteArtistFields
        }

        ... on Event {
          ...CompleteEventFields
        }

        ... on Place {
          ...CompletePlaceFields
        }
      }
    }
  }
`

interface FetchHandleByNameQueryVars {
  name: string,
}

interface FetchHandleByNameQueryData {
  handles: Handle[],
}

interface UseFetchDataByHandleResult {
  fetchDataByHandle: (name: string) => void,
  artist?: Artist,
  event?: Event,
  place?: Place,
  result: QueryResult<FetchHandleByNameQueryData, FetchHandleByNameQueryVars>,
}

export const useFetchDataByHandle = (): UseFetchDataByHandleResult => {
  const [query, result] = useLazyQuery<FetchHandleByNameQueryData, FetchHandleByNameQueryVars>(FETCH_DATA_BY_HANDLE_QUERY)

  const data = result.data?.handles[0]?.handleFor

  let artist, event, place
  switch (data?.__typename) {
    case 'Artist':
      artist = data
      break
    case 'Event':
      event = data
      break
    case 'Place':
      place = data
      break
  }

  const fetchDataByHandle = useCallback((name: string) => {
    if (!result.called) query({variables: {name: name.toLowerCase()}})
  }, [result.called, query])

  return {
    fetchDataByHandle,
    artist,
    event,
    place,
    result,
  }
}
