import React from 'react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import {
  Fab,
  SxProps,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {useRoles} from 'auth/hooks'
import {ctrlOs} from 'utils'
import TooltipLabel from 'ui/TooltipLabel'

const buttonSx: SxProps = {
  position: 'fixed',
  bottom: 16,
  right: 16,
}

const NewPlaceButton = (): JSX.Element | null => {
  const {isContentManager} = useRoles()
  const location = useLocation()

  if (!isContentManager) return null

  return (
    <Tooltip title={<TooltipLabel title="Add Place" shortcut={`${ctrlOs()} + Enter`}/>}>
      <Fab
        color="primary"
        sx={buttonSx}
        component={RouterLink}
        to="/places/new"
        state={{from: location}}
      >
        <AddIcon/>
      </Fab>
    </Tooltip>
  )
}

export default NewPlaceButton
