import React, {useRef, useState} from 'react'
import {Navigate} from 'react-router-dom'
import {
  Box,
  Button,
  Collapse,
  Container,
  List,
  ListItem,
  ListItemButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {InputBaseChangeEvent} from 'types/mui'
import {useAutocompleteService} from 'google/hooks'
import {googleToHapnynPlace} from 'google/utils'
import {useCreatePlace} from 'places/hooks'
import type {Place} from 'data/types'
import {addressToString} from 'places/utils'
import {useHotkeys} from 'react-hotkeys-hook'
import {ctrlOs} from 'utils'
import TooltipLabel from 'ui/TooltipLabel'

const NewPlaceView = (): JSX.Element => {
  const mapRef = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState("")
  const {fetchPredictions, fetchPlace, predictions, place} = useAutocompleteService()
  const {createPlace, result} = useCreatePlace()

  useHotkeys('ctrl+s, meta+s', () => savePlace(),
    {enableOnFormTags: true, preventDefault: true})

  // route to edit screen after creation
  if (result.data) return <Navigate to={`../edit/${result.data.createPlaces.places[0].id}`} replace/>

  const handleSearchChange = (e: InputBaseChangeEvent) => {
    setSearch(e.target.value)
    fetchPredictions({input: e.target.value})
  }

  const handleAddPlace = (placeId?: string) => {
    if (placeId && mapRef.current) fetchPlace({placeId}, mapRef.current)
  }

  const showList = !!search && !!predictions && predictions.length > 0

  let hapnynPlace: Place | undefined
  if (place) hapnynPlace = googleToHapnynPlace(place)

  const handleCreatePlace = () => {
    if (hapnynPlace) createPlace(hapnynPlace)
  }

  const savePlace = () => {
    if (hapnynPlace) handleCreatePlace()
  }

  return (
    <Container maxWidth="md" sx={{mt: 2}}>
      <Typography variant="h2" mb={2}>Add Place</Typography>
      <Paper sx={{p: 2}}>
        <Collapse in={!place}>
          <TextField
            label="Search"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            autoComplete="off"
            autoFocus
          />

          <Collapse in={showList}>
            <List>
              {predictions?.map((prediction) => {
                return (
                  <ListItem key={prediction.place_id} disablePadding>
                    <ListItemButton onClick={() => handleAddPlace(prediction.place_id)}>
                      {prediction.description}
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </Collapse>
        </Collapse>

        <Collapse in={!!place}>
          <Box display="flex" justifyContent="space-between">
            <Box flexShrink={1} overflow="hidden">
              <Typography fontSize={28} color="primary" mb={1}>
                {hapnynPlace?.name}
              </Typography>
              {hapnynPlace?.physicalAddress &&
                <Box mb={1}>
                  <Typography fontSize={18}>
                    {addressToString(hapnynPlace.physicalAddress)}
                  </Typography>
                </Box>
              }
              <Box mb={1}>
                <Typography fontSize={18}>
                  {hapnynPlace?.primaryPhone?.number}
                </Typography>
              </Box>
              <Box mt={2}>
                {hapnynPlace?.links?.map((link) => {
                  return (
                    <Typography key={link.id} fontSize={18} color="success.main">
                      {link.url}
                    </Typography>
                  )
                })}
              </Box>
            </Box>

            <Box ref={mapRef} height={360} width={360} flexShrink={0}/>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Tooltip title={<TooltipLabel shortcut={`${ctrlOs()} + S`}/>}>
              <Box>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={handleCreatePlace}
                  disabled={!hapnynPlace}
                >
                  Create Place
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Collapse>
      </Paper>
    </Container>
  )
}

export default NewPlaceView
