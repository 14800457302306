import React from 'react'
import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import {sortByName} from 'utils'
import type {Artist} from 'data/types'
import ArtistDetails from 'events/components/ArtistDetails'

const sectionSx: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: theme => `${theme.spacing(5)} auto`,
}

const iconSx: SxProps<Theme> = {
  color: theme => theme.palette.text.disabled,
  fontSize: 28,
}

const headerSx: SxProps<Theme> = {
  fontSize: 24,
  lineHeight: 1,
  fontWeight: 300,
}

interface EventArtistsProps {
  artists?: Artist[],
  header?: string,
}

const EventArtists = ({artists, header}: EventArtistsProps): JSX.Element | null => {
  if (!artists || artists.length === 0) return null

  const sortedArtists = [...artists].sort((a, b) => sortByName(a.name, b.name))

  return (
    <Box>
      <Box sx={sectionSx}>
        <PersonIcon sx={iconSx}/>
        <Box display="grid" rowGap={2}>
          {header && <Typography sx={headerSx}>{header}</Typography>}
          {sortedArtists.map(a => <ArtistDetails key={a.id} artist={a} expanded={artists.length === 1}/>)}
        </Box>
      </Box>
    </Box>
  )
}

export default EventArtists
