import {getRoles} from 'data/auth'

interface UseRolesResult {
  roles: string[],
  isAdmin: boolean,
  isContentManager: boolean,
}

export const useRoles = (): UseRolesResult => {
  const roles = getRoles()
  const isAdmin = roles.includes('admin')
  const isContentManager = roles.includes('content-manager')

  return {
    roles,
    isAdmin,
    isContentManager,
  }
}
