import {useCallback} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {gql, MutationResult, useMutation} from '@apollo/client'
import {setJwt} from 'data/auth'
import {LocationState} from 'types/location'

type SignInFn = (args: {email: string, password: string}) => void

interface SignInMutationVars {
  email: string,
  password: string,
}

const SIGN_IN_MUTATION = gql `
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password)
  }
`

interface SignInMutationData {
  signIn: string,
}

interface SignInResult extends MutationResult {
  signIn: SignInFn
}

export const useSignIn = (): SignInResult => {
  const location = useLocation()
  const navigate = useNavigate()

  const state = location.state as LocationState

  const redirect = state?.from ?? '/'

  const onCompleted = (data: SignInMutationData) => {
    setJwt(data.signIn)
    navigate(redirect, {replace: true})
  }

  const onError = () => {/* prevent exception from being thrown */}

  const mutationOptions = {
    onCompleted,
    onError,
  }

  const [mutation, result] = useMutation<SignInMutationData, SignInMutationVars>(SIGN_IN_MUTATION, mutationOptions)

  const signIn: SignInFn = useCallback(({email, password}): void => {
    const variables = {
      email,
      password,
    }

    mutation({variables})
  }, [mutation])

  return {
    ...result,
    signIn,
  }
}
