import {gql} from '@apollo/client'

export const CORE_IMAGE_FIELDS = gql `
  fragment CoreImageFields on Image {
    id
    createdAt

    url
  }
`
