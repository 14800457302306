import React from 'react'
import {
  SxProps,
  Typography,
} from '@mui/material'

const baseSx: SxProps = {
  fontFamily: 'Alba',
  marginRight: '0.2em',
}

type LogoProps = {
  fontSize?: number,
  color?: "common.white" | "primary.main",
  shadow?: boolean,
}

const Logo = ({fontSize=28, color="common.white", shadow=true}: LogoProps): JSX.Element => {
  const logoSx = {
    ...baseSx,
    fontSize,
    color,
    textShadow: shadow ? '0.03em 0.03em 0.03em black' : 'none',
  }

  return (
    <Typography sx={logoSx}>
      hapnyn
    </Typography>
  )
}

export default Logo
