import React from 'react'
import {
  Typography,
  TypographyProps,
} from '@mui/material'
import type {Event} from 'data/types'
import {getEventName} from 'events/utils'

interface EventTitleProps extends TypographyProps {
  event: Event,
}

const EventTitle = ({event, ...typographyProps}: EventTitleProps): JSX.Element => {
  const title = getEventName(event)

  return (
    <Typography variant="h2" {...typographyProps}>
      {title}
    </Typography>
  )
}

export default EventTitle
