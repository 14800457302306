import {gql} from '@apollo/client'

export const CORE_LINK_FIELDS = gql `
  fragment CoreLinkFields on Link {
    id
    createdAt

    url
  }
`
