import React from 'react'
import {
  SvgIcon,
} from '@mui/material'
import WebIcon from '@mui/icons-material/Public'
import AppleIcon from '@mui/icons-material/Apple'
import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import YouTubeIcon from '@mui/icons-material/YouTube'
import type {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {
  faAmazon,
  faBandcamp,
  faMixcloud,
  faPaypal,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faTwitch,
  faVimeoV,
  faYelp,
} from '@fortawesome/free-brands-svg-icons'
import {
  AMAZON_URL,
  APPLE_URL,
  BANDCAMP_URL,
  FACEBOOK_URL,
  GOOGLE_URL,
  INSTAGRAM_URL,
  MIXCLOUD_URL,
  PAYPAL_URL,
  SOUNDCLOUD_URL,
  SPOTIFY_URL,
  TIKTOK_URL,
  TWITCH_URL,
  TWITTER_URL,
  VENMO_URL,
  VIMEO_URL,
  YELP_URL,
  YOUTUBE_URL,
} from 'utils/regex'
import {Link} from 'data/types'

type FontAwesomeSvgIconProps = {
  icon: IconDefinition,
}

const FontAwesomeSvgIcon = React.forwardRef<SVGSVGElement, FontAwesomeSvgIconProps>(
  (props, ref) => {
    const {icon} = props

    const {
      icon: [width, height, , , svgPathData],
    } = icon

    return (
      <SvgIcon ref={ref} viewBox={`0 0 ${width} ${height}`}>
        {typeof svgPathData === 'string' ? (
          <path d={svgPathData}/>
        ) : (
          /**
           * A multi-path Font Awesome icon seems to imply a duotune icon. The 0th path seems to
           * be the faded element (referred to as the "secondary" path in the Font Awesome docs)
           * of a duotone icon. 40% is the default opacity.
           *
           * @see https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons#changing-opacity
           */
          svgPathData.map((d: string, i: number) => (
            <path key={i} style={{opacity: i === 0 ? 0.4 : 1}} d={d}/>
          ))
        )}
      </SvgIcon>
    )
  },
)

FontAwesomeSvgIcon.displayName = 'FontAwesomeSvgIcon'

const VenmoIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 162 162"
      >
        <path className="st0" d="M136.8,14.9c4.8,8,7,16.2,7,26.6c0,33.2-28.3,76.2-51.3,106.5H40L19,22.1l46-4.4l11.1,89.6
          c10.4-16.9,23.2-43.6,23.2-61.7c0-9.9-1.7-16.7-4.4-22.3L136.8,14.9z"/>
      </svg>
    </SvgIcon>
  )
}

interface IconProps {
  link: Link,
}

const Icon = ({link}: IconProps): JSX.Element | null => {
  if (link.url.match(AMAZON_URL)) return <FontAwesomeSvgIcon icon={faAmazon}/>
  if (link.url.match(APPLE_URL)) return <AppleIcon/>
  if (link.url.match(BANDCAMP_URL)) return <FontAwesomeSvgIcon icon={faBandcamp}/>
  if (link.url.match(FACEBOOK_URL)) return <FacebookIcon/>
  if (link.url.match(GOOGLE_URL)) return <GoogleIcon/>
  if (link.url.match(INSTAGRAM_URL)) return <InstagramIcon/>
  if (link.url.match(MIXCLOUD_URL)) return <FontAwesomeSvgIcon icon={faMixcloud}/>
  if (link.url.match(PAYPAL_URL)) return <FontAwesomeSvgIcon icon={faPaypal}/>
  if (link.url.match(SOUNDCLOUD_URL)) return <FontAwesomeSvgIcon icon={faSoundcloud}/>
  if (link.url.match(SPOTIFY_URL)) return <FontAwesomeSvgIcon icon={faSpotify}/>
  if (link.url.match(TIKTOK_URL)) return <FontAwesomeSvgIcon icon={faTiktok}/>
  if (link.url.match(TWITCH_URL)) return <FontAwesomeSvgIcon icon={faTwitch}/>
  if (link.url.match(TWITTER_URL)) return <TwitterIcon/>
  if (link.url.match(VENMO_URL)) return <VenmoIcon/>
  if (link.url.match(VIMEO_URL)) return <FontAwesomeSvgIcon icon={faVimeoV}/>
  if (link.url.match(YELP_URL)) return <FontAwesomeSvgIcon icon={faYelp}/>
  if (link.url.match(YOUTUBE_URL)) return <YouTubeIcon/>

  return <WebIcon/>
}

export default Icon
