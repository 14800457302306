import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import type {Event} from 'data/types'
import {addressToString} from 'places/utils'
import GoogleMap from 'google/components/Map'
import {getPrimaryUrl} from 'utils/urls'

const nameSx: SxProps<Theme> = {
  display: 'block',
  color: theme => theme.palette.primary.main,
  textDecoration: 'none',
  transition: '0.3s',

  '&:hover': {
    textShadow: theme => `0 0 5px ${theme.palette.primary.light}`,
  },
}

const contactSx: SxProps<Theme> = {
  fontSize: 18,
  fontWeight: 300,
}

interface EventLocationDesktopProps {
  event: Event,
}

const EventLocationDesktop = ({event}: EventLocationDesktopProps): JSX.Element | null => {
  const {location} = event

  const placeUrl = getPrimaryUrl(location)

  return (
    <Box display="grid" gap={2} justifyItems="end" textAlign="right">
      <Typography
        component={RouterLink}
        to={placeUrl}
        variant="h2"
        sx={nameSx}
      >
        {event.location.name}
      </Typography>
      <Box>
        {location.physicalAddress && <Typography sx={contactSx}>{addressToString(location.physicalAddress)}</Typography>}
        {location.primaryPhone && <Typography sx={contactSx}>{location.primaryPhone.number}</Typography>}
      </Box>
      <GoogleMap placeId={location.googlePlaceId} height={300}/>
    </Box>
  )
}

export default EventLocationDesktop
