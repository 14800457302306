import React from 'react'
import {
  Theme,
  useMediaQuery,
} from '@mui/material'
import DesktopMenu from './Desktop'
import MobileMenu from './Mobile'

const HapnynMenu = (): JSX.Element => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  if (isMobile) return <MobileMenu/>

  return <DesktopMenu/>
}

export default HapnynMenu
