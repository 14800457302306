import {useCallback} from 'react'
import {gql, QueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_EVENT_FIELDS} from 'data/fragments/event'
import type {Event} from 'data/types'

const FETCH_EVENTS_QUERY = gql `
  ${CORE_EVENT_FIELDS}

  query FetchEvents {
    events(
      options: {sort: {startTime: ASC}}
    ) {
      ...CoreEventFields
    }
  }
`

interface FetchEventsData {
  events: Event[],
}

interface UseFetchEventsResult {
  fetchEvents: () => void,
  events?: Event[],
  result: QueryResult<FetchEventsData>,
}

type FetchEventsOptions = QueryHookOptions<FetchEventsData>

export const useFetchEvents = (options?: FetchEventsOptions): UseFetchEventsResult => {
  const [query, result] = useLazyQuery<FetchEventsData>(FETCH_EVENTS_QUERY, options)

  const fetchEvents = useCallback(() => {
    if (!result.called) query()
  }, [result.called, query])

  const events = result.data?.events

  return {
    fetchEvents,
    events,
    result,
  }
}
