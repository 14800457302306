import React from 'react'
import {
  Button,
  Link as MuiLink,
} from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram'

const handleFromUrl = (url: string) => {
  let s = url.split("?")[0]
  s = (s.slice(-1)) == "/" ? s.slice(0, -1) : s
  return s.split("/").pop()
}

interface InstagramHandleProps {
  url: string,
}

const InstagramHandle = ({url}: InstagramHandleProps): JSX.Element => {
  return (
    <Button
      sx={{textTransform: 'none'}}
      component={MuiLink}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      startIcon={<InstagramIcon/>}
      color="success"
      size="large"
    >
      {handleFromUrl(url)}
    </Button>
  )
}

export default InstagramHandle
