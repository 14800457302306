import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Box,
  Button,
  IconButton,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import PersonIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {getEditUrl, getPrimaryUrl} from 'utils/urls'
import {DateTime, Interval} from 'luxon'
import {Artist, Event, Place} from 'data/types'
import {getEventName} from 'events/utils'

const getEventTimeRange = (event: Event): string => {
  const startTime = DateTime.fromJSDate(event.startTime)
  const endTime = event.endTime ? DateTime.fromJSDate(event.endTime) : startTime.plus({hours: 3})
  const interval = Interval.fromDateTimes(startTime, endTime)

  let startTimeString = interval.start.toFormat('h')
  if (interval.start.minute != 0) startTimeString += interval.start.toFormat(':mm')
  if (interval.start.toFormat('a') !== interval.end.toFormat('a') || !interval.hasSame('day')) {
    startTimeString += interval.start.toFormat('a').toLowerCase()
  }

  let endTimeString = interval.end.toFormat('h')
  if (interval.end.minute != 0) endTimeString += interval.end.toFormat(':mm')
  endTimeString += interval.end.toFormat('a').toLowerCase()
  if (interval.end.hour === 0 && interval.end.minute === 0) endTimeString = "midnight"

  const eventTimeRange = [startTimeString, endTimeString].join("-")

  return eventTimeRange
}

interface ConflictRowProps {
  entity: Artist | Place,
  event1: Event,
  event2: Event,
}

const ConflictRow = ({entity, event1, event2}: ConflictRowProps) => {
  const event1Name = getEventName(event1)
  const event1TimeRange = getEventTimeRange(event1)

  const event2Name = getEventName(event2)
  const event2TimeRange = getEventTimeRange(event2)

  const entityType = entity.__typename

  let col1, col3

  const col1Artist =
  <Button
    sx={{textTransform: 'none'}}
    component={RouterLink}
    to={getPrimaryUrl(entity)}
    target="_blank"
    rel="noopener noreferrer"
    startIcon={<PersonIcon/>}
    size="large"
    color="warning"
  >
    <Typography fontSize={18}>{entity.name}</Typography>
  </Button>

  const col1Place =
  <Button
    sx={{textTransform: 'none'}}
    component={RouterLink}
    to={getPrimaryUrl(entity)}
    target="_blank"
    rel="noopener noreferrer"
    startIcon={<PlaceIcon/>}
    size="large"
  >
    <Typography fontSize={18} color="primary.main">{entity.name}</Typography>
  </Button>

  const col3Artist =
  <>
    <Box display='flex'>
      <Typography fontSize={18} pr=".28em">{event1Name + " @"}</Typography>
      <Button
        sx={{textTransform: 'none', p: 0}}
        component={RouterLink}
        to={getPrimaryUrl(event1.location)}
        target="_blank"
        rel="noopener noreferrer"
        size="large"
      >
        <Typography fontSize={18} color="primary.main">{event1.location.name}</Typography>
      </Button>
    </Box>
    <Box display='flex'>
      <Typography fontSize={18} pr=".28em">{event2Name + " @"}</Typography>
      <Button
        sx={{textTransform: 'none', p: 0}}
        component={RouterLink}
        to={getPrimaryUrl(event2.location)}
        target="_blank"
        rel="noopener noreferrer"
        size="large"
      >
        <Typography fontSize={18} color="primary.main">{event2.location.name}</Typography>
      </Button>
    </Box>
  </>

  const col3Place =
  <>
    <Typography fontSize={18}>{event1Name}</Typography>
    <Typography fontSize={18}>{event2Name}</Typography>
  </>


  switch (entityType ) {
    case "Artist":
      col1 = col1Artist
      col3 = col3Artist
      break
    case "Place":
      col1 = col1Place
      col3 = col3Place
  }

  return (
    <>
      <TableRow
      >
        <TableCell>
          {col1}
        </TableCell>
        <TableCell>
          <Typography fontSize={18} color="info.main">{event1TimeRange}</Typography>
          <Typography fontSize={18} color="info.main">{event2TimeRange}</Typography>
        </TableCell>
        <TableCell>
          {col3}
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton
            component={RouterLink}
            to={getPrimaryUrl(event1)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            <OpenInNewIcon/>
          </IconButton>
          <IconButton
            component={RouterLink}
            to={getPrimaryUrl(event2)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            <OpenInNewIcon/>
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton
            component={RouterLink}
            to={getEditUrl(event1)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            <EditIcon/>
          </IconButton>
          <IconButton
            component={RouterLink}
            to={getEditUrl(event2)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            <EditIcon/>
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ConflictRow
