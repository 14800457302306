import React, {useMemo, useState} from 'react'
import {Searcher} from 'fast-fuzzy'
import {
  Box,
  IconButton,
  InputBase,
  Paper,
  SxProps,
  TableContainer,
  Table,
  TableBody,
  Theme,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import type {Event} from 'data/types'
import EventsTableRow from './TableRow'
import {getEventName} from 'events/utils'

const searchInputSx: SxProps<Theme> = {
  mb: 1,
}

const searchWrapperSx: SxProps<Theme> = {
  display: 'flex',
  borderRadius: 1,
  pl: 1.5,
  pt: 1,
  mb: 1,
  maxWidth: 600,
}

function getKeySelectorArray(event: Event): string[] {
  const keySelectors: string[] = []

  const eventName = getEventName(event)
  if (eventName) keySelectors.push(eventName)

  keySelectors.push(event.location.name)

  event.artists?.forEach(a => keySelectors.push(a.name))

  event.promotedArtists?.forEach(a => keySelectors.push(a.name))

  return keySelectors
}

interface EventsTableProps {
  events : Event[],
}

const EventsTable = ({events}: EventsTableProps): JSX.Element | null => {
  const [input, setInput] = useState("")

  const searcher = useMemo(() => new Searcher(events, {keySelector: e => getKeySelectorArray(e)}), [events])

  const filteredEvents = input === "" ? events : searcher.search(input)

  const clearInput = () => setInput("")

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Escape':
        clearInput()
        break
    }
  }

  return (
    <Box>
      <Box sx={searchWrapperSx} border={2} borderColor='primary.main'>
        <InputBase
          value={input}
          onChange={(event) => setInput(event.target.value)}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          placeholder="Search"
          autoFocus
          fullWidth
          sx={searchInputSx}
        />
        <IconButton
          sx={{mb: 1}}
          onClick={clearInput}
          disabled={!input}
        >
          <CancelIcon/>
        </IconButton>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {filteredEvents.map((event) => {
              return <EventsTableRow key={event.id} event={event}/>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default EventsTable
