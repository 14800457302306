import React from 'react'
import {
  Box,
} from '@mui/material'
import EventsList from 'events/components/List'
import DateSelectorDialog from 'events/components/DateSelectorDialog'

const MobileDashboard = (): JSX.Element => {
  return (
    <Box sx={{mx: 1, my: 2}}>
      <EventsList/>
      <DateSelectorDialog/>
    </Box>
  )
}

export default MobileDashboard
