import React, {useState} from 'react'
import {DateTime, Interval} from 'luxon'
import {
  Box,
  Collapse,
  Paper,
  TableRow,
  TableCell,
  Typography,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import ExpandIcon from 'ui/ExpandIcon'
import {Artist, Event, Place} from 'data/types'
import ConflictRow from './ConflictRow'
import {filterStatusEvents} from 'events/utils'

interface ConflictingEventsProps {
  events: Event[],
  date: DateTime,
}

type ArtistType = "" | "festival" | "trivia"

type Conflict = {
  entity: Artist | Place,
  artistType: ArtistType,
  event1: Event,
  event2: Event,
}

const ConflictingEvents = ({events}: ConflictingEventsProps): JSX.Element | null => {
  const [expandFestival, setExpandFestival] = useState(false)
  const [expandTrivia, setExpandTrivia] = useState(false)

  const filteredEvents = filterStatusEvents(events)

  const conflicts: Conflict[] = []

  nextEvent: for (let i=0; i < filteredEvents.length; i++) {
    const event1 = filteredEvents[i]

    if (!event1.endTime) continue nextEvent

    const event1Interval = Interval.fromDateTimes(event1.startTime, event1.endTime)

    const event1ArtistIds: string[] = []
    let artistType: ArtistType = ""

    event1.artists?.forEach(a => {
      if (a.name.toLowerCase().indexOf("trivia") > -1) {
        artistType = "trivia"
      } else if (a.name.toLowerCase().split(" ").pop() == "festival") {
        artistType = "festival"
      }

      event1ArtistIds.push(a.id)
    })

    for (let j=i+1; j < filteredEvents.length; j++) {
      const event2 = filteredEvents[j]

      const event2StartDateTime = DateTime.fromJSDate(event2.startTime)
      if (!event1Interval.contains(event2StartDateTime)) continue nextEvent

      if (event1.location.id == event2.location.id) {
        const conflict: Conflict = {
          entity: event1.location,
          artistType,
          event1,
          event2,
        }

        conflicts.push(conflict)

        continue nextEvent
      }

      let matchingArtist = false

      event2.artists?.forEach(a => {
        if (event1ArtistIds?.includes(a.id)) {
          const conflict: Conflict = {
            entity: a,
            artistType,
            event1,
            event2,
          }

          conflicts.push(conflict)

          matchingArtist = true
        }
      })

      if (matchingArtist) {
        continue nextEvent
      }
    }
  }

  let festivalCount = 0
  conflicts.forEach(c => {if (c.artistType == "festival") festivalCount++})

  let triviaCount = 0
  conflicts.forEach(c => {if (c.artistType == "trivia") triviaCount++})

  const toggleExpandFestival = () => setExpandFestival(!expandFestival)
  const toggleExpandTrivia = () => setExpandTrivia(!expandTrivia)

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {conflicts.filter(c => c.artistType == "").map((c, i) => {
            return <ConflictRow key={i} entity={c.entity} event1={c.event1} event2={c.event2}/>
          })}
        </TableBody>
      </Table>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Box display='flex' onClick={toggleExpandFestival}>
                <ExpandIcon expanded={expandFestival}/>
                <Typography fontSize={18} sx={{pl: 1}}>Festivals ({festivalCount})</Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Collapse in={expandFestival}>
        <Table>
          <TableBody>
            {conflicts.filter(c => c.artistType == "festival").map((c, i) => {
              return <ConflictRow key={i} entity={c.entity} event1={c.event1} event2={c.event2}/>
            })}
          </TableBody>
        </Table>
      </Collapse>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Box display='flex' onClick={toggleExpandTrivia}>
                <ExpandIcon expanded={expandTrivia}/>
                <Typography fontSize={18} sx={{pl: 1}}>Trivia ({triviaCount})</Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Collapse in={expandTrivia}>
        <Table>
          <TableBody>
            {conflicts.filter(c => c.artistType == "trivia").map((c, i) => {
              return <ConflictRow key={i} entity={c.entity} event1={c.event1} event2={c.event2}/>
            })}
          </TableBody>
        </Table>
      </Collapse>
    </TableContainer>
  )
}

export default ConflictingEvents
