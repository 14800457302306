import React, {useEffect, useState} from 'react'
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import type {Event} from 'data/types'
import {useDate, useFetchEventsByDateRange} from 'events/hooks'
import {DateTime} from 'luxon'
import EventsTable from './Table'
import MissingEvents from './Missing'
import ConflictingEvents from './Conflicting'
import {InstagramPanel} from './Instagram'
import {sortEvents, filterStatusEvents} from 'events/utils'

const headerSx: SxProps<Theme> = {
  backgroundColor: theme => theme.palette.info.main,
  mb: 1,
  px: 2,
  py: 1,
  borderRadius: 1,
  display: 'grid',
  gridTemplateColumns: `auto auto auto`,
  columnGap: 2,
  alignItems: 'center',
}

const EventsAdminPanel = (): JSX.Element => {
  const [date, setDate] = useDate()
  const {fetchEventsByDateRange, events, result: {loading}} = useFetchEventsByDateRange({
    fetchPolicy: 'cache-and-network',
  })
  const [tabValue, setTabValue] = useState('table')

  useEffect(() => {
    fetchEventsByDateRange(date.toJSDate())
  }, [date, fetchEventsByDateRange])

  let filteredEvents: Event[] = []
  let sortedEvents: Event[] = []

  if (events) {
    filteredEvents = events.filter((event) => {
      const startTime = DateTime.fromJSDate(event.startTime)
      return startTime.hasSame(date, 'day')
    })

    sortedEvents = [...filteredEvents].sort((a, b) => sortEvents(a, b))
  }

  return (
    <Container maxWidth="xl">
      <Box my={2}>
        <Box sx={headerSx}>
          <Typography variant="h4">Events Admin Panel</Typography>

          <Box
            display="grid"
            gridTemplateColumns="auto auto auto"
            justifyContent="center"
            alignItems="center"
            columnGap={1}
          >
            <IconButton size="small" onClick={() => setDate(date.minus({day: 1}))}>
              <ArrowLeftIcon/>
            </IconButton>
            <Typography fontSize={18} align="center">
              {date.toLocaleString(DateTime.DATE_HUGE)}
            </Typography>
            <IconButton size="small" onClick={() => setDate(date.plus({day: 1}))}>
              <ArrowRightIcon/>
            </IconButton>
          </Box>

          <Typography fontSize={18} align="right">
            Count: {sortedEvents.length}
          </Typography>
        </Box>

        <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
          <Tab label="Table" value="table"/>
          <Tab label="Instagram" value="instagram"/>
          <Tab label="Missing" value="missing"/>
          <Tab label="Conflicting" value="conflicting"/>
        </Tabs>

        <Box pt={1}>
          {tabValue === 'table' && <EventsTable events={sortedEvents}/>}
          {tabValue === 'instagram' && <InstagramPanel events={filterStatusEvents(sortedEvents)}/>}
          {tabValue === 'missing' && <MissingEvents currentEvents={sortedEvents} currentDate={date}/>}
          {tabValue === 'conflicting' && <ConflictingEvents events={sortedEvents} date={date}/>}
        </Box>

        {loading &&
          <Box textAlign="center" my={2}>
            <CircularProgress/>
          </Box>
        }
      </Box>
    </Container>
  )
}

export default EventsAdminPanel
