import {useCallback} from 'react'
import {gql, MutationHookOptions, MutationResult, useMutation} from '@apollo/client'
import type {Artist, Input} from 'data/types'
import {CORE_ARTIST_FIELDS} from 'data/fragments/artist'

interface CreateArtistVars {
  createArtistInput: Input<Artist>,
}

const CREATE_ARTIST_MUTATION = gql `
  ${CORE_ARTIST_FIELDS}

  mutation CreateArtist($createArtistInput: [ArtistCreateInput!]!) {
    createArtists(input: $createArtistInput) {
      artists {
        ...CoreArtistFields
      }
    }
  }
`

interface CreateArtistData {
  createArtists: {
    artists: Artist[],
  },
}

interface UseCreateArtistResult {
  createArtist: (artist: Artist) => void,
  result: MutationResult<CreateArtistData>,
}

type CreateArtistOptions = MutationHookOptions<CreateArtistData, CreateArtistVars>

export const useCreateArtist = (options?: CreateArtistOptions): UseCreateArtistResult => {
  const [mutate, result] = useMutation<CreateArtistData, CreateArtistVars>(CREATE_ARTIST_MUTATION, {
    onError: () => {/* prevent exception from being thrown */},
    refetchQueries: ['FetchArtists', 'ArtistsCount'],
    ...options,
  })

  const createArtist = useCallback((artist: Artist) => {
    const {
      id,
      name,
    } = artist

    const createArtistInput: Input<Artist> = {
      id,
      name,
    }

    mutate({variables: {createArtistInput}})
  }, [mutate])

  return {
    createArtist,
    result,
  }
}
