import React from 'react'
import {
  Theme,
  useMediaQuery,
} from '@mui/material'
import type {Place} from 'data/types'
import MobileDetails from './Mobile'
import DesktopDetails from './Desktop'

interface PlaceDetailsProps {
  place: Place,
}

const PlaceDetails = ({place}: PlaceDetailsProps): JSX.Element | null => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (!place) return null

  if (isMobile) return <MobileDetails place={place}/>

  return <DesktopDetails place={place}/>
}

export default PlaceDetails
