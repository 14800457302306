import {useLocation, useNavigate} from 'react-router-dom'
import {LocationState} from 'types/location'

interface UseShowDialogsResult {
  isDialogOpen: (name: string) => boolean,
  openDialog: (name: string) => void,
  closeDialog: (name: string) => void,
}

export const useShowDialogs = (): UseShowDialogsResult => {
  const location = useLocation()
  const navigate = useNavigate()

  const locationState = location.state as LocationState

  const dialogs = new Set(locationState?.showDialogs ?? [])

  const isDialogOpen = (name: string) => dialogs.has(name)

  const openDialog = (name: string) => {
    dialogs.add(name)

    const showDialogs = Array.from(dialogs)

    navigate(location, {state: {...locationState, showDialogs}})
  }

  const closeDialog = (name: string) => {
    dialogs.delete(name)

    const showDialogs = Array.from(dialogs)

    navigate(location, {state: {...locationState, showDialogs}, replace: true})
  }

  return ({
    isDialogOpen,
    openDialog,
    closeDialog,
  })
}
