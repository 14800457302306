import {gql} from '@apollo/client'

export const CORE_TEXT_FIELDS = gql `
  fragment CoreTextFields on Text {
    id
    createdAt
    updatedAt

    format
    content
  }
`
