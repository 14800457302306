import React, {useEffect, useRef} from 'react'
import {useImmer} from 'use-immer'
import {DateTime} from 'luxon'
import * as Chrono from 'chrono-node'
import {
  TextField,
} from '@mui/material'

const formatTime = (time?: DateTime): string => {
  if (!time) return ""

  const meridian = time.toFormat('a').toLowerCase()
  return time.toFormat('h:mm') + meridian
}

export const parseTime = (input: string) => {
  switch (input.toLowerCase().trimEnd().slice(-1)) {
    case 'm':
      break

    case 'a':
    case 'p':
      input += 'm'
      break

    default:
      input += 'pm'
      break
  }

  const [parsedResult] = Chrono.parse(input)

  const parsedStart = parsedResult?.start
  const parsedDate = parsedStart?.isCertain('hour') && parsedStart?.isCertain('meridiem') ? parsedStart?.date() : undefined

  return parsedDate ? DateTime.fromJSDate(parsedDate) : undefined
}

interface TimeSelectorProps {
  time?: DateTime,
  onChange: (time?: DateTime) => void,
  label?: string,
}

const TimeSelector = ({time, onChange, label="Time"}: TimeSelectorProps) => {
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null)
  const [timeInput, setTimeInput] = useImmer(formatTime(time))

  useEffect(() => {
    if (time) setTimeInput(formatTime(time))

    if (inputRef.current?.ownerDocument.activeElement === inputRef.current) {
      // update selection if focused
      setTimeout(() => inputRef.current?.select(), 50)
    }
  }, [time, setTimeInput])

  const handleInputChange = (input: string) => {
    setTimeInput(input)
  }

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    event.target.select()
  }

  const handleBlur = () => {
    const dateString = time?.toLocaleString() ?? ""
    const parsedDateTime = parseTime(`${dateString} ${timeInput}`)

    if (parsedDateTime) {
      // update time
      onChange(parsedDateTime)
    } else {
      // reset input
      setTimeInput(formatTime(time))
    }
  }

  return (
    <TextField
      inputRef={inputRef}
      label={label}
      value={timeInput}
      onChange={e => handleInputChange(e.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  )
}

export default TimeSelector
