import React from 'react'
import {DateTime} from 'luxon'
import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import type {Event} from 'data/types'

const cardSx: SxProps<Theme> = {
  height: 1080,
  width: 1080,
  p: '108px',
  backgroundColor: theme => theme.palette.background.default,
}

const eventSx: SxProps<Theme> = {
  '&:hover': {
    cursor: 'no-drop',
  },
}

interface InstagramCardProps {
  events: Event[],
  hideEvent: (id: string) => void,
}

export const InstagramCard = ({events, hideEvent}: InstagramCardProps): JSX.Element => {
  return (
    <Box sx={cardSx}>
      {events.map((event) => {
        let eventName = ""
        if (event.title) {
          eventName += `${event.title}`
        } else if (event.artists && event.artists.length > 0) {
          const artistNames = event.artists.map(a => a.name)
          const separator = artistNames.length > 2 ? ", " : " & "
          eventName += artistNames.join(separator)
        }

        const eventPlace = event.location.name

        const startTime = DateTime.fromJSDate(event.startTime)

        let startTimeString = startTime.toFormat('h')
        if (startTime.minute != 0) startTimeString += startTime.toFormat(':mm')
        startTimeString += startTime.toFormat(' a')

        let locale = event.location.physicalAddress?.neighborhood
        if (!locale) locale = event.location.physicalAddress?.locality
        if (!locale) locale = ""

        return (
          <Box key={event.id} mb={4} onClick={() => hideEvent(event.id)} sx={eventSx}>
            <Box>
              <Box display="inline-flex" justifyContent="space-between" width="100%">
                <Typography color="common.white" fontSize={44} fontWeight={300}>{eventName}</Typography>
                <Typography color="common.white" fontSize={44} fontWeight={300} flexShrink={0} ml={2}>{startTimeString}</Typography>
              </Box>
              <Box display='inline-flex' justifyContent='space-between' width='100%' alignItems='baseline'>
                <Typography color="primary.main" fontSize={40} fontWeight={400}>{eventPlace}</Typography>
                <Typography color="success.main" fontSize={28} fontWeight={400} flexShrink={0} ml={2}>{locale}</Typography>
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
