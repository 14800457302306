import React, {useCallback, useEffect, useState} from 'react'
import {DateTime} from 'luxon'
import {v4 as uuid} from 'uuid'
import {
  Box,
  Button,
  Container,
  Paper,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import type {Artist, Event, Place, Text} from 'data/types'
import LinksEditor from 'ui/LinksEditor'
import WhenPicker from 'events/components/Editor/WhenPicker'
import PlacePicker from 'events/components/PlacePicker'
import ArtistPicker from 'events/components/ArtistPicker'
import ArtistSponsorPicker from 'events/components/ArtistSponsorPicker'
import ArtistPromoterPicker from 'events/components/ArtistPromoterPicker'
import ConflictChecker from 'events/components/ConflictChecker'
import {useCreateEvent, useFetchEventById} from 'events/hooks'
import {Navigate, useNavigate, useSearchParams} from 'react-router-dom'
import {useReturnLocation} from 'router/hooks'
import {getPrimaryUrl} from 'utils/urls'
import {useHotkeys} from 'react-hotkeys-hook'
import {ctrlOs} from 'utils'
import TooltipLabel from 'ui/TooltipLabel'

const titleSx: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const formSx: SxProps = {
  display: 'grid',
  gridTemplateAreas: {
    xs: `
      "where"
      "when"
      "who"
      "title"
      "description"
    `,
  },
  justifyItems: 'stretch',
  alignItems: 'center',
  gap: 2,
  mt: {
    xs: 2,
    md: 4,
  },
}

const NewEventView = (): JSX.Element | null => {
  const [searchParams] = useSearchParams()
  const {fetchEventById, event: sourceEvent} = useFetchEventById()
  const [eventState, setEventState] = useState<Partial<Event>>({id: uuid()})
  const {createEvent, result} =  useCreateEvent()
  const navigate = useNavigate()
  const returnLocation = useReturnLocation()

  useHotkeys('ctrl+s, meta+s', () => saveEvent(),
    {enableOnFormTags: true, preventDefault: true})
  useHotkeys('ctrl+., meta+.', () => handleCancel(),
    {enableOnFormTags: true, preventDefault: true})

  // check location for artistId
  const sourceEventId = searchParams.get('sourceEventId')
  if (sourceEventId) fetchEventById(sourceEventId)

  const focusWhere = !!searchParams.get('artistId')
  const focusWhen = !focusWhere

  useEffect(() => {
    if (sourceEvent) {
      const newStartTime = DateTime.fromJSDate(sourceEvent.startTime).plus({week: 1})
      const newEndTime = sourceEvent.endTime
        ? DateTime.fromJSDate(sourceEvent.endTime).plus({week: 1})
        : newStartTime.plus({hour: 3})

      let newDescription: Text | undefined
      if (sourceEvent.description) {
        newDescription = {
          ...sourceEvent.description,
          id: uuid(),
        }
      }

      setEventState(currentState => ({
        ...sourceEvent,
        startTime: newStartTime.toJSDate(),
        endTime: newEndTime.toJSDate(),
        description: newDescription,
        ...currentState,
      }))
    }
  }, [sourceEvent])

  const updateWhen = useCallback(
    (start: DateTime, end:DateTime) => {
      setEventState((state) => ({
        ...state,
        startTime: start.toJSDate() ?? eventState.startTime,
        endTime: end.toJSDate() ?? eventState.endTime,
      }))
    },
    [eventState.startTime, eventState.endTime],
  )

  const updateLocation = useCallback(
    (location?: Place) => setEventState(s => ({...s, location})),
    [setEventState],
  )

  const updateArtists = useCallback(
    (artists?: Artist[]) => setEventState(s => ({...s, artists})),
    [setEventState],
  )

  const updateSponsorArtists = useCallback(
    (sponsorArtists?: Artist[]) => setEventState(s => ({...s, sponsorArtists})),
    [setEventState],
  )

  const updatePromotedArtists = useCallback(
    (promotedArtists?: Artist[]) => setEventState(s => ({...s, promotedArtists})),
    [setEventState],
  )

  const updateTitle = (value: string) => {
    let title: string | undefined

    if (value.length > 0) title = value

    setEventState({...eventState, title})
  }

  const updateDescription = (content: string) => {
    let description: Text | undefined

    if (content.length > 0) {
      description = {
        id: uuid(),
        ...eventState.description,
        format: 'PLAIN',
        content,
      }
    }

    setEventState({...eventState, description})
  }

  const isValid = eventState.id
    && eventState.location
    && eventState.startTime
    && eventState.endTime
    && ((eventState.artists && eventState.artists.length > 0) || eventState.title)

  const addLink = (url: string) => {
    const links = eventState.links ?? []

    setEventState({
      ...eventState,
      links: [
        {id: uuid(), url},
        ...links,
      ],
    })
  }

  const removeLink = (linkId: string) => {
    setEventState({
      ...eventState,
      links: eventState.links?.filter(l => l.id !== linkId),
    })
  }

  const handleCreate = () => {
    console.log({eventState})

    if (isValid) createEvent(eventState as Event)
  }

  const handleCancel = () => navigate(returnLocation ?? '/', {replace: true})

  const saveEvent = () => {
    if (isValid && !result.loading) handleCreate()
  }

  if (result.data) {
    const eventUrl = getPrimaryUrl(result.data.createEvents.events[0])

    return <Navigate to={returnLocation ?? eventUrl} replace/>
  }

  const createButtonLabel = result.loading ? "Creating Event..." : "Create Event"

  return (
    <Container maxWidth="md" sx={{my: {xs: 0, md: 5}}}>
      <Paper sx={{p: 2}}>
        <Box sx={titleSx}>
          <Typography variant="h2">New Event</Typography>
          <ConflictChecker
            startTime={eventState.startTime}
            endTime={eventState.endTime}
            location={eventState.location}
            artists={eventState.artists}
          />
        </Box>
        <Box sx={formSx}>
          <Box sx={{gridArea: 'where'}}>
            <PlacePicker place={eventState.location} onUpdate={updateLocation} autoFocus={focusWhere}/>
          </Box>
          <Box sx={{gridArea: 'when'}}>
            <WhenPicker start={eventState.startTime} end={eventState.endTime} onUpdate={updateWhen} autoFocus={focusWhen}/>
          </Box>
          <Box sx={{gridArea: 'who'}}>
            <ArtistPicker artists={eventState.artists} onUpdate={updateArtists}/>
          </Box>
          <TextField
            sx={{gridArea: 'title'}}
            label="Title"
            value={eventState.title ?? ""}
            onChange={e => updateTitle(e.target.value)}
            autoComplete="off"
          />
          <TextField
            label="Description"
            value={eventState.description?.content ?? ""}
            onChange={e => updateDescription(e.target.value)}
            autoComplete="off"
            multiline
          />
          <LinksEditor links={eventState.links} addLink={addLink} removeLink={removeLink}/>
          <ArtistSponsorPicker artists={eventState.sponsorArtists} onUpdate={updateSponsorArtists}/>
          <ArtistPromoterPicker artists={eventState.promotedArtists} onUpdate={updatePromotedArtists}/>

          <Box display="grid" gap={2} gridAutoFlow="column" justifyContent="end">
            <Tooltip title={<TooltipLabel shortcut={`${ctrlOs()} + Period`}/>}>
              <Button
                onClick={handleCancel}
                disabled={result.loading}
              >
                Cancel
              </Button>
            </Tooltip>
            <Tooltip title={<TooltipLabel shortcut={`${ctrlOs()} + S`}/>}>
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleCreate}
                  disabled={!isValid || result.loading}
                >
                  {createButtonLabel}
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}

export default NewEventView
