import React from 'react'
import {
  Theme,
  useMediaQuery,
} from '@mui/material'
import type {Text} from 'data/types'
import DesktopDescription from './Desktop'
import MobileDescription from './Mobile'

interface DescriptionProps {
  description?: Text,
}

const Description = ({description}: DescriptionProps): JSX.Element | null => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (!description) return null

  if (isMobile) return <MobileDescription description={description}/>

  return <DesktopDescription description={description}/>
}

export default Description
