import React from 'react'
import {Helmet} from 'react-helmet-async'
import {useParams} from 'react-router-dom'
import {LocationParams} from 'types/location'
import {useFetchPlaceById} from 'places/hooks'
import PlaceDetails from 'places/components/Details'

const PlaceDetailsView = (): JSX.Element | null => {
  const {id} = useParams<LocationParams>()
  const {fetchPlaceById, place} = useFetchPlaceById()

  if (id) fetchPlaceById(id)

  if (!place) return null

  const title = "Hapnyn - " + place.name

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PlaceDetails place={place}/>
    </>
  )
}

export default PlaceDetailsView
