import React, {useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Box,
  BoxProps,
  Button,
  Collapse,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import type {Artist} from 'data/types'
import Description from 'artists/components/Description'
import SpotifyPlayer from 'artists/components/SpotifyPlayer'
import ExpandIcon from 'ui/ExpandIcon'
import {getPrimaryUrl} from 'utils/urls'

const nameSx: SxProps<Theme> = {
  display: 'block',
  color: theme => theme.palette.warning.main,
  fontSize: 24,
  lineHeight: 1,
  cursor: 'pointer',
  userSelect: 'none',
}

interface ArtistDetailsProps extends BoxProps {
  artist: Artist,
  expanded?: boolean,
}

const ArtistDetails = ({artist, expanded=false, ...boxProps}: ArtistDetailsProps): JSX.Element | null => {
  const [expand, setExpand] = useState(expanded)

  const {description, links} =  artist

  const artistUrl = getPrimaryUrl(artist)

  const toggleExpand = () => setExpand(!expand)

  return (
    <Box {...boxProps}>
      <Box display="flex" justifyContent={{xs: 'space-between', md: 'flex-start'}} onClick={toggleExpand}>
        <Typography
          sx={nameSx}
        >
          {artist.name}
        </Typography>
        <ExpandIcon expanded={expand} color="warning"/>
      </Box>

      <Collapse in={expand}>
        <Box display="grid" gap={1} mt={2}>
          <SpotifyPlayer links={links} height={80}/>

          {description && <Description description={description} expanded={true}/>}

          <Button
            component={RouterLink}
            to={artistUrl}
            size="large"
            color="warning"
            variant="outlined"
            fullWidth
            sx={{textAlign: 'center', my: 1}}
          >
            More from {artist.name}...
          </Button>
        </Box>
      </Collapse>
    </Box>
  )
}

export default ArtistDetails
