import React, {useCallback, useState} from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {v4 as uuid} from 'uuid'
import {DateTime} from 'luxon'
import {
  Box,
  Button,
  SxProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {Theme} from '@mui/system'
import type {Artist, Event, Place, Text} from 'data/types'
import {useUpdateEvent} from 'events/hooks'
import PlacePicker from 'events/components/PlacePicker'
import ArtistPicker from 'events/components/ArtistPicker'
import ArtistSponsorPicker from 'events/components/ArtistSponsorPicker'
import ArtistPromoterPicker from 'events/components/ArtistPromoterPicker'
import ConflictChecker from 'events/components/ConflictChecker'
import LinksEditor from 'ui/LinksEditor'
import WhenPicker from './WhenPicker'
import {useReturnLocation} from 'router/hooks'
import {getPrimaryUrl} from 'utils/urls'
import {useHotkeys} from 'react-hotkeys-hook'
import {ctrlOs} from 'utils'
import TooltipLabel from 'ui/TooltipLabel'

const titleSx: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

type EventEditorProps = {
  event: Event,
}

const EventEditor = ({event}: EventEditorProps): JSX.Element | null => {
  const [eventState, setEventState] = useState<Event>(event)
  const {updateEvent, result} = useUpdateEvent(event)
  const navigate = useNavigate()
  const returnLocation = useReturnLocation()

  useHotkeys('ctrl+s, meta+s', () => saveEvent(),
    {enableOnFormTags: true, preventDefault: true})
  useHotkeys('ctrl+., meta+.', () => handleCancel(),
    {enableOnFormTags: true, preventDefault: true})

  const eventUrl = getPrimaryUrl(event)

  const updateWhen = useCallback(
    (start: DateTime, end:DateTime) => {
      setEventState((state) => ({
        ...state,
        startTime: start.toJSDate() ?? eventState.startTime,
        endTime: end.toJSDate() ?? eventState.endTime,
      }))
    },
    [eventState.startTime, eventState.endTime],
  )

  const updateLocation = useCallback(
    (location?: Place) => {
      if (location) setEventState(s => ({...s, location}))
    },
    [setEventState],
  )

  const updateArtists = useCallback(
    (artists?: Artist[]) => setEventState(s => ({...s, artists})),
    [setEventState],
  )

  const updateSponsorArtists = useCallback(
    (sponsorArtists?: Artist[]) => setEventState(s => ({...s, sponsorArtists})),
    [setEventState],
  )

  const updatePromotedArtists = useCallback(
    (promotedArtists?: Artist[]) => setEventState(s => ({...s, promotedArtists})),
    [setEventState],
  )

  const updateTitle = (value: string) => {
    let title: string | undefined

    if (value.length > 0) title = value

    setEventState({...eventState, title})
  }

  const updateDescription = (content: string) => {
    let description: Text | undefined

    if (content.length > 0) {
      description = {
        id: uuid(),
        ...eventState.description,
        format: 'PLAIN',
        content,
      }
    }

    setEventState({...eventState, description})
  }

  const isValid = eventState.id
    && eventState.location
    && eventState.startTime
    && eventState.endTime
    && ((eventState.artists && eventState.artists.length > 0) || eventState.title)

  const addLink = (url: string) => {
    const links = eventState.links ?? []

    setEventState({
      ...eventState,
      links: [
        {id: uuid(), url},
        ...links,
      ],
    })
  }

  const removeLink = (linkId: string) => {
    setEventState({
      ...eventState,
      links: eventState.links?.filter(l => l.id !== linkId),
    })
  }

  const handleCancel = () => navigate(returnLocation ?? eventUrl, {replace: true})

  const handleUpdate = () => {
    if (isValid) updateEvent(eventState)
  }

  const saveEvent = () => {
    if (isValid && !result.loading) handleUpdate()
  }

  if (result.data) {
    return <Navigate to={returnLocation ?? eventUrl} replace/>
  }

  const updateButtonLabel = result.loading ? "Updating Event..." : "Update Event"

  return (
    <Box display="grid" gap={2}>
      <Box sx={titleSx}>
        <Typography variant="h2" mb={1}>Edit Event</Typography>
        <ConflictChecker
          id={eventState.id}
          startTime={eventState.startTime}
          endTime={eventState.endTime}
          location={eventState.location}
          artists={eventState.artists}
        />
      </Box>

      <PlacePicker place={eventState.location} onUpdate={updateLocation}/>
      <WhenPicker start={eventState.startTime} end={eventState.endTime} onUpdate={updateWhen} autoFocus={true}/>
      <ArtistPicker artists={eventState.artists} onUpdate={updateArtists}/>

      <TextField
        label="Title"
        value={eventState.title ?? ""}
        onChange={e => updateTitle(e.target.value)}
        autoComplete="off"
      />
      <TextField
        label="Description"
        value={eventState.description?.content ?? ""}
        onChange={e => updateDescription(e.target.value)}
        autoComplete="off"
        multiline
      />
      <LinksEditor links={eventState.links} addLink={addLink} removeLink={removeLink}/>
      <ArtistSponsorPicker artists={eventState.sponsorArtists} onUpdate={updateSponsorArtists}/>
      <ArtistPromoterPicker artists={eventState.promotedArtists} onUpdate={updatePromotedArtists}/>

      <Box display="grid" gap={2} gridAutoFlow="column" justifyContent="end">
        <Tooltip title={<TooltipLabel shortcut={`${ctrlOs()} + Period`}/>}>
          <Button
            onClick={handleCancel}
            disabled={result.loading}
          >
            Cancel
          </Button>
        </Tooltip>
        <Tooltip title={<TooltipLabel shortcut={`${ctrlOs()} + S`}/>}>
          <Box>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={handleUpdate}
              disabled={!isValid || result.loading}
            >
              {updateButtonLabel}
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default EventEditor
