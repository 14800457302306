import React from 'react'
import {
  Theme,
  useMediaQuery,
} from '@mui/material'
import type {Event} from 'data/types'
import MobileEventDetails from './Mobile'
import DesktopEventDetails from './Desktop'

interface EventDetailsProps {
  event: Event,
}

const EventDetails = ({event}: EventDetailsProps): JSX.Element => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return isMobile ? <MobileEventDetails event={event}/> : <DesktopEventDetails event={event}/>
}

export default EventDetails
