import React from 'react'
import {
  Box,
  Button,
  Link as MuiLink,
  SxProps,
  Theme,
} from '@mui/material'
import type {Event} from 'data/types'
import LinkButtons from 'ui/LinkButtons'
import EventLocation from 'events/components/Details/Location'
import EventTime from 'events/components/Details/Time'
import EventTitle from 'events/components/Details/Title'
import EventArtists from 'events/components/Details/Artists'
import EventSponsors from 'events/components/Details/Sponsors'
import EventPromoters from 'events/components/Details/Promoters'
import EventDescription from 'events/components/Details/Description'
import ActionsDial from 'events/components/ActionsDial'
import {VENMO_URL} from 'utils/regex'
import Icon from 'ui/LinkButtons/Icon'

const contentSx: SxProps<Theme> = {
  display: 'grid',
  rowGap: 2,
}

interface MobileEventDetailsProps {
  event: Event,
}

const MobileEventDetails = ({event}: MobileEventDetailsProps): JSX.Element => {
  const {links} = event
  const venmoLinks = links?.filter(l => l.url.match(VENMO_URL))
  const otherLinks = links?.filter(l => !l.url.match(VENMO_URL))

  let artistHeader
  let sponsorHeader
  let promoterHeader

  if (event.sponsorArtists && event.sponsorArtists.length > 0) {
    artistHeader = "Artists"
    sponsorHeader = "Sponsors"
  }

  return (
    <Box p={2} mb={4}>
      <ActionsDial event={event}/>

      <Box sx={contentSx}>
        <EventTitle event={event} fontSize={28} mb={1}/>
        <EventTime event={event}/>
        <EventDescription description={event.description}/>
        <EventLocation event={event}/>
        <EventArtists artists={event.artists} header={artistHeader}/>
        <EventSponsors artists={event.sponsorArtists} header={sponsorHeader}/>
        <EventPromoters artists={event.promotedArtists} header={promoterHeader}/>
        {venmoLinks && venmoLinks.length > 0 &&
          <Box mt={2} justifySelf="center">
            {venmoLinks.map((link, i) => {
              const handleFromUrl = (url: string) => {
                let s = url.split("?")[0]
                s = (s.slice(-1)) == "/" ? s.slice(0, -1) : s
                return s.split("/").pop()
              }

              return (
                <Button
                  key={i}
                  color="inherit"
                  variant="outlined"
                  startIcon={<Icon link={link}/>}
                  size="large"
                  component={MuiLink}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {handleFromUrl(link.url)}
                </Button>
              )
            })}
          </Box>
        }
        {otherLinks && otherLinks.length > 0 && <LinkButtons links={otherLinks} justify="center"/>}
      </Box>
    </Box>
  )
}

export default MobileEventDetails
