import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {
  Container,
} from '@mui/material'
import NewPlaceButton from 'places/components/NewButton'
import PlaceList from 'places/components/List'
import {useRoles} from 'auth/hooks'
import {useHotkeys} from 'react-hotkeys-hook'

const PlacesList = (): JSX.Element | null => {
  const location = useLocation()
  const navigate = useNavigate()
  const {isContentManager} = useRoles()

  useHotkeys('ctrl+Enter, meta+Enter', () => newPlace(),
    {enableOnFormTags: true, preventDefault: true})

  const newPlace = () => {
    if (isContentManager)
      navigate('/places/new', {state: {from: location}})
  }

  return (
    <Container maxWidth="lg" sx={{my: 2, px: {xs: 1, md: 2}}}>
      <NewPlaceButton/>
      <PlaceList/>
    </Container>
  )
}

export default PlacesList
