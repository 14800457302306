import {gql} from '@apollo/client'
import {CONTACT_METHOD_FIELDS} from './contactMethod'
import {CORE_IMAGE_FIELDS} from './image'
import {CORE_LINK_FIELDS} from './link'
import {CORE_TEXT_FIELDS} from './text'
// import {CORE_USER_FIELDS} from './user'

export const CORE_ARTIST_FIELDS = gql `
  ${CORE_TEXT_FIELDS}
  ${CORE_IMAGE_FIELDS}
  ${CORE_LINK_FIELDS}

  fragment CoreArtistFields on Artist {
    id
    createdAt
    updatedAt

    name

    primaryHandle {
      name
    }

    description {
      ...CoreTextFields
    }

    primaryImage {
      ...CoreImageFields
    }

    links {
      ...CoreLinkFields
    }
  }
`

export const COMPLETE_ARTIST_FIELDS = gql `
  ${CORE_ARTIST_FIELDS}
  ${CONTACT_METHOD_FIELDS}
  ${/* CORE_USER_FIELDS */ ""}

  fragment CompleteArtistFields on Artist {
    ...CoreArtistFields

    handles {
      name
    }

    contactMethods {
      ...ContactMethodFields
    }

    images {
      ...CoreImageFields
    }

    # managers {
    #   ...CoreUserFields
    # }
  }
`
