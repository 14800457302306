import React from 'react'
import {
  Box, SxProps,
} from '@mui/material'
import AuthLayout from 'auth/Layout'
import SignInForm from 'auth/SignInForm'

const wrapperSx: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}

const SignInView = (): JSX.Element => {
  return (
    <AuthLayout>
      <Box sx={wrapperSx}>
        <SignInForm/>
      </Box>
    </AuthLayout>
  )
}

export default SignInView
