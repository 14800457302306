import React from 'react'
import {
  Typography,
} from '@mui/material'
import type {Text} from 'data/types'

interface DescriptionProps {
  description: Text,
}

const DesktopDescription = ({description}: DescriptionProps): JSX.Element => {
  return (
    <Typography fontSize={18} fontWeight={300} whiteSpace="pre-wrap">
      {description.content}
    </Typography>
  )
}

export default DesktopDescription
