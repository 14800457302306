import React from 'react'
import {
  Box,
  Typography,
} from '@mui/material'
import {Place} from 'data/types'
import {addressToString} from 'places/utils'
import GoogleMap from 'google/components/Map'

interface DesktopLocationProps {
  place: Place,
}

const DesktopLocation = ({place}: DesktopLocationProps): JSX.Element => {
  const {physicalAddress, primaryPhone} = place

  return (
    <Box display="grid" gap={2}>
      <Box textAlign="right">
        {physicalAddress && <Typography fontWeight={300}>{addressToString(physicalAddress)}</Typography>}
        {primaryPhone && <Typography fontWeight={300}>{primaryPhone.number}</Typography>}
      </Box>
      <GoogleMap placeId={place.googlePlaceId} height={300}/>
    </Box>
  )
}

export default DesktopLocation
