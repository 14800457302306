import React, {useEffect, useState} from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Typography,
} from '@mui/material'
import {useFetchEventsByDateRange} from 'events/hooks'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import DoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import DoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import {DateTime} from 'luxon'
import type {Event, Place} from 'data/types'
import PlaceRow from './PlaceRow'
import {sortEvents, getEventName} from 'events/utils'

const sortMissing = (a: Missing, b: Missing): number => {
  return a.place.name.localeCompare(b.place.name)
}

type Missing = {
  place: Place,
  event: Event,
  count: number,
}

interface EventsTableProps {
  currentEvents : Event[],
  currentDate : DateTime,
}

const MissingEvents = ({currentEvents, currentDate}: EventsTableProps): JSX.Element | null => {
  const {fetchEventsByDateRange, events, result: {loading}} = useFetchEventsByDateRange({
    fetchPolicy: 'cache-and-network',
  })
  const [compareDate, setCompareDate] = useState(currentDate.minus({days: 7}))

  useEffect(() => {
    const compareDate = currentDate.minus({days: 7})
    fetchEventsByDateRange(compareDate.toJSDate())

    setCompareDate(compareDate)
  }, [currentDate, fetchEventsByDateRange])

  useEffect(() => {
    fetchEventsByDateRange(compareDate.toJSDate())
  }, [fetchEventsByDateRange, compareDate])

  let compareEvents: Event[] = []

  if (events) {
    let filteredEvents: Event[] = []

    filteredEvents = events.filter((event) => {
      const startTime = DateTime.fromJSDate(event.startTime)
      return startTime.hasSame(compareDate, 'day')
    })

    compareEvents = [...filteredEvents].sort((a, b) => sortEvents(a, b))
  }

  const currentEventPlaceIds = currentEvents.map(e => {return e.location.id})

  const missingPlaces: Place[] = []

  compareEvents.map((e) => {
    if (!currentEventPlaceIds.includes(e.location.id)) missingPlaces.push(e.location)
  })

  const missing: Missing[] = []

  compareEvents.map((e) => {
    if (!currentEventPlaceIds.includes(e.location.id)) {
      missing.push({place: e.location, event: e, count: 1})
    }
  })

  const missingSorted = [...missing].sort((a, b) => sortMissing(a, b))

  const missingEventCount = missingSorted.length > 0 ? missingSorted.length : 0

  for (let i = 0; i < missingSorted.length;) {
    if (missingSorted[i+1] && missingSorted[i].place.id == missingSorted[i + 1].place.id) {
      missingSorted[i].count++
      missingSorted.splice(i + 1, 1)
      continue
    }
    i++
  }

  return (
    <Box>
      <Box display='inline-flex' justifyContent='space-between' width='100%' bgcolor="background.paper">
        <Box
          display="grid"
          gridTemplateColumns="auto auto auto auto auto"
          justifyContent="center"
          alignItems="center"
          columnGap={1}
        >
          <IconButton size="small" onClick={() => setCompareDate(compareDate.minus({day: 7}))}>
            <DoubleArrowLeftIcon/>
          </IconButton>
          <IconButton size="small" onClick={() => setCompareDate(compareDate.minus({day: 1}))}>
            <ArrowLeftIcon/>
          </IconButton>
          <Typography fontSize={18} align="center">
            {compareDate.toLocaleString(DateTime.DATE_HUGE)}
          </Typography>
          <IconButton size="small" onClick={() => setCompareDate(compareDate.plus({day: 1}))}>
            <ArrowRightIcon/>
          </IconButton>
          <IconButton size="small" onClick={() => setCompareDate(compareDate.plus({day: 7}))}>
            <DoubleArrowRightIcon/>
          </IconButton>
        </Box>
        <Typography
          px={2}
          py={1}
          mb="1px"
          fontSize={18}
          align="right"
          color="warning.main"
          borderRadius={1}
        >
          {compareEvents.length} EVENTS BEFORE, {missingEventCount} MISSING.
        </Typography>
      </Box>
      {!loading && <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {missingSorted.map((m) =>
              <PlaceRow
                key={m.place.id}
                place={m.place}
                name={getEventName(m.event) + (m.count == 1 ? '' : ' (' + m.count + ')')}
                event={m.event}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>}
      {loading && <Box py={1} textAlign="center">
        <CircularProgress/>
      </Box>}

    </Box>
  )
}

export default MissingEvents
