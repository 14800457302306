import {useCallback} from 'react'
import {gql, LazyQueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_ARTIST_FIELDS} from 'data/fragments/artist'
import {Artist} from 'data/types'

const FETCH_ARTISTS_QUERY = gql `
  ${CORE_ARTIST_FIELDS}

  query FetchArtists {
    artists(options: {sort: [{name: ASC}]}) {
      ...CoreArtistFields
    }
  }
`

interface FetchArtistsQueryData {
  artists: Artist[],
}

interface UseFetchArtistsResult {
  fetchArtists: () => void,
  artists?: Artist[],
  result: QueryResult<FetchArtistsQueryData>,
}

type FetchArtistsOptions = LazyQueryHookOptions<FetchArtistsQueryData>

export const useFetchArtists = (options?: FetchArtistsOptions): UseFetchArtistsResult => {
  const [query, result] = useLazyQuery<FetchArtistsQueryData>(FETCH_ARTISTS_QUERY, options)

  const fetchArtists = useCallback(() => {
    if (!result.called) query()
  }, [result.called, query])

  const artists = result.data?.artists

  return {
    fetchArtists,
    artists,
    result,
  }
}
