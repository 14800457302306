import React from 'react'
import {useParams} from 'react-router-dom'
import {
  Container,
  Paper,
} from '@mui/material'
import {useFetchArtistById} from 'artists/hooks'
import type {LocationParams} from 'types/location'
import ArtistEditor from './Editor'

const EditArtistView = (): JSX.Element | null => {
  const {id} = useParams<LocationParams>()
  const {fetchArtistById, artist} = useFetchArtistById({fetchPolicy: 'network-only'})

  if (id) fetchArtistById(id)

  // loading or error occurred
  if (!artist) return null

  return (
    <Container maxWidth="md">
      <Paper sx={{my: 2, p: 2}}>
        {artist && <ArtistEditor artist={artist}/>}
      </Paper>
    </Container>
  )
}

export default EditArtistView
