import React, {useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {DateTime, Interval} from 'luxon'
import {
  Box,
  Collapse,
  IconButton,
  TableRow,
  TableCell,
  Typography,
  Button,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PersonIcon from '@mui/icons-material/Person'
import PlaceIcon from '@mui/icons-material/Place'
import ExpandIcon from 'ui/ExpandIcon'
import type {Event} from 'data/types'
import {getEditUrl, getPrimaryUrl} from 'utils/urls'
import PlaceLinks from './PlaceLinks'
import ArtistLinks from './ArtistLinks'
import {getEventName} from 'events/utils'

interface EventsTableRowProps {
  event: Event,
}

const EventsTableRow = ({event}: EventsTableRowProps): JSX.Element => {
  const [expand, setExpand] = useState(false)

  const eventName = getEventName(event)

  const eventPlace = event.location.name

  const startTime = DateTime.fromJSDate(event.startTime)
  const endTime = event.endTime ? DateTime.fromJSDate(event.endTime) : startTime.plus({hours: 3})
  const interval = Interval.fromDateTimes(startTime, endTime)

  let startTimeString = interval.start.toFormat('h')
  if (interval.start.minute != 0) startTimeString += interval.start.toFormat(':mm')
  if (interval.start.toFormat('a') !== interval.end.toFormat('a') || !interval.hasSame('day')) {
    startTimeString += interval.start.toFormat('a').toLowerCase()
  }

  let endTimeString = interval.end.toFormat('h')
  if (interval.end.minute != 0) endTimeString += interval.end.toFormat(':mm')
  endTimeString += interval.end.toFormat('a').toLowerCase()
  if (interval.end.hour === 0 && interval.end.minute === 0) endTimeString = "midnight"

  const eventTime = [startTimeString, endTimeString].join("-")

  const url = getPrimaryUrl(event)
  const placeUrl = getPrimaryUrl(event.location)

  const editUrl = getEditUrl(event)

  const toggleExpand = () => setExpand(!expand)

  const artists = event.artists

  return (
    <>
      <TableRow
        key={event.id}
        sx={{'&:last-child td, &:last-child th': {border: 0}}}
        onClick={toggleExpand}
      >
        <TableCell>
          <ExpandIcon expanded={expand}/>
        </TableCell>
        <TableCell>
          <Typography fontSize={18}>{eventName}</Typography>
        </TableCell>
        <TableCell>
          <Typography fontSize={18} color="primary.main">{eventPlace}</Typography>
        </TableCell>
        <TableCell>
          <Typography fontSize={18} color="info.main">{eventTime}</Typography>
        </TableCell>
        <TableCell padding="checkbox">
          <Box sx={{display: "flex"}}>
            <IconButton
              component={RouterLink}
              to={url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
              <OpenInNewIcon/>
            </IconButton>
            <IconButton
              component={RouterLink}
              to={editUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            >
              <EditIcon/>
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{py: 0}}></TableCell>
        <TableCell colSpan={4} sx={{py: 0}}>
          <Collapse in={expand}>
            {expand &&
            <Box py={1}>
              <Box display="grid" gridAutoFlow="column" justifyContent="start" alignItems="center" gap={1}>
                <Button
                  sx={{textTransform: 'none'}}
                  component={RouterLink}
                  to={placeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<PlaceIcon/>}
                  size="large"
                >
                  {eventPlace}
                </Button>
                <PlaceLinks place={event.location}/>
              </Box>

              {artists?.map((artist) =>
                <Box key={artist.id} display="grid" gridAutoFlow="column" justifyContent="start" alignItems="center" gap={1}>
                  <Button
                    sx={{textTransform: 'none'}}
                    component={RouterLink}
                    to={getPrimaryUrl(artist)}
                    target="_blank"
                    rel="noopener noreferrer"
                    startIcon={<PersonIcon/>}
                    size="large"
                    color="warning"
                  >
                    {artist.name}
                  </Button>

                  <ArtistLinks artist={artist}/>
                </Box>
              )}
            </Box>
            }
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default EventsTableRow
