import {useCallback, useEffect, useState} from 'react'
import {debounce} from 'lodash'
import {useLoader} from './useLoader'

const fields = [
  'address_component',
  'business_status',
  'formatted_address',
  'geometry',
  'name',
  'place_id',
  'type',
  'formatted_phone_number',
  'opening_hours',
  'types',
  'url',
  'website',
]

interface useAutocompleteServiceResults {
  fetchPredictions: (request: google.maps.places.AutocompletionRequest) => void,
  fetchPlace: (request: google.maps.places.PlaceDetailsRequest, element: HTMLDivElement) => void,
  predictions?: google.maps.places.QueryAutocompletePrediction[],
  place?: google.maps.places.PlaceResult,
}

export const useAutocompleteService = (): useAutocompleteServiceResults => {
  const loaded = useLoader()
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService>()
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()
  const [predictions, setPredictions] = useState<google.maps.places.QueryAutocompletePrediction[]>()
  const [place, setPlace] = useState<google.maps.places.PlaceResult>()

  useEffect(() => {
    if (loaded) {
      setSessionToken(new google.maps.places.AutocompleteSessionToken())
      setAutocompleteService(new google.maps.places.AutocompleteService())
    }
  }, [loaded])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPlacePredictions = useCallback(debounce((request: google.maps.places.AutocompletionRequest) => {
    autocompleteService?.getPlacePredictions({sessionToken, ...request}, (predictions, status) => {
      switch (status) {
        case google.maps.places.PlacesServiceStatus.OK:
          setPredictions(predictions ?? undefined)
          break
        case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
          setPredictions([])
          break
        default:
          console.log({status})
          setPredictions(undefined)
      }
    })
  }, 500), [autocompleteService, sessionToken])

  const fetchPredictions = useCallback((request: google.maps.places.AutocompletionRequest) => {
    getPlacePredictions(request)
  }, [getPlacePredictions])

  const fetchPlace = useCallback((request: google.maps.places.PlaceDetailsRequest, element: HTMLDivElement) => {
    const map = new google.maps.Map(element, {
      clickableIcons: false,
      disableDefaultUI: true,
      zoom: 17,
      zoomControl: true,
    })

    const service = new google.maps.places.PlacesService(map)

    service.getDetails({sessionToken, fields, ...request}, (result, status) => {
      switch (status) {
        case google.maps.places.PlacesServiceStatus.OK:
          setPlace(result ?? undefined)
          setSessionToken(new google.maps.places.AutocompleteSessionToken())
          break
        default:
          console.log({status})
          setPlace(undefined)
      }

      // center the map
      const center = result?.geometry?.viewport?.getCenter()
      if (center) map.setCenter(center)
    })
  }, [sessionToken])

  return {
    fetchPredictions,
    fetchPlace,
    predictions,
    place,
  }
}
