import React from 'react'
import {Outlet} from 'react-router-dom'
import HapnynMenu from './Menu'

const HapnynLayout = (): JSX.Element => {
  return (
    <>
      <HapnynMenu/>
      <Outlet/>
    </>
  )
}

export default HapnynLayout
