import React from 'react'
import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'

const cardSx: SxProps<Theme> = {
  height: 1080,
  width: 1080,
  position: 'relative',
  backgroundColor: theme => theme.palette.background.default,
}

const logoSx: SxProps<Theme> = {
  color: 'common.white',
  fontFamily: 'alba',
  fontSize: 172,
  position: 'absolute',
  left: 108,
  top: 367,
  textShadow: theme => `.04em .04em .04em ${theme.palette.primary.main}`,
}

type TitleCardProps = {
  date: string,
  part: number,
}

export const TitleCard = ({date, part}: TitleCardProps): JSX.Element => {
  return (
    <Box sx={cardSx}>
      <Typography
        color='common.white'
        fontSize={69}
        position='absolute'
        left={169}
        top={353}
      >What&apos;s
      </Typography>
      <Typography
        sx={logoSx}
      >hapnyn
      </Typography>
      <Typography
        color='common.white'
        fontSize={92}
        position='absolute'
        left={658}
        top={432}
      >san diego
      </Typography>
      <Box
        position='absolute'
        top={588}
        textAlign='right'
        width='100%'
        pr='108px'
      >
        <Typography
          color='common.white'
          fontSize={96}
        >{date}
        </Typography>
        <Typography
          color='common.white'
          fontSize={48}
          mt='-7px'
        >Part {part}
        </Typography>
      </Box>
      <Typography
        color='common.white'
        fontSize={64}
        position='absolute'
        left={218}
        top={886}
      >Visit
      </Typography>
      <Typography
        color='primary.main'
        fontSize={64}
        position='absolute'
        left={338}
        top={886}
      >hapnyn.com
      </Typography>
      <Typography
        color='common.white'
        fontSize={64}
        position='absolute'
        left={627}
        top={886}
      >for more...
      </Typography>
    </Box>
  )
}

export default TitleCard
