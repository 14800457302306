import React, {useState} from 'react'
import {v4 as uuid} from 'uuid'
import {Navigate, useNavigate} from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {useCreateArtist} from 'artists/hooks/useCreateArtist'
import {useReturnLocation} from 'router/hooks'
import {useHotkeys} from 'react-hotkeys-hook'
import {ctrlOs} from 'utils'
import TooltipLabel from 'ui/TooltipLabel'

const NewArtistView = (): JSX.Element => {
  const [name, setName] = useState("")
  const {createArtist, result} = useCreateArtist()
  const navigate = useNavigate()
  const returnLocation = useReturnLocation()

  useHotkeys('ctrl+s, meta+s', () => saveArtist(),
    {enableOnFormTags: true, preventDefault: true})
  useHotkeys('ctrl+., meta+.', () => handleCancel(),
    {enableOnFormTags: true, preventDefault: true})

  // route to edit screen after creation
  if (result.data) {
    return <Navigate to={`../edit/${result.data.createArtists.artists[0].id}`} replace/>
  }

  const handleCreateArtist = () => {
    const artist = {
      id: uuid(),
      name,
    }

    createArtist(artist)
  }

  const handleCancel = () => navigate(returnLocation ?? '/artists', {replace: true})

  const valid = name.length > 0 && !result.loading

  const saveArtist = () => {
    if (valid) handleCreateArtist()
  }

  return (
    <Container maxWidth="md">
      <Paper sx={{my: 2, p: 2}}>
        <Box display="grid" gap={2}>
          <Typography variant="h2">
            New Artist
          </Typography>
          <TextField
            label="Artist Name"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
            autoComplete="off"
            autoFocus
          />
          <Box display="grid" gap={2} gridAutoFlow="column" justifyContent="end">
            <Tooltip title={<TooltipLabel shortcut={`${ctrlOs()} + Period`}/>}>
              <Button onClick={handleCancel}>Cancel</Button>
            </Tooltip>
            <Tooltip title={<TooltipLabel shortcut={`${ctrlOs()} + S`}/>}>
              <Box>
                <Button
                  onClick={handleCreateArtist}
                  variant="contained"
                  disableElevation
                  disabled={!valid}
                >
                  Create Artist
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}

export default NewArtistView
