import {useCallback} from 'react'
import {gql, MutationHookOptions, MutationResult, useMutation} from '@apollo/client'
import {CORE_EVENT_FIELDS} from 'data/fragments/event'
import type {Event, Input} from 'data/types'

interface CreateEventVars {
  createEventInput: Input<Event>,
}

const CREATE_EVENT_MUTATION = gql `
  ${CORE_EVENT_FIELDS}

  mutation CreateEvent($createEventInput: [EventCreateInput!]!) {
    createEvents(input: $createEventInput) {
      events {
        ...CoreEventFields
      }
    }
  }
`

interface CreateEventData {
  createEvents: {
    events: Event[],
  },
}

interface UseCreateEventResult {
  createEvent: (event: Event) => void,
  result: MutationResult<CreateEventData>,
}

type CreateEventOptions = MutationHookOptions<CreateEventData, CreateEventVars>

export const useCreateEvent = (options?: CreateEventOptions): UseCreateEventResult => {
  const [mutate, result] = useMutation<CreateEventData, CreateEventVars>(CREATE_EVENT_MUTATION, {
    onError: () => {/* prevent exception from being thrown */},
    refetchQueries: ['FetchEvents', 'EventsCount', 'FetchArtistEvents', 'FetchPlaceEvents'],
    ...options,
  })

  const createEvent = useCallback((event: Event) => {
    const {
      id,
      location: {id: locationId},
      startTime,
      endTime,
      artists,
      sponsorArtists,
      promotedArtists,
      title,
      description,
      links,
    } = event

    const createEventInput: Input<Event> = {
      id,
      location: {
        connect: {
          where: {
            node: {
              id: locationId,
            },
          },
        },
      },
      startTime,
      endTime,
      title,
    }

    // artists
    if (artists && artists.length > 0) {
      createEventInput.artists = {
        connect: {
          where: {
            node: {
              id_IN: artists.map(a => a.id),
            },
          },
        },
      }
    }

    // sponsor artists
    if (sponsorArtists && sponsorArtists.length > 0) {
      createEventInput.sponsorArtists = {
        connect: {
          where: {
            node: {
              id_IN: sponsorArtists.map(a => a.id),
            },
          },
        },
      }
    }

    // promoted artists
    if (promotedArtists && promotedArtists.length > 0) {
      createEventInput.promotedArtists = {
        connect: {
          where: {
            node: {
              id_IN: promotedArtists.map(a => a.id),
            },
          },
        },
      }
    }

    // description
    if (description) {
      const node = {
        id: description.id,
        format: description.format,
        content: description.content,
      }

      createEventInput.description = {
        create: {
          node,
        },
      }
    }

    // links
    if (links && links.length > 0) {
      if (!createEventInput.links) createEventInput.links = {}

      createEventInput.links.create = links.map((link) => {
        const node = {
          id: link.id,
          url: link.url,
        }

        return {node}
      })
    }

    mutate({variables: {createEventInput}})
  }, [mutate])

  return {
    createEvent,
    result,
  }
}
