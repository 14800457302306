import React from 'react'
import {useParams} from 'react-router-dom'
import {
  Container,
  Paper,
} from '@mui/material'
import {useFetchPlaceById} from 'places/hooks'
import type {LocationParams} from 'types/location'
import PlaceEditor from 'places/components/Editor'

const EditPlaceView = (): JSX.Element | null => {
  const {id} = useParams<LocationParams>()
  const {fetchPlaceById, place} = useFetchPlaceById({fetchPolicy: 'network-only'})

  if (id) fetchPlaceById(id)

  // loading or error occurred
  if (!place) return null

  return (
    <Container maxWidth="md">
      <Paper sx={{my: 2, p: 2}}>
        {place && <PlaceEditor place={place}/>}
      </Paper>
    </Container>
  )
}

export default EditPlaceView
