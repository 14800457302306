import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {
  Box,
  Button,
  SxProps,
} from '@mui/material'
import HapnynLogo from 'ui/hapnyn/Logo'
import Region from 'hapnyn/Region'

const buttonSx: SxProps = {
  textTransform: 'none',
  color: 'inherit',
  py: 0,
  borderRadius: 0,
}

const HomeLink = (): JSX.Element => {
  return (
    <Button component={RouterLink} to="/" sx={buttonSx}>
      <Box display="flex" alignItems="center">
        <HapnynLogo/>
        <Region/>
      </Box>
    </Button>
  )
}

export default HomeLink
