import React, {useState} from 'react'
import {
  Box,
  BoxProps,
  Collapse,
  Paper,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import type {Artist} from 'data/types'
import ExpandIcon from 'ui/ExpandIcon'
import LinkButtons from 'ui/LinkButtons'
import {VENMO_URL} from 'utils/regex'

const nameSx: SxProps<Theme> = {
  display: 'block',
  color: theme => theme.palette.warning.main,
  fontSize: 24,
  lineHeight: 1,
  cursor: 'pointer',
  userSelect: 'none',
}

interface SponsorDetailsProps extends BoxProps {
  artist: Artist,
  expanded?: boolean,
}

const SponsorDetails = ({artist, expanded=false, ...boxProps}: SponsorDetailsProps): JSX.Element | null => {
  const [expand, setExpand] = useState(expanded)

  const {description, links} =  artist

  const toggleExpand = () => setExpand(!expand)

  return (
    <Box {...boxProps}>
      <Box display="flex" justifyContent={{xs: 'space-between', md: 'flex-start'}} onClick={toggleExpand}>
        <Typography
          sx={nameSx}
        >
          {artist.name}
        </Typography>
        {description && <ExpandIcon expanded={expand} color="warning"/>}
      </Box>

      {description &&
        <Collapse in={expand}>
          <Box component={Paper} display="grid" gap={1} mt={2} p={2}>
            <Typography fontSize={{xs: 18, sm: 20}} fontWeight={300} whiteSpace="pre-wrap">
              {description.content}
            </Typography>
            <Box mt={1}>
              <LinkButtons links={links}/>
            </Box>
          </Box>
        </Collapse>
      }
    </Box>
  )
}

export default SponsorDetails
