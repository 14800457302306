import React from 'react'
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import {useHotkeys} from 'react-hotkeys-hook'

type DurationOption = {
  label: string,
  value: number,
  shortcut?: string,
}

const DURATIONS: DurationOption[] = [
  {label: "15 minutes", value: 15, shortcut: 'Q'},
  {label: "30 minutes", value: 30, shortcut: 'H'},
  {label: "45 minutes", value: 45, shortcut: 'T'},
  {label: "1 hour", value: 60, shortcut: '1'},
  {label: "90 minutes", value: 90, shortcut: 'N'},
  {label: "2 hours", value: 120, shortcut: '2'},
  {label: "3 hours", value: 180, shortcut: '3'},
  {label: "4 hours", value: 240, shortcut: '4'},
]

interface DurationSelectorProps {
  durationInMinutes?: number,
  onChange: (durationInMinutes: number) => void,
}

const DurationSelector = ({durationInMinutes, onChange}: DurationSelectorProps) => {
  const handleChange = (event: SelectChangeEvent<string | number>) => {
    const duration = Number(event.target.value)

    onChange(duration)
  }

  const setDuration = (duration: number) => {
    onChange(duration)
  }

  useHotkeys('Q', () => setDuration(15))
  useHotkeys('H', () => setDuration(30))
  useHotkeys('T', () => setDuration(45))
  useHotkeys('1', () => setDuration(60))
  useHotkeys('N', () => setDuration(90))
  useHotkeys('2', () => setDuration(120))
  useHotkeys('3', () => setDuration(180))
  useHotkeys('4', () => setDuration(240))

  let value: string | number = ""
  if (durationInMinutes && DURATIONS.map(d => d.value).includes(durationInMinutes)) {
    value = durationInMinutes
  }

  return (
    <FormControl>
      <InputLabel id="select-duration">Duration</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        label="Duration"
        labelId="select-duration"
        renderValue={value => `${DURATIONS.find(d => d.value === value)?.label}`}
      >
        {DURATIONS.map(({label, value, shortcut}) => (
          <MenuItem key={value} value={value}>
            <ListItemText>{label}</ListItemText>
            {shortcut && <Typography variant="body2" color="text.secondary" sx={{ml: 2}}>[{shortcut}]</Typography>}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default DurationSelector
