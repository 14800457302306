import {useCallback} from 'react'
import {DateTime} from 'luxon'
import {gql, LazyQueryHookOptions, QueryResult, useLazyQuery} from '@apollo/client'
import {CORE_EVENT_FIELDS} from 'data/fragments/event'
import {Artist, Event} from 'data/types'
import {sortEvents} from 'events/utils'

const FETCH_UPCOMING_ARTIST_EVENTS_QUERY = gql `
  ${CORE_EVENT_FIELDS}

  query FetchUpcomingArtistEvents($artistId: ID!, $start: DateTime) {
    artists(
      where: {id: $artistId}
    ) {
      id

      events(
        where: {
          startTime_GTE: $start,
        }
        options: {sort: {startTime: ASC}}
      ) {
        ...CoreEventFields
      }

      sponsorEvents(
        where: {
          startTime_GTE: $start,
        }
        options: {sort: {startTime: ASC}}
      ) {
        ...CoreEventFields
      }

      promotedEvents(
        where: {
          startTime_GTE: $start,
        }
        options: {sort: {startTime: ASC}}
      ) {
        ...CoreEventFields
      }

    }
  }
`

interface FetchUpcomingArtistEventsVars {
  artistId: string,
  start: Date,
}

interface FetchUpcomingArtistEventsData {
  artists: Artist[],
}

interface UseFetchUpcomingArtistEventsResult {
  fetchUpcomingArtistEvents: () => void,
  events: Event[]
  result: QueryResult<FetchUpcomingArtistEventsData, FetchUpcomingArtistEventsVars>,
}

type FetchUpcomingArtistEventsOptions = LazyQueryHookOptions<FetchUpcomingArtistEventsData, FetchUpcomingArtistEventsVars>

export const useFetchUpcomingArtistEvents = (artistId: string, options?: FetchUpcomingArtistEventsOptions): UseFetchUpcomingArtistEventsResult => {
  const [query, result] = useLazyQuery<FetchUpcomingArtistEventsData, FetchUpcomingArtistEventsVars>(FETCH_UPCOMING_ARTIST_EVENTS_QUERY, options)

  const fetchUpcomingArtistEvents = useCallback(() => {
    const start = DateTime.now().startOf('day').toJSDate()

    if (!result.called)
      query({
        variables: {
          artistId,
          start,
        },
      })
  }, [result.called, query, artistId])

  const regularEvents = result.data?.artists[0].events ?? []
  const sponsorEvents = result.data?.artists[0].sponsorEvents ?? []
  const promotedEvents = result.data?.artists[0].promotedEvents ?? []

  const unsortedEvents: Event[] = []

  const ids: string[] = []

  regularEvents.forEach(e => {
    if (!ids.includes(e.id)) {
      ids.push(e.id)

      unsortedEvents.push(e)
    }
  })

  sponsorEvents.forEach(e => {
    if (!ids.includes(e.id)) {
      ids.push(e.id)

      unsortedEvents.push(e)
    }
  })

  promotedEvents.forEach(e => {
    if (!ids.includes(e.id)) {
      ids.push(e.id)

      unsortedEvents.push(e)
    }
  })

  const events = [...unsortedEvents].sort((a, b) => sortEvents(a, b))

  return {
    fetchUpcomingArtistEvents,
    events,
    result,
  }
}
