import React from 'react'
import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import DetailsIcon from '@mui/icons-material/TextSnippet'
import type {Text} from 'data/types'

const sectionSx: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: theme => `${theme.spacing(5)} auto`,
}

const iconSx: SxProps<Theme> = {
  color: theme => theme.palette.text.disabled,
  fontSize: 28,
}

interface EventDescriptionProps {
  description?: Text,
}

const EventDescription = ({description}: EventDescriptionProps): JSX.Element | null => {
  if (!description) return null

  return (
    <Box sx={sectionSx}>
      <DetailsIcon sx={iconSx}/>
      <Typography fontSize={18} fontWeight={300} whiteSpace="pre-wrap">
        {description.content}
      </Typography>
    </Box>
  )
}

export default EventDescription
